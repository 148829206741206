import {
  getBaseURL,
  getOnlyNumbers,
  limitarString,
} from "../../utils/functions";
import { ApiRoutes, Mensagens } from "../../models/types";
import { useEffect, useState } from "react";
import { z } from "zod";
import { useAxiosClient } from "../../hooks/useAxiosClient";
import { useHistory } from "react-router";
import { useIonLoading } from "@ionic/react";
import { useAuth } from "../../contexts/authContext";
import Page from "../../components/Page";
import useNotification from "../../hooks/useNotification";
import ReactForm from "../../components/form/ReactForm";
import InputText from "../../components/form/InputText";
import SubmitButton from "../../components/form/SubmitButton";
import axios from "axios";

export default function Endereco() {
  const { logout } = useAuth();

  const notification = useNotification();
  const navigate = useHistory<any>();
  const axiosClient = useAxiosClient(ApiRoutes.ESI_USUARIO_AGENCIA_VIRTUAL);

  const [present, dismiss] = useIonLoading();
  const [dadosNavegacao, setDadosNavegacao] = useState<any>();
  const [endereco, setEndereco] = useState<any>(getEnderecoVazio());

  const maxLengthBairro = 70;
  const maxLengthTipoLogradouro = 15;
  const maxLengthLogradouro = 85;
  const maxLengthQuadra = 5;
  const maxLengthLote = 9;
  const maxLengthNumeroImovel = 5;
  const maxLengthComplementoLogradouro = 15;

  useEffect(() => {
    // dados vindos da navegação
    setDadosNavegacao(navigate.location.state?.data);
  }, [navigate.location.state?.data]);

  function handleChange(event: any, field: string) {
    setEndereco({
      ...endereco,
      [field]: (event.target as HTMLInputElement).value,
    });
  }

  const formSchema = z.object({
    cep: z
      .string()
      .min(1, "Informe um CEP.")
      .refine((input) => !endereco.cepInvalido, "Informe um CEP válido."),
    nomeBairro: z
      .string()
      .refine(
        (input) =>
          endereco.existeNomeBairro || (!endereco.existeNomeBairro && !!input),
        "Informe o bairro."
      ),
    tipoLogradouro: z
      .string()
      .refine(
        (input) =>
          endereco.existeTipoLogradouro ||
          (!endereco.existeTipoLogradouro && !!input),
        "Informe o tipo do logradouro."
      ),
    nomeLogradouro: z
      .string()
      .refine(
        (input) =>
          endereco.existeNomeLogradouro ||
          (!endereco.existeNomeLogradouro && !!input),
        "Informe o logradouro."
      ),
    numeroQuadra: z.string(),
    numeroLote: z.string(),
    numeroImovel: z.string(),
    descricaoComplementoLogradouro: z.string(),
  });

  type formType = z.infer<typeof formSchema>;

  async function enviarDados(dados: formType) {
    if (!dadosNavegacao) {
      await notification.showError({
        message: "Os dados pessoais não foram encontrados.",
      });

      return;
    }

    const body = {
      ...dadosNavegacao,
      ...dados,
      cep: Number(getOnlyNumbers(dados.cep)),
      numeroImovel: dados.numeroImovel || "S/N",
      nomeLogradouro: dados.tipoLogradouro + " " + dados.nomeLogradouro,
    };

    delete body.cepInvalido;
    delete body.tipoLogradouro;
    delete body.existeTipoLogradouro;
    delete body.existeNomeLogradouro;
    delete body.existeNomeBairro;

    await present({ message: Mensagens.SALVANDO });

    try {
      await axiosClient.post(
        "/atendimentos/conclusaoUsuarios",
        JSON.stringify(body)
      );

      notification.showSuccess({
        message: Mensagens.CADASTRO_FINALIZADO_SUCESSO,
      });

      // usuario loga novamente para verificar a efetivação do cadastro
      logout();

      dismiss();
    } catch (e: any) {
      dismiss();

      let msg: Mensagens | string = Mensagens.ERRO_INESPERADO_CONCLUIR_CADASTRO;

      if (e?.response?.status === 422) {
        msg = Mensagens.NECESSARIO_VINCULO_SANEAGO;
      }

      if (e?.response?.data?.detalhes) {
        const data = e.response.data;

        data.detalhes.forEach((item: string) => {
          if (item) {
            msg = msg + "<br/> <br/>" + item.substring(item.indexOf(":") + 2);
          }
        });
      }

      notification.showAlert({ message: msg });
    }
  }

  async function consultarCEP(cep: string) {
    if (isCepInvalido(cep)) {
      setEndereco(getEnderecoVazio(true));
      return;
    }

    await present({ message: Mensagens.VERIFICANDO_DADOS });

    try {
      const url = getBaseURL(ApiRoutes.SAN_CADASTRO) + "/enderecos/ceps";

      const response = await axios.get(url, {
        params: { cep: cep },
        headers: { "Content-Type": "application/json" },
      });

      if (response && response.data && response.data.codigoCidadeCorreios) {
        const data = response.data;

        const dados = {
          cep: cep,
          nomeCidade: data.nomeCidadeCorreios,
          estado: data.uf,

          nomeBairro: data.nomeBairroCorreios
            ? limitarString(data.nomeBairroCorreios, maxLengthBairro)
            : "",

          tipoLogradouro: data.tipoLogradouro
            ? limitarString(data.tipoLogradouro, maxLengthTipoLogradouro)
            : "",

          nomeLogradouro: data.nomeLogradouroCorreios
            ? limitarString(data.nomeLogradouroCorreios, maxLengthLogradouro)
            : "",

          descricaoComplementoLogradouro: data.complementoLogradouroCorreios
            ? limitarString(
                data.complementoLogradouroCorreios,
                maxLengthComplementoLogradouro
              )
            : "",

          existeTipoLogradouro: !!data.tipoLogradouro,
          existeNomeLogradouro: !!data.nomeLogradouroCorreios,
          existeNomeBairro: !!data.nomeBairroCorreios,
        };

        setEndereco(dados);
      } else {
        setEndereco(getEnderecoVazio(true));

        notification.showAlert({ message: `CEP ${cep} não foi encontrado.` });
      }

      dismiss();
    } catch (e: any) {
      dismiss();
      setEndereco(getEnderecoVazio(true));

      await notification.showError({
        message: Mensagens.ERRO_INESPERADO_CEP,
        exception: e,
      });
    }
  }

  function isCepInvalido(cep: string): boolean {
    let sufixoCEP = Number(cep.substring(6, 9));

    if (!cep) {
      return true;
    }

    if (sufixoCEP >= 900 && sufixoCEP <= 959) {
      notification.showAlert({
        message: `O CEP informado ${cep} refere-se a grandes usuários.`,
      });

      return true;
    }

    if ((sufixoCEP >= 970 && sufixoCEP <= 989) || sufixoCEP === 999) {
      notification.showAlert({
        message: `O CEP informado ${cep} refere-se a unidades operacionais.`,
      });

      return true;
    }

    if (sufixoCEP >= 990 && sufixoCEP <= 998) {
      notification.showAlert({
        message: `O CEP informado ${cep} refere-se a caixas postais comunitárias.`,
      });

      return true;
    }

    return false;
  }

  function getEnderecoVazio(cepInvalido?: boolean) {
    return {
      cep: "",
      tipoLogradouro: "",
      nomeLogradouro: "",
      descricaoComplementoLogradouro: "",
      nomeBairro: "",
      nomeCidade: "",
      estado: "",
      existeTipoLogradouro: false,
      existeNomeLogradouro: false,
      existeNomeBairro: false,
      cepInvalido: cepInvalido,
    };
  }

  return (
    <Page title="Recadastro" buttonType="back" showCardConta={false}>
      <div className="card bg-opacity-70">
        <div className="p-2">
          <div className="font-bold text-lg text-primary">Endereço</div>

          <ReactForm
            schema={formSchema}
            onSubmit={(e) => {
              enviarDados(e as formType);
            }}
            onError={(error, e) => {}}
          >
            <div className="flex flex-col space-y-4">
              <div className="font-light text-sm">
                Preencha os dados de acordo com o seu endereço principal.
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-4">
                <InputText
                  name="cep"
                  label="CEP"
                  mask="99999-999"
                  defaultValue={endereco?.cep}
                  onBlur={(e) => consultarCEP(e.target.value)}
                />

                <InputText
                  name="estado"
                  label="Estado"
                  value={endereco?.estado}
                  disabled
                />

                <InputText
                  name="nomeCidade"
                  label="Cidade"
                  value={endereco?.nomeCidade}
                  disabled
                />

                <InputText
                  name="nomeBairro"
                  label="Bairro"
                  inputProps={{ maxLength: maxLengthBairro }}
                  value={endereco?.nomeBairro}
                  onInput={(e) => handleChange(e, "nomeBairro")}
                  readonly={!!endereco?.existeNomeBairro}
                />

                <InputText
                  name="tipoLogradouro"
                  label="Tipo de logradouro"
                  inputProps={{ maxLength: maxLengthTipoLogradouro }}
                  value={endereco?.tipoLogradouro}
                  onInput={(e) => handleChange(e, "tipoLogradouro")}
                  readonly={!!endereco?.existeTipoLogradouro}
                />

                <InputText
                  name="nomeLogradouro"
                  label="Logradouro"
                  inputProps={{ maxLength: maxLengthLogradouro }}
                  value={endereco?.nomeLogradouro}
                  onInput={(e) => handleChange(e, "nomeLogradouro")}
                  readonly={!!endereco?.existeNomeLogradouro}
                />

                <InputText
                  name="numeroQuadra"
                  label="Quadra"
                  inputProps={{ maxLength: maxLengthQuadra }}
                />
                <InputText
                  name="numeroLote"
                  label="Lote"
                  inputProps={{ maxLength: maxLengthLote }}
                />
                <InputText
                  name="numeroImovel"
                  label="Número"
                  inputProps={{ maxLength: maxLengthNumeroImovel }}
                />

                <InputText
                  name="descricaoComplementoLogradouro"
                  label="Complemento"
                  inputProps={{ maxLength: maxLengthComplementoLogradouro }}
                  value={endereco?.descricaoComplementoLogradouro}
                  onInput={(e) =>
                    handleChange(e, "descricaoComplementoLogradouro")
                  }
                />
              </div>

              <SubmitButton label="Enviar" />
            </div>
          </ReactForm>
        </div>
      </div>
    </Page>
  );
}
