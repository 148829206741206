import { z } from "zod";
import { SHA1 } from "crypto-js";
import { useHistory } from "react-router";
import { ApiRoutes, Mensagens, TipoPessoa } from "../../models/types";
import { IonLabel, useIonLoading } from "@ionic/react";
import { useAxiosClient } from "../../hooks/useAxiosClient";
import { useEffect, useState } from "react";
import { useAuth } from "../../contexts/authContext";
import Page from "../../components/Page";
import ReactForm from "../../components/form/ReactForm";
import InputText from "../../components/form/InputText";
import SubmitButton from "../../components/form/SubmitButton";
import useNotification from "../../hooks/useNotification";

export default function NovaSenha() {
  const { logout } = useAuth();

  const [present, dismiss] = useIonLoading();
  const [usuario, setUsuario] = useState<any>();

  const axiosClient = useAxiosClient(ApiRoutes.ESI_USUARIO_AGENCIA_VIRTUAL);
  const navigate = useHistory<any>();
  const notification = useNotification();

  useEffect(() => {
    //dados vindos da navegação
    setUsuario(navigate.location.state?.data);
  }, [navigate.location.state?.data]);

  const isTipoClientePessoaFisica = (() => {
    return usuario?.tipoCliente === TipoPessoa.PESSOA_FISICA;
  })();

  const formSchema = z
    .object({
      senha: z
        .string()
        .min(
          8,
          "Senha deve possuir no mínimo 8 caracteres com pelo menos uma letra maiúscula e um número."
        )
        .regex(
          new RegExp(/[A-Z]/),
          "Senha deve possuir no mínimo uma letra maiúscula."
        )
        .regex(
          new RegExp(/[a-z]/),
          "Senha deve possuir no mínimo uma letra minúscula."
        )
        .regex(new RegExp(/[0-9]/), "Senha deve possuir no mínimo um número.")
        .transform((val) => SHA1(val).toString()),
      confirmarSenha: z
        .string()
        .min(8, "Senha deve possuir no mínimo 8 caracteres.")
        .transform((val) => SHA1(val).toString()),
    })
    .refine((data) => data.senha === data.confirmarSenha, {
      message: "As duas senhas informadas devem ser iguais.",
      path: ["confirmarSenha"],
    });

  type formType = z.infer<typeof formSchema>;

  async function enviarDados(dados: formType) {
    await present({ message: Mensagens.SALVANDO });

    try {
      const url = `/atendimentos/usuarios/${usuario.tipoCliente}/${usuario.cpfCnpj}/senha/resetar`;

      let body = {
        senha: dados.senha,
        codigoValidacao: usuario.codigo,
        cpfCnpj: usuario.cpfCnpj,
        tipoCliente: usuario.tipoCliente,
      };

      await axiosClient.post(url, JSON.stringify(body), {
        headers: {
          "content-type": "application/json",
        },
      });

      notification.showSuccess({
        message: "Senha alterada com sucesso.",
      });

      logout();

      dismiss();
    } catch (e: any) {
      dismiss();

      if (e.response.status === 403) {
        notification.showAlert({
          message: Mensagens.CLIENTE_NAO_CADASTRADO_NAO_CONFIRMADO,
        });
        return;
      }

      if (e.response.status === 406) {
        notification.showAlert({
          message: "A nova senha deve ser diferente da senha anterior.",
        });

        return;
      }

      await notification.showError({ exception: e });
    }
  }

  //Se por ventura não obteve os dados da navegação anterior então retorna erro
  if (!usuario) {
    return (
      <Page title="Recuperar senha" showCardConta={false}>
        <div className="flex flex-col justify-center items-center p-4">
          <IonLabel>{Mensagens.ERRO_INESPERADO}</IonLabel>
        </div>
      </Page>
    );
  }

  return (
    <Page showCardConta={false}>
      <div className="card bg-opacity-70">
        <div className="p-2">
          <div className="font-bold text-lg text-primary pb-4">
            Recuperar senha
          </div>
          <ReactForm
            schema={formSchema}
            onSubmit={(e) => enviarDados(e as formType)}
            onError={(error, e) => {}}
          >
            <div className="flex flex-col space-y-4">
              <InputText
                value={usuario?.cpfCnpj}
                name="cpfCnpj"
                label={isTipoClientePessoaFisica ? "CPF" : "CNPJ"}
                mask={
                  isTipoClientePessoaFisica
                    ? "999.999.999-99"
                    : "99.999.999/9999-99"
                }
                disabled
              />

              <InputText
                name="senha"
                label="Nova senha"
                type={"password"}
                inputProps={{ maxLength: 12 }}
                autoComplete="off"
              />

              <InputText
                name="confirmarSenha"
                label="Confirmar nova senha"
                type={"password"}
                inputProps={{ maxLength: 12 }}
                autoComplete="off"
              />

              <SubmitButton label="Enviar" />
            </div>
          </ReactForm>
        </div>
      </div>
    </Page>
  );
}
