import {
  ApiRoutes,
  CODIGO_UNIDADE_ATENDIMENTO,
  Conta,
  MATRICULA_SOLICITANTE,
  Mensagens,
  SituacaoLigacaoAgua,
  TIPOS_LOCAL_VAZAMENTO,
  TIPOS_PASSEIO,
  TIPOS_PAVIMENTACAO,
} from "../../models/types";
import { getOnlyNumbers } from "../../utils/functions";
import { useContaUsuario } from "../../contexts/contaUsuarioContext";
import { useEffect, useState } from "react";
import { z } from "zod";
import { useAxiosClient } from "../../hooks/useAxiosClient";
import { useAuth } from "../../contexts/authContext";
import { useIonLoading } from "@ionic/react";
import { useProtocolo } from "../../contexts/protocoloContext";

import Page from "../../components/Page";
import useNotification from "../../hooks/useNotification";
import ReactForm from "../../components/form/ReactForm";
import InputText from "../../components/form/InputText";
import SubmitButton from "../../components/form/SubmitButton";
import InputComplete from "../../components/form/InputComplete";
import CardCapturarFoto from "../../components/form/CardCapturarFoto";
import ButtonBack from "../../components/ButtonBack";

export default function VazamentoNaConta() {
  const { usuario } = useAuth();
  const { getProtocolo } = useProtocolo();
  const contaUsuarioContext = useContaUsuario();

  const [present, dismiss] = useIonLoading();
  const [dadosConta, setDadosConta] = useState<Conta | null>({
    nomeCidade: "",
    nomeBairro: "",
    nomeLogradouro: "",
    quadraImovel: "",
    loteImovel: "",
    numeroImovel: "",
    situacaoLigacaoAgua: "",
  });

  useEffect(() => {
    setDadosConta(contaUsuarioContext.contaSelecionada);
  }, [contaUsuarioContext.contaSelecionada]);

  const notification = useNotification();
  const axiosClient = useAxiosClient(ApiRoutes.ECO_ATENDIMENTO);

  const formSchema = z.object({
    pontoReferencia: z.string().min(1, "Informe um ponto de referência."),
    celular: z
      .string()
      .min(11, "Informe um número de contato.")
      .transform((val) => Number(getOnlyNumbers(val))),
    localizacao: z.string().min(1, "Selecione a localização do vazamento."),
    pavimentacao: z.string().min(1, "Selecione o tipo de pavimentação."),
    passeio: z.string().min(1, "Selecione o tipo de passeio."),
    observacao: z.string(),
    fotoVazamento: z.string(),
  });

  type formType = z.infer<typeof formSchema>;

  async function enviarDados(dados: formType, resetFields: any) {
    //
    if (
      dados?.localizacao === "HIDROMETRO" &&
      dadosConta?.codigoSituacaoLigacaoAgua !== SituacaoLigacaoAgua.LIGADA &&
      dadosConta?.codigoSituacaoLigacaoAgua !== SituacaoLigacaoAgua.CORTADA
    ) {
      notification.showAlert({
        message: Mensagens.MSGE0101_VAZAMENTO_NAO_POSSIVEL,
      });
      return;
    }

    await present({ message: Mensagens.SALVANDO });

    const dddTelefone = String(dados.celular).substring(0, 2);
    const numeroTelefone = String(dados.celular).substring(2, 11);

    const protocolo = await getProtocolo({
      numeroConta: dadosConta?.numeroConta || null,
      dddTelefoneContato: Number(dddTelefone),
      numeroTelefoneContato: Number(numeroTelefone),
    });

    if (!protocolo || !protocolo.numeroProtocolo) {
      dismiss();
      return;
    }

    try {
      let body = {
        matriculaSolicitante: MATRICULA_SOLICITANTE,
        codigoUnidadeAtendimento: CODIGO_UNIDADE_ATENDIMENTO,
        cpfCnpj: usuario?.cpfCnpj,
        nomeContato: usuario?.nome,
        tipoCliente: usuario?.tipoCliente,
        numeroConta: dadosConta?.numeroContaComDigito,
        pontoReferencia: dados.pontoReferencia,
        dddTelefoneContato: dddTelefone,
        numeroTelefoneContato: numeroTelefone,

        localizacao: dados.localizacao,
        tipoPavimentacao: dados.pavimentacao,
        tipoCalcada: dados.passeio,
        observacao: dados.observacao,

        extensaoArquivoRegistroVazamento: "jpg",
        arquivoRegistroVazamento: dados.fotoVazamento
          ? dados.fotoVazamento
              .replace(/^data:image\/[a-z]+;base64,/, "")
              .trim()
          : "",
      };

      await axiosClient.post(
        `/atendimentos/${protocolo.numeroProtocolo}/registrar/vazamento`,
        JSON.stringify(body),
        {
          headers: {
            "content-type": "application/json",
          },
        }
      );

      notification.showProtocolo(protocolo.numeroProtocolo).then(() => {
        resetFields();
      });

      dismiss();
    } catch (e: any) {
      dismiss();

      let msg = Mensagens.ERRO_INESPERADO;

      if (e.response.status === 403) {
        msg = Mensagens.SOLICITACAO_ABERTA;
      }

      await notification.showError({ message: msg, exception: e });
    }
  }

  return (
    <Page title="Comunicar vazamento" buttonType="back">
      <div className="flex flex-col space-y-4">
        <div className="card bg-opacity-70">
          <div className="card-title">Dados da ocorrência</div>

          <div className="items-center">
            <ReactForm
              schema={formSchema}
              onError={(error, e) => {}}
              onSubmit={(data, resetFields) =>
                enviarDados(data as formType, resetFields)
              }
            >
              <div className="flex flex-col space-y-4">
                <InputText
                  value={dadosConta?.nomeCidade}
                  name="nomeCidade"
                  label="Cidade"
                  disabled
                />
                <InputText
                  value={dadosConta?.nomeBairro}
                  name="nomeBairro"
                  label="Bairro"
                  disabled
                />
                <InputText
                  value={dadosConta?.nomeLogradouro}
                  name="nomeLogradouro"
                  label="Logradouro"
                  disabled
                />

                <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3 gap-4">
                  <InputText
                    value={dadosConta?.quadraImovel}
                    name="quadraImovel"
                    label="Quadra"
                    disabled
                  />
                  <InputText
                    value={dadosConta?.loteImovel}
                    name="loteImovel"
                    label="Lote"
                    disabled
                  />
                  <InputText
                    value={dadosConta?.numeroImovel}
                    name="numeroImovel"
                    label="Número"
                    disabled
                  />
                </div>

                <InputText
                  name="pontoReferencia"
                  label="Ponto de referência"
                  inputProps={{ maxLength: 50 }}
                />

                <InputText
                  name="celular"
                  label="Celular"
                  mask="99 99999-9999"
                />

                <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3 gap-4">
                  <InputComplete
                    name="localizacao"
                    placeHolder="Localização vazamento"
                    items={TIPOS_LOCAL_VAZAMENTO}
                  />

                  <InputComplete
                    name="pavimentacao"
                    placeHolder="Tipo de pavimentação"
                    items={TIPOS_PAVIMENTACAO}
                  />

                  <InputComplete
                    name="passeio"
                    placeHolder="Tipo de passeio(calçada)"
                    items={TIPOS_PASSEIO}
                  />
                </div>

                <InputText
                  name="observacao"
                  label="Observação"
                  inputProps={{ maxLength: 50 }}
                />

                <CardCapturarFoto
                  name="fotoVazamento"
                  title="Foto do vazamento"
                />

                <div className="grid grid-cols-2 gap-2 xs:grid-cols-1">
                  <SubmitButton label="Enviar" />
                  <ButtonBack label="Voltar" />
                </div>
              </div>
            </ReactForm>
          </div>
        </div>
      </div>
    </Page>
  );
}
