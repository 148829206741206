import React, { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { ApiRoutes, Item, Mensagens } from "../../models/types";
import { getBaseURL, ordernarLista } from "../../utils/functions";
import InputComplete from "./InputComplete";
import useNotification from "../../hooks/useNotification";
import DivInputLoading from "../DivInputLoading";

export interface Pesquisa {
  mensagem: string;
  listaResultados: Bairro[];
}

export interface Bairro {
  codigoCidade: number;
  codigoBairro: number;
  nomeBairro: string;
}

export interface PesquisaBairroProps {
  name: string;
  codigoCidade?: number | null;
  placeHolder?: string;
  defaultValuePosition?: number;
  disableClearable?: boolean;
  onSelect?(item: Item | null): void;
}

const PesquisaBairro: React.FC<PesquisaBairroProps> = ({
  name,
  placeHolder,
  codigoCidade,
  defaultValuePosition,
  disableClearable,
  onSelect,
}: PesquisaBairroProps) => {
  const notification = useNotification();

  const [data, setData] = useState<Bairro[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const url = String(getBaseURL(ApiRoutes.SAN_CADASTRO)).concat(
    `/enderecos/${codigoCidade}/bairros`
  );

  useEffect(() => {
    if (codigoCidade) {
      consultar();
    } else {
      setData(null);
    }
  }, [codigoCidade]);

  async function consultar() {
    setIsLoading(true);
    setData(null);

    try {
      let response: AxiosResponse<Pesquisa, any> = await axios.get<Pesquisa>(
        url,
        {
          headers: {
            "content-type": "application/json",
            "Application-Origin": "AgenciaVirtual",
          },
        }
      );

      let lista = ordernarLista(response?.data?.listaResultados, "nomeBairro");

      setIsLoading(false);
      setData(lista);
      //
    } catch (e: any) {
      setIsLoading(false);

      await notification.showError({ exception: e });
    }
  }

  return isLoading ? (
    <DivInputLoading label={"Carregando bairros"} />
  ) : (
    <InputComplete
      name={name}
      placeHolder={placeHolder}
      defaultValuePosition={defaultValuePosition}
      disableClearable={disableClearable}
      onSelect={onSelect}
      items={data?.map((item) => {
        return { value: item.codigoBairro, label: item.nomeBairro };
      })}
    />
  );
};

export default PesquisaBairro;
