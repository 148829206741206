import { classNames } from "primereact/utils";
import useWindowDimensions from "../hooks/useWindowDimensions";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  className?: string;
  fullWidth?: boolean;
}

const Button = (props: ButtonProps) => {
  const { isMobileScreen } = useWindowDimensions();

  const { label, className, fullWidth, ...propsOriginal } = props;

  // se o parametro className for passado ele ira sobrescrever todo o estilo do componente
  let btnClass =
    className ||
    classNames({
      "p-4 bg-slate-300 rounded-xl text-sm font-medium text-slate-700":
        props.disabled,
      "p-4 bg-primary hover:bg-secondary rounded-xl text-sm font-medium text-slate-50 transition duration-200":
        !props.disabled,
    });

  btnClass = btnClass?.concat(isMobileScreen || fullWidth ? " w-full" : "");

  return (
    <div className="text-center">
      <button
        {...propsOriginal}
        type="button"
        style={{ minWidth: 180 }}
        className={btnClass}
      >
        {label}
      </button>
    </div>
  );
};

export default Button;
