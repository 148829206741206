import {
  ApiRoutes,
  CODIGO_CONTATO_AGENCIA_VIRTUAL_MOBILE,
  MATRICULA_RESPONSAVEL_PROTOCOLO,
  Mensagens,
  ProtocoloAnonimo,
  ProtocoloParam,
  ProtocoloItem,
} from "../models/types";
import { createContext, useContext, useEffect, useState } from "react";
import { Preferences } from "@capacitor/preferences";
import { distinct, getBaseURL, ordernarLista } from "../utils/functions";
import { useAuth } from "./authContext";
import { useAxiosClient } from "../hooks/useAxiosClient";
import useNotification from "../hooks/useNotification";
import axios from "axios";

export interface ProtocoloContextData {
  getProtocolo(params: ProtocoloParam): Promise<ProtocoloItem>;
  getProtocoloAnonimo(params: ProtocoloAnonimo): Promise<ProtocoloItem>;
  salvarProtocoloMemoria(novoProtocolo: any): void;
  consultarProtocolosMemoria(): any;
}

const ProtocoloContext = createContext<ProtocoloContextData>(
  {} as ProtocoloContextData
);

export const ProtocoloProvider = ({ children }: { children: JSX.Element }) => {
  const { isLogged, usuario } = useAuth();
  const [protocolos, setProtocolos] = useState<ProtocoloItem[]>([]);

  const notification = useNotification();
  const useAxiosAtendimento = useAxiosClient(ApiRoutes.ECO_ATENDIMENTO);

  useEffect(() => {
    if (!isLogged) {
      resetProtocolo();
    }
  }, [isLogged]);

  async function getProtocolo(protocoloParams: ProtocoloParam) {
    const protocoloFind = protocolos.find(
      (protocolo) => protocoloParams.numeroConta === protocolo.numeroConta
    );

    if (protocoloFind) {
      return protocoloFind;
    }

    return await abrirProtocolo(protocoloParams);
  }

  async function abrirProtocolo(
    protocoloParams: ProtocoloParam
  ): Promise<ProtocoloItem> {
    //
    let body = {
      numeroConta: protocoloParams.numeroConta,
      dddTelefoneContato: protocoloParams.dddTelefoneContato,
      numeroTelefoneContato: protocoloParams.numeroTelefoneContato,
      codigoMotivoContato: CODIGO_CONTATO_AGENCIA_VIRTUAL_MOBILE,
      matriculaResponsavel: MATRICULA_RESPONSAVEL_PROTOCOLO,
      descricaoAdicional: "Este protocolo foi gerado no APP da Saneago",
      nomeContato: usuario?.nome,
      numeroCpfCnpj: usuario?.cpfCnpj,
      tipoCliente: usuario?.tipoCliente,
    };

    return useAxiosAtendimento
      .post("/atendimentos/protocolos", JSON.stringify(body), {
        headers: {
          "content-type": "application/json",
        },
      })
      .then(async (e) => {
        let _protocolo = {
          ...e.data,
          numeroConta: protocoloParams.numeroConta,
        };

        setProtocolos([...protocolos, ...[_protocolo]]);

        // para 'não clientes' os protocolos abertos ficam salvos na memoria do dispositivo
        // pois para eles o serviço de consultar protocolos por cpf não funciona
        if (!usuario?.clienteSaneago) {
          salvarProtocoloMemoria(_protocolo);
        }

        return e.data;
      })
      .catch(async (e: any) => {
        await notification.showError({
          message: Mensagens.PROBLEMAS_GERAR_PROTOCOLO,
          exception: e,
        });

        return null;
      });
  }

  async function getProtocoloAnonimo(protocoloParams: ProtocoloAnonimo) {
    let body = {
      codigoMotivoContato: CODIGO_CONTATO_AGENCIA_VIRTUAL_MOBILE,
      matriculaResponsavel: MATRICULA_RESPONSAVEL_PROTOCOLO,
      descricaoAdicional: "Este protocolo foi gerado no APP da Saneago",
    };

    const params = new URLSearchParams({
      "g-recaptcha-response": protocoloParams.reCaptchaToken,
    });

    let url =
      getBaseURL(ApiRoutes.ECO_ATENDIMENTO_EXTERNO) +
      `/atendimentos/protocolos`;

    return axios
      .post(url, JSON.stringify(body), {
        params: params,
        headers: {
          "content-type": "application/json",
        },
      })
      .then(async (e) => {
        // salva o protocolo aberto na memoria do dispositivo
        // pois não existe a opção de consultar por cpf ou conta
        salvarProtocoloMemoria({ ...e.data });

        return e.data;
      })
      .catch(async (e: any) => {
        await notification.showError({
          message: Mensagens.PROBLEMAS_GERAR_PROTOCOLO,
          exception: e,
        });

        return null;
      });
  }

  async function salvarProtocoloMemoria(novoProtocolo: ProtocoloItem) {
    let protocolos = await consultarProtocolosMemoria();

    protocolos = [
      ...protocolos,
      {
        numeroProtocolo: novoProtocolo.numeroProtocolo,
        dataAbertura: novoProtocolo.dataHoraAbertura,
      },
    ];

    await Preferences.set({
      key: `protocolos_${usuario?.cpfCnpj}`,
      value: JSON.stringify(protocolos),
    });
  }

  async function consultarProtocolosMemoria() {
    const { value } = await Preferences.get({
      key: `protocolos_${usuario?.cpfCnpj}`,
    });

    let lista = value ? JSON.parse(value || "[]") : [];

    if (lista) {
      lista = distinct(lista, "numeroProtocolo");
      lista = ordernarLista(lista, "dataAbertura");
      lista = lista.reverse();
    }

    return lista;
  }

  function resetProtocolo() {
    setProtocolos([]);
  }

  return (
    <ProtocoloContext.Provider
      value={{
        getProtocolo,
        getProtocoloAnonimo,
        salvarProtocoloMemoria,
        consultarProtocolosMemoria,
      }}
    >
      {children}
    </ProtocoloContext.Provider>
  );
};

export function useProtocolo() {
  return useContext(ProtocoloContext);
}
