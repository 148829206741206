import { IonIcon } from "@ionic/react";
import { camera } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { usePhotoGallery } from "../../hooks/usePhotoGallery";
import { classNames } from "primereact/utils";

export interface CardTirarFotoProps {
  title?: string;
  name: string;
  quality?: number;
  maxHeight?: number;
  maxWidth?: number;
  hidden?: boolean;
  onCaptureImage?(imageBase64: string): void;
}

export default function CardCapturarFoto(props: CardTirarFotoProps) {
  const { title, onCaptureImage, maxHeight, maxWidth, quality, name, hidden } =
    props;
  const { takePhoto } = usePhotoGallery();

  const [image, setImage] = useState<string>("");
  const inputData = useRef<any>();

  const {
    register,
    resetField,
    formState: { isSubmitting, errors },
  } = useFormContext();

  useEffect(() => {
    if (!inputData.current?.value) {
      inputData.current.value = null;
      inputData.current.focus();

      setImage("");
    }
  }, [inputData.current?.value]);

  useEffect(() => {
    if (hidden) {
      inputData.current.value = null;
      inputData.current.focus();
      resetField(name, { defaultValue: "" });

      setImage("");
    }
  }, [hidden]);

  async function capture() {
    try {
      const imageBase64 = await takePhoto({
        quality: quality || 80,
        maxHeight: maxHeight || 800,
        maxWidth: maxWidth || 800,
      });

      const img = imageBase64?.includes("base64")
        ? imageBase64
        : "data:image/jpeg;base64,".concat(imageBase64);

      inputData.current.value = img;
      inputData.current.focus();

      setImage(img);
      // onCaptureImage(imageBase64);
    } catch (e) {
      // ignorando o erro de quando o usuario fecha a janela sem anexar nada
    }
  }

  let returnRender = <></>;

  if (image) {
    returnRender = (
      <div className="flex flex-col gap-4">
        <div className="self-center">
          <img
            alt=""
            src={image}
            style={{
              maxHeight: "200px",
              objectFit: "cover",
            }}
          />
        </div>
        <div className="self-center cursor-pointer bg-primary hover:bg-opacity-30 bg-opacity-20 px-4 py-2 text-center rounded-lg max-w-sm text-slate-700 text-md font-medium">
          <div className="flex flex-row gap-2">
            <IonIcon className="text-xl self-center" icon={camera} />
            <div onClick={() => capture()}>Tirar outra foto</div>
          </div>
        </div>
      </div>
    );
  } else {
    returnRender = (
      <div className="flex flex-col">
        <div
          onClick={() => capture()}
          style={{ height: 150, maxWidth: 200 }}
          className={classNames(
            "w-full self-center  cursor-pointer bg-primary py-10 hover:bg-opacity-10 bg-opacity-5 flex flex-col justify-center rounded-lg",
            { "bg-red-500": !!errors[name] }
          )}
        >
          <IonIcon className="text-2xl self-center" icon={camera} />
          <div className="text-center">{title}</div>
        </div>
        <div className="text-red-600 text-sm text-center mt-1 self-center">
          {errors[name]?.message?.toString()}
        </div>
      </div>
    );
  }

  const { ref, ...rest } = register(name);

  return (
    <div hidden={hidden}>
      <div>{returnRender}</div>

      <div style={{ height: "0px" }}>
        {/* esse input ficara invisivel (width: 0) e sera utilizado para armazenar o value */}
        <input
          style={{ width: "0px" }}
          type="text"
          // value={image}
          {...rest}
          name={name}
          ref={(e) => {
            ref(e);
            inputData.current = e;
          }}
        ></input>
      </div>
    </div>
  );
}
