import { ApiRoutes, Mensagens, ProtocoloListItem } from "../../models/types";
import { useContaUsuario } from "../../contexts/contaUsuarioContext";
import { z } from "zod";
import { useEffect, useState } from "react";
import { useAuth } from "../../contexts/authContext";
import { useAxiosClient } from "../../hooks/useAxiosClient";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatarData, getOnlyNumbers } from "../../utils/functions";
import { useHistory } from "react-router";
import { useProtocolo } from "../../contexts/protocoloContext";
import Page from "../../components/Page";
import useNotification from "../../hooks/useNotification";
import ReactForm from "../../components/form/ReactForm";
import SelectField from "../../components/form/SelectField";
import InputText from "../../components/form/InputText";
import SubmitButton from "../../components/form/SubmitButton";

export default function Protocolos() {
  const axiosClient = useAxiosClient(ApiRoutes.ECO_ATENDIMENTO);
  const notification = useNotification();
  const navigate = useHistory<any>();

  const { usuario } = useAuth();
  const { contaSelecionada } = useContaUsuario();
  const { consultarProtocolosMemoria } = useProtocolo();

  const [data, setData] = useState<ProtocoloListItem[] | []>();
  const [selected, setSelected] = useState<ProtocoloListItem>();
  const [tipoConsulta, setTipoConsulta] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isTipoConsultaConta = (() => {
    return tipoConsulta === "conta";
  })();

  const isTipoConsultaNumero = (() => {
    return tipoConsulta === "numero";
  })();

  useEffect(() => {
    setData([]);

    if (tipoConsulta !== undefined) {
      consultar();
    }
  }, [tipoConsulta, contaSelecionada]);

  useEffect(() => {
    if (selected) {
      detalhar(selected.numeroProtocolo);
    }
  }, [selected]);

  const formSchema = z.object({
    tipoConsultaForm: z
      .any()
      .refine((input) => input, "Selecione o tipo de consulta.")
      .transform((input) => input.value),
    numeroProtocolo: z
      .string()
      .min(1, "Informe o número de 13 dígitos do protocolo.")
      .transform((input) => Number(getOnlyNumbers(input))),
  });

  type formType = z.infer<typeof formSchema>;

  async function consultar(dados?: formType) {
    try {
      let response;

      switch (tipoConsulta) {
        case "numero":
          if (dados?.numeroProtocolo) {
            detalhar(dados?.numeroProtocolo);
          }

          break;

        case "conta":
          if (!contaSelecionada?.numeroContaComDigito) {
            notification.showNecessarioSelecionarConta();
            return;
          }

          setIsLoading(true);

          response = await axiosClient.get(`/atendimentos/contas/protocolos`, {
            params: {
              conta: contaSelecionada?.numeroContaComDigito,
              quantidadeProtocolos: 100,
            },
          });

          setData(response.data);
          break;

        case "cpfCnpj":
          if (!usuario?.clienteSaneago) {
            response = { data: await consultarProtocolosMemoria() };
            setData(response.data);

            return;
          }

          setIsLoading(true);

          response = await axiosClient.get(
            `/atendimentos/${usuario?.tipoCliente}/${usuario?.cpfCnpj}/protocolos`,
            {
              params: {
                quantidadeProtocolos: 100,
              },
            }
          );

          setData(response.data);
          break;
      }

      setIsLoading(false);
    } catch (e: any) {
      setIsLoading(false);

      await notification.showError({ exception: e });
    }
  }

  function detalhar(numeroProtocolo: number) {
    navigate.push({
      pathname: "/protocoloDetalhe",
      state: { numeroProtocolo: numeroProtocolo },
    });
  }

  return (
    <Page title="Protocolos" showCardConta={isTipoConsultaConta}>
      <div className="flex flex-col space-y-4">
        <ReactForm
          schema={formSchema}
          onSubmit={(e) => consultar(e as formType)}
          onError={(error, e) => {}}
        >
          <div className="card bg-opacity-70">
            <SelectField
              name="tipoConsultaForm"
              placeHolder="Tipo consulta"
              defaultValuePosition={0}
              onSelect={(item) => {
                setTipoConsulta(item?.value as string);
              }}
              items={[
                { value: "cpfCnpj", label: "Associados ao CPF/CNPJ" },
                {
                  value: "conta",
                  label: "Associados a conta selecionada",
                  disabled: !usuario?.ativo || !usuario?.clienteSaneago,
                },
                { value: "numero", label: "Número do protocolo" },
              ]}
            />

            <div className="pt-4 space-y-4" hidden={!isTipoConsultaNumero}>
              <InputText
                name="numeroProtocolo"
                label="Informe o número"
                mask="9999999999999"
              />

              <SubmitButton label="Consultar" />
            </div>
          </div>
        </ReactForm>

        <div className="card-borda" hidden={isTipoConsultaNumero}>
          <DataTable
            value={data}
            emptyMessage={Mensagens.NENHUM_RESULTADO_ENCONTRADO}
            responsiveLayout="scroll"
            size="small"
            stripedRows
            selection={selected}
            loading={isLoading}
            onSelectionChange={(e) => setSelected(e.value)}
          >
            <Column selectionMode="single"></Column>
            <Column field="numeroProtocolo" header="Número protocolo"></Column>
            <Column
              field="dataAbertura"
              header="Data abertura"
              body={dataAberturaBodyTemplate}
            ></Column>
          </DataTable>
        </div>
      </div>
    </Page>
  );
}

const dataAberturaBodyTemplate = (rowData: { dataAbertura: unknown }) => {
  return formatarData(rowData.dataAbertura);
};
