import { IonItem, IonSelect, IonSelectOption } from "@ionic/react";
import { classNames } from "primereact/utils";
import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import { Item } from "../../models/types";

export declare type SelectFieldInterface = "action-sheet" | "popover" | "alert";

export interface SelectFieldProps {
  items: Item[];
  name: string;
  placeHolder?: string;
  type?: SelectFieldInterface;
  defaultValuePosition?: number; // informe qual posicao do array deve vir selecionada por padrao
  readonly?: boolean;
  onSelect?(item: Item | null): void;
}

const SelectField = ({
  name,
  items,
  type,
  placeHolder,
  defaultValuePosition,
  readonly,
  onSelect,
}: SelectFieldProps) => {
  //
  const {
    register,
    resetField,
    formState: { isSubmitting, errors },
  } = useFormContext();

  const [value, setValue] = useState<Item | null>(null);
  const [erro, setErro] = useState<boolean>(false);

  useEffect(() => {
    if (errors[name]) {
      setErro(true);
    } else {
      setErro(false);
    }
  }, [errors, name]);

  useEffect(() => {
    if ((defaultValuePosition || defaultValuePosition === 0) && items) {
      selecionar(items[defaultValuePosition]);
    }
  }, [defaultValuePosition]);

  function selecionar(item: Item) {
    if (item?.value && item?.value !== value?.value) {
      onChange(item);
    }
  }

  const onChange = (item: Item | null) => {
    setValue(item);
    setErro(item ? false : errors[name] && !item ? true : false);

    setTimeout(() => {
      resetField(name, { defaultValue: item });
    }, 200);

    if (onSelect) {
      onSelect(item);
    }
  };

  const _placeHolder = placeHolder || "Selecione";

  return (
    <div
      className={classNames("", {
        "pointer-events-none opacity-70": readonly,
      })}
    >
      <div hidden={!value || !placeHolder}>
        <div className="text-xs absolute z-50 ml-3 -mt-2 px-1 text-slate-500 bg-gradient-to-b from-transparent via-slate-50 to-white">
          {_placeHolder}
        </div>
      </div>

      <IonItem
        lines="none"
        className={classNames(
          "rounded-md border-solid border",
          {
            "border-slate-400 border-opacity-70 hover:border-slate-700": !erro,
          },
          { "border-red-600 ": erro }
        )}
      >
        <IonSelect
          style={{ height: 56 }}
          {...register(name)}
          placeholder={_placeHolder}
          interface={type ? type : "popover"}
          cancelText="Cancelar"
          onIonChange={(e) => selecionar(e.detail.value)}
          value={value}
          disabled={isSubmitting}
          compareWith={(a: Item, b: Item) => a?.value === b?.value}
          selectedText={value?.label || _placeHolder}
          className={classNames(
            "w-full",
            { "text-red-600 ": erro },
            { "text-slate-500 ": !value }
          )}
        >
          {items?.map((e) => {
            return (
              <IonSelectOption value={e} key={e.value} disabled={e.disabled}>
                {e.label}
              </IonSelectOption>
            );
          })}
        </IonSelect>
      </IonItem>

      <div
        className="text-red-600 text-xs pl-3 py-1 self-center bg-slate-50"
        hidden={!erro}
      >
        {errors[name]?.message?.toString()}
      </div>
    </div>
  );
};

export default SelectField;
