import useWindowDimensions from "../../hooks/useWindowDimensions";

export interface SubmitButtonProps {
  label: string;
  className?: string;
  fullWidth?: boolean;
}

const SubmitButton = ({ label, className, fullWidth }: SubmitButtonProps) => {
  const { isMobileScreen } = useWindowDimensions();

  let btnClass =
    className ||
    "p-4 bg-primary hover:bg-secondary rounded-xl text-sm font-medium text-slate-50 transition duration-200";

  btnClass = btnClass?.concat(isMobileScreen || fullWidth ? " w-full" : "");

  return (
    <div className="text-center">
      <button type="submit" style={{ minWidth: 180 }} className={btnClass}>
        {label}
      </button>
    </div>
  );
};

export default SubmitButton;
