import React from "react";

const DivEmailIndividualizados: React.FC = () => {
  return (
    <div className="">
      <div className="mb-2 text-sm font-medium text-slate-700">
        Após o cadastro, o sistema enviará mensalmente um extrato detalhado para
        o e-mail fornecido, contendo informações sobre o consumo individual e
        nas áreas comuns. Isso ajudará o gestor da conta macro a monitorar e
        analisar o consumo.
      </div>
    </div>
  );
};

export default DivEmailIndividualizados;
