import {
  InputAdornment,
  StandardTextFieldProps,
  TextField,
} from "@mui/material";
import { useFormContext } from "react-hook-form";
import { classNames } from "primereact/utils";
import { IonIcon } from "@ionic/react";
import { eye, eyeOff } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import MaskField from "./MaskField";
import usePrevious from "../../hooks/usePrevious";

export interface InputTextProps extends StandardTextFieldProps {
  name: string;
  label?: string;
  mask?: string;
  defaultValue?: string;
  readonly?: boolean;
}

const InputText: React.FC<InputTextProps> = (props: InputTextProps) => {
  const {
    register,
    resetField,
    formState: { isSubmitting, errors },
  } = useFormContext();

  const { name, label, mask, readonly, defaultValue, ...propsOriginal } = props;

  const previousValue = usePrevious(propsOriginal.value);
  const previousDefaultValue = usePrevious(defaultValue);
  const previousLabelValue = usePrevious(label);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (previousValue !== propsOriginal.value) {
      resetField(name, { defaultValue: propsOriginal.value });
    }
  }, [propsOriginal.value]);

  useEffect(() => {
    if (previousDefaultValue !== defaultValue) {
      resetField(name, { defaultValue: defaultValue });
    }
  }, [defaultValue]);

  useEffect(() => {
    if (previousLabelValue !== label) {
      resetField(name, {
        defaultValue: defaultValue || "",
      });
    }
  }, [label]);

  return (
    <div className={classNames({ "pointer-events-none opacity-70": readonly })}>
      {!mask ? (
        <TextField
          {...propsOriginal}
          className="bg-slate-50 w-full"
          label={label ? label : name}
          variant="outlined"
          helperText={errors[name as string]?.message?.toString()}
          error={!!errors[name as string]}
          {...register(name)}
          disabled={propsOriginal.disabled || isSubmitting}
          defaultValue={defaultValue}
          onBlur={propsOriginal.onBlur}
          onInput={propsOriginal.onInput} // use para capturar o onchange (event.target as HTMLInputElement)?.value
          type={
            propsOriginal.type === "password" && showPassword
              ? "text"
              : propsOriginal.type
          }
          InputProps={{
            endAdornment:
              propsOriginal.type === "password" ? (
                <InputAdornment position="end">
                  <IonIcon
                    onClick={() => setShowPassword((show) => !show)}
                    className="text-2xl opacity-80"
                    color="primary"
                    icon={showPassword ? eyeOff : eye}
                  />
                </InputAdornment>
              ) : null,
          }}
        />
      ) : (
        <MaskField
          mask={mask}
          name={name}
          label={label}
          value={propsOriginal.value as any}
          disabled={propsOriginal.disabled}
          onBlur={propsOriginal.onBlur}
          onComplete={propsOriginal.onInput as any} // use para capturar ao complementar a mascara e?.value
        />
      )}
    </div>
  );
};

export default InputText;
