import {
  downloadPDF,
  formatarData,
  formatarDinheiro,
} from "../../utils/functions";
import { useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useHistory } from "react-router";
import { ApiRoutes, Debito, Mensagens } from "../../models/types";
import { Clipboard } from "@capacitor/clipboard";
import { IonButton, useIonLoading } from "@ionic/react";
import { QRCodeSVG } from "qrcode.react";
import { useAxiosClient } from "../../hooks/useAxiosClient";
import { useContaUsuario } from "../../contexts/contaUsuarioContext";
import { useAuth } from "../../contexts/authContext";
import PageRequestConta from "../../components/PageRequest";
import CardCliente from "../../components/CardCliente";
import useNotification from "../../hooks/useNotification";

export interface Fatura {
  numeroConta: string;
  numDocumentoFiscal: string;
  valorTotalDebito: number;
  numeroCodigoBarra: string;
  codigoPix: string;
}

export default function SegundaViaFatura() {
  const axiosClient = useAxiosClient(ApiRoutes.ECO_FATURAMENTO);
  const navigate = useHistory<any>();
  const notification = useNotification();

  const [data, setData] = useState<Fatura | null>(null);
  const [present, dismiss] = useIonLoading();

  const { contaSelecionada } = useContaUsuario();
  const { usuario } = useAuth();

  //dados vindos da navegação
  const debitos: Debito[] = navigate.location.state?.data;

  async function copiarCodigoBarras() {
    await Clipboard.write({
      string: data?.numeroCodigoBarra
        ? data?.numeroCodigoBarra.split("-").join("").split(" ").join("")
        : data?.numeroCodigoBarra,
    })
      .then(() => {
        notification.showSuccess({
          message: "Código de barras copiado para área de transferência.",
        });
      })
      .catch(() => {
        notification.showAlert({
          message: "Ocorreu um erro ao copiar o código de barras.",
        });
      });
  }

  async function copiarCodigoPix() {
    await Clipboard.write({ string: data?.codigoPix })
      .then(() => {
        notification.showSuccess({
          message: "Código Pix copiado para área de transferência.",
        });
      })
      .catch(() => {
        notification.showAlert({
          message: "Ocorreu um erro ao copiar o código Pix.",
        });
      });
  }

  async function gerarFatura() {
    if (!debitos) {
      return;
    }

    await present({ message: Mensagens.CONSULTANDO });

    try {
      const documentos = debitos?.map((item) => {
        return item.numeroDocumentoFormatado;
      });

      const body = {
        cpfCnpj: usuario?.cpfCnpj,
        tipoCliente: usuario?.tipoCliente,
        numeroConta: contaSelecionada?.numeroContaComDigito,
        documentos: documentos,
        exibirDadosFatura: true,
      };

      const res = await axiosClient.post(
        "/faturamentos/segunda-via/individual",
        JSON.stringify(body),
        {
          headers: { "content-type": "application/json" },
          responseType: "blob",
        }
      );

      notification.showSuccess({
        message: Mensagens.FATURA_GERADA,
      });

      downloadPDF("Segunda via Saneago", res.data);

      dismiss();
    } catch (e: any) {
      dismiss();

      await notification.showError({ exception: e });
    }
  }

  return (
    <PageRequestConta<Fatura>
      title="Emissão de 2ª via da fatura"
      desabilitarCardConta={true}
      buttonType="back"
      requestType="post"
      api={ApiRoutes.ECO_FATURAMENTO}
      endPoint="/faturamentos/segunda-via/mobile"
      nomeParametroConta="numeroConta"
      extraParams={{
        documentos: debitos?.map((item) => {
          return item.numeroDocumentoFormatado;
        }),
      }}
      onContaChange={(data: Fatura) => {
        setData(data);
        gerarFatura();
      }}
    >
      <div className="flex flex-col space-y-4">
        <CardCliente />

        <div className="card-borda">
          <div className="card-title">Detalhes das faturas agrupadas</div>

          <DataTable
            value={debitos}
            emptyMessage={Mensagens.NENHUM_RESULTADO_ENCONTRADO}
            responsiveLayout="scroll"
            size="small"
            stripedRows
          >
            <Column
              field="dataVencimento"
              header="Vencimento"
              alignHeader={"right"}
              align={"right"}
              style={{ fontSize: "0.9rem" }}
              body={dataVencimentoBodyTemplate}
            ></Column>

            <Column
              field="consumoMedido"
              header="Consumo(m³)"
              alignHeader={"right"}
              align={"right"}
              style={{ fontSize: "0.9rem" }}
            ></Column>

            <Column
              field="valorDebito"
              header="Valor"
              alignHeader={"right"}
              align={"right"}
              style={{ fontSize: "0.9rem" }}
              body={valorBodyTemplate}
            ></Column>
          </DataTable>
        </div>

        <div className="font-medium grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div className="grid grid-cols-3 bg-secondary bg-opacity-5 px-4 py-2 rounded-2xl align-middle">
            <div className="col-span-2 text-sm text-secondary">
              Número do documento:
            </div>
            <div className="text-right text-slate-700">
              {data?.numDocumentoFiscal}
            </div>
          </div>

          <div className="grid grid-cols-3 bg-secondary bg-opacity-5 px-4 py-2 rounded-2xl">
            <div className="col-span-2 text-sm text-secondary">
              Valor total da fatura:
            </div>
            <div className="text-right text-slate-700">
              {formatarDinheiro(data?.valorTotalDebito || 0)}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="card-borda" hidden={!data?.codigoPix}>
            <div className="card-title">Pix</div>

            <div className="flex flex-col items-center">
              <div className="flex flex-col flex- items-center justify-center pb-4">
                <QRCodeSVG value={data?.codigoPix || ""} size={156} level="M" />
              </div>

              <div className="text-center">
                <IonButton
                  type="button"
                  mode="ios"
                  fill="outline"
                  onClick={() => copiarCodigoPix()}
                >
                  <div className="font-bold text-sm">Pix Copia e Cola</div>
                </IonButton>
              </div>
            </div>
          </div>

          <div className="card-borda">
            <div className="card-title">Código de barras</div>

            <div className="flex flex-col items-center justify-center md:h-56">
              <div className="text-center pb-4">{data?.numeroCodigoBarra}</div>

              <div className="text-center">
                <IonButton
                  type="button"
                  mode="ios"
                  fill="outline"
                  onClick={() => copiarCodigoBarras()}
                >
                  <div className="font-bold text-sm">
                    Copiar código de barras
                  </div>
                </IonButton>
              </div>
            </div>
          </div>

          <div className="card-borda">
            <div className="card-title">Fatura em PDF</div>

            <div className="flex flex-col items-center justify-center md:h-56">
              <div className="text-center">
                <IonButton
                  type="button"
                  mode="ios"
                  fill="outline"
                  onClick={() => gerarFatura()}
                >
                  <div className="font-bold text-sm">Download da fatura</div>
                </IonButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageRequestConta>
  );
}

const valorBodyTemplate = (rowData: { valorDebito: number | bigint }) => {
  return formatarDinheiro(rowData.valorDebito);
};

const dataVencimentoBodyTemplate = (rowData: { dataVencimento: unknown }) => {
  return formatarData(rowData.dataVencimento);
};
