import {
  ApiRoutes,
  CODIGO_UNIDADE_ATENDIMENTO,
  MATRICULA_SOLICITANTE,
  Mensagens,
  TIPOS_DENUNCIA,
  TIPOS_IRREGULARIDADE_AGUA,
  TIPOS_IRREGULARIDADE_ESGOTO,
} from "../../models/types";
import { getBaseURL, getOnlyNumbers } from "../../utils/functions";
import { useCallback, useState } from "react";
import { z } from "zod";
import { useIonLoading } from "@ionic/react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useProtocolo } from "../../contexts/protocoloContext";
import Page from "../../components/Page";
import useNotification from "../../hooks/useNotification";
import ReactForm from "../../components/form/ReactForm";
import InputText from "../../components/form/InputText";
import SubmitButton from "../../components/form/SubmitButton";
import Accordion from "../../components/Accordion";
import PesquisaCidade from "../../components/form/PesquisaCidade";
import PesquisaBairro from "../../components/form/PesquisaBairro";
import PesquisaLogradouro from "../../components/form/PesquisaLogradouro";
import InputComplete from "../../components/form/InputComplete";
import ButtonBack from "../../components/ButtonBack";
import DivDenunciaIrregularidade from "../../components/DivDenunciaIrregularidade";
import axios from "axios";

export default function DenunciaIrregularidadeNoEndereco() {
  const [present, dismiss] = useIonLoading();
  const [cidade, setCidade] = useState<number | null>();
  const [bairro, setBairro] = useState<number | null>();
  const [tipoDenuncia, setTipoDenuncia] = useState<string | null>("agua");

  const { executeRecaptcha } = useGoogleReCaptcha();
  const { getProtocoloAnonimo } = useProtocolo();

  const notification = useNotification();

  const formSchema = z.object({
    codigoCidade: z
      .string()
      .min(1, "Selecione uma cidade.")
      .transform((val) => Number(getOnlyNumbers(val))),
    codigoBairro: z
      .string()
      .min(1, "Selecione um bairro.")
      .transform((val) => Number(getOnlyNumbers(val))),
    codigoLogradouro: z
      .string()
      .min(1, "Selecione um logradouro.")
      .transform((val) => Number(getOnlyNumbers(val))),
    quadraImovel: z.string().min(1, "Informe a quadra."),
    loteImovel: z.string().min(1, "Informe o lote."),
    numeroImovel: z.string().min(1, "Informe o número."),
    pontoReferencia: z.string().min(1, "Informe um ponto de referência."),
    tipoDenuncia: z.string().min(1, "Selecione o tipo de denúncia."),
    tipoIrregularidade: z
      .string()
      .min(1, "Selecione o tipo de irregularidade."),
  });

  type formType = z.infer<typeof formSchema>;

  async function enviarDados(dados: formType, resetFields: any) {
    await present({ message: Mensagens.SALVANDO });

    const token = await getReCaptchaToken();

    const protocolo = await getProtocoloAnonimo({
      reCaptchaToken: token as string,
    });

    if (!protocolo || !protocolo.numeroProtocolo) {
      dismiss();
      return;
    }

    try {
      let body = {
        matriculaSolicitante: MATRICULA_SOLICITANTE,
        codigoUnidadeAtendimento: CODIGO_UNIDADE_ATENDIMENTO,
        codigoCidade: dados.codigoCidade,
        codigoBairro: dados.codigoBairro,
        codigoLogradouro: dados.codigoLogradouro,
        quadraImovel: dados.quadraImovel,
        loteImovel: dados.loteImovel,
        numeroImovel: dados.numeroImovel,
        pontoReferencia: dados.pontoReferencia,
        descricaoTipoIrregularidade: dados.tipoIrregularidade,
      };

      let url =
        getBaseURL(ApiRoutes.ECO_ATENDIMENTO_EXTERNO) +
        `/atendimentos/${protocolo.numeroProtocolo}/registrar/`;

      url = isTipoDenunciaAgua()
        ? url.concat("irregularidade-agua")
        : url.concat("irregularidade-esgoto");

      const token_ = await getReCaptchaToken();

      const params = new URLSearchParams({
        "g-recaptcha-response": token_ as string,
      });

      await axios.post(url, JSON.stringify(body), {
        params: params,
        headers: {
          "content-type": "application/json",
        },
      });

      notification.showProtocolo(protocolo.numeroProtocolo).then(() => {
        resetFields();
      });

      dismiss();
    } catch (e: any) {
      dismiss();

      let msg = Mensagens.ERRO_INESPERADO;

      if (e.response.status === 403) {
        msg = Mensagens.SOLICITACAO_ABERTA;
      }

      await notification.showError({ message: msg, exception: e });
    }
  }

  const getReCaptchaToken = useCallback(async () => {
    if (!executeRecaptcha) {
      notification.showWarning({ message: Mensagens.RECAPTCHA_INDISPONIVEL });
      return null;
    }

    const token = await executeRecaptcha();
    return token;
  }, [executeRecaptcha]);

  function isTipoDenunciaAgua() {
    return tipoDenuncia === "agua";
  }

  return (
    <Page
      title="Denuncia de irregularidade"
      buttonType="back"
      showCardConta={false}
      reCaptcha
    >
      <div className="flex flex-col space-y-4">
        <Accordion title="Observações" open>
          <DivDenunciaIrregularidade />
        </Accordion>

        <div className="card bg-opacity-70">
          <div className="card-title">Dados da ocorrência</div>

          <div className="items-center">
            <ReactForm
              schema={formSchema}
              onError={(error, e) => {}}
              onSubmit={(data, resetFields) =>
                enviarDados(data as formType, resetFields)
              }
            >
              <div className="flex flex-col space-y-4">
                <PesquisaCidade
                  name="codigoCidade"
                  placeHolder="Cidade"
                  onSelect={(item) => {
                    setCidade(item?.value as number);
                  }}
                />

                <PesquisaBairro
                  name="codigoBairro"
                  placeHolder="Bairro"
                  codigoCidade={cidade}
                  onSelect={(item) => {
                    setBairro(item?.value as number);
                  }}
                />

                <PesquisaLogradouro
                  name="codigoLogradouro"
                  placeHolder="Logradouro"
                  codigoCidade={cidade}
                  codigoBairro={bairro}
                />

                <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3 gap-4">
                  <InputText
                    name="quadraImovel"
                    label="Quadra"
                    inputProps={{ maxLength: 5 }}
                  />
                  <InputText
                    name="loteImovel"
                    label="Lote"
                    inputProps={{ maxLength: 9 }}
                  />
                  <InputText
                    name="numeroImovel"
                    label="Número"
                    inputProps={{ maxLength: 5 }}
                  />
                </div>

                <InputText
                  name="pontoReferencia"
                  label="Ponto de referência"
                  inputProps={{ maxLength: 50 }}
                />

                <InputComplete
                  name="tipoDenuncia"
                  placeHolder="Tipo de denúncia"
                  items={TIPOS_DENUNCIA}
                  defaultValuePosition={0}
                  disableClearable={true}
                  onSelect={(item) => {
                    setTipoDenuncia(item?.value as string);
                  }}
                />

                <InputComplete
                  name="tipoIrregularidade"
                  placeHolder="Tipo de irregularidade"
                  showDivDetalhes
                  titleDivDetalhes="Descrição da irregularidade selecionada"
                  items={
                    isTipoDenunciaAgua()
                      ? TIPOS_IRREGULARIDADE_AGUA
                      : TIPOS_IRREGULARIDADE_ESGOTO
                  }
                />

                <div className="grid grid-cols-2 gap-2 xs:grid-cols-1">
                  <SubmitButton label="Enviar" />
                  <ButtonBack label="Voltar" />
                </div>
              </div>
            </ReactForm>
          </div>
        </div>
      </div>
    </Page>
  );
}
