import {
  downloadPDF,
  formatarData,
  formatarDinheiro,
} from "../utils/functions";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useState } from "react";
import { ApiRoutes, Debito, Mensagens } from "../models/types";
import { useAuth } from "../contexts/authContext";
import { useContaUsuario } from "../contexts/contaUsuarioContext";
import { useAxiosClient } from "../hooks/useAxiosClient";
import { IonIcon, useIonLoading } from "@ionic/react";
import PageRequestConta from "../components/PageRequest";
import useNotification from "../hooks/useNotification";

export interface UltimasFaturas {
  mensagem: string;
  listaResultados: Debito[];
}

export default function UltimasFaturasPage() {
  const [data, setData] = useState<UltimasFaturas | null>(null);
  const [present, dismiss] = useIonLoading();

  const { usuario } = useAuth();
  const { contaSelecionada } = useContaUsuario();

  const axiosClient = useAxiosClient(ApiRoutes.ECO_FATURAMENTO);
  const notification = useNotification();

  async function consultar(
    numeroDocumentoFormatado: string,
    anoMesReferencia: number
  ) {
    await present({ message: Mensagens.CONSULTANDO });

    try {
      let body = {
        cpfCnpj: usuario?.cpfCnpj,
        tipoCliente: usuario?.tipoCliente,
        documento: numeroDocumentoFormatado,
        espelhoFatura: true,
        numeroConta: contaSelecionada?.numeroContaComDigito,
      };

      const res = await axiosClient.post(
        "/faturamentos/fatura/original",
        JSON.stringify(body),
        { responseType: "blob" }
      );

      dismiss();

      downloadPDF(`Espelho fatura ${anoMesReferencia}`, res.data);
    } catch (e: any) {
      dismiss();

      await notification.showError({ exception: e });
    }
  }

  const downloadBodyTemplate = (rowData: {
    dataPagamentoDebito: unknown;
    anoMesReferencia: any;
    numeroDocumentoFormatado: any;
  }) => {
    if (rowData.dataPagamentoDebito) {
      return (
        <IonIcon
          src="assets/icon/download.svg"
          className="text-2xl"
          onClick={() => {
            consultar(
              rowData.numeroDocumentoFormatado,
              rowData.anoMesReferencia
            );
          }}
        />
      );
    }

    return null;
  };

  return (
    <PageRequestConta<UltimasFaturas>
      title="Últimas faturas"
      api={ApiRoutes.ECO_FATURAMENTO}
      endPoint="/faturamentos/faturas"
      extraParams={{ quantidadeMeses: 12 }}
      onContaChange={(data: UltimasFaturas) => setData(data)}
      necessarioSelecionarConta
    >
      <div className="flex flex-col space-y-4">
        <div className="card">
          <div
            className="font-medium mb-4 text-slate-700"
            hidden={
              !data ||
              !data.mensagem ||
              data.mensagem ===
                "Últimas faturas para a conta " +
                  contaSelecionada?.numeroContaComDigito
            }
          >
            {data?.mensagem}
          </div>
          <div className="text-slate-700">
            A listagem abaixo é referente aos últimos 12 meses. Podem haver
            outras faturas e débitos pendentes anteriores a este período. Em
            caso de dúvidas, favor entrar em contato com a Central de
            Relacionamento da Saneago pelo telefone 0800 645 0115, ou procure
            uma de nossas unidades de atendimento presencial.
          </div>
        </div>

        <div className="card-borda">
          <div className="card-title">Faturas dos últimos 12 meses</div>

          <DataTable
            value={data?.listaResultados}
            emptyMessage={Mensagens.NENHUM_RESULTADO_ENCONTRADO}
            responsiveLayout="scroll"
            size="small"
            stripedRows
          >
            <Column body={downloadBodyTemplate}></Column>
            <Column
              field="referenciaFormatada"
              header="Referência"
              alignHeader={"right"}
              align={"right"}
              style={{ fontSize: "0.9rem" }}
            ></Column>
            <Column
              field="dataVencimento"
              header="Vencimento"
              alignHeader={"right"}
              align={"right"}
              style={{ fontSize: "0.9rem" }}
              body={dataVencimentoBodyTemplate}
            ></Column>
            <Column
              field="dataPagamentoDebito"
              header="Pagamento"
              alignHeader={"right"}
              align={"right"}
              style={{ fontSize: "0.9rem" }}
              body={dataPagamentoBodyTemplate}
            ></Column>
            <Column
              field="valorDebito"
              header="Valor Pago"
              alignHeader={"right"}
              align={"right"}
              style={{ fontSize: "0.9rem" }}
              body={valorBodyTemplate}
            ></Column>
          </DataTable>
        </div>
      </div>
    </PageRequestConta>
  );
}

const dataVencimentoBodyTemplate = (rowData: { dataVencimento: unknown }) => {
  return formatarData(rowData.dataVencimento);
};

const dataPagamentoBodyTemplate = (rowData: {
  dataPagamentoDebito: unknown;
  statusFatura: string;
}) => {
  if (rowData.dataPagamentoDebito) {
    return formatarData(rowData.dataPagamentoDebito);
  }

  return rowData.statusFatura;
};

const valorBodyTemplate = (rowData: {
  valorDebito: number | bigint;
  dataPagamentoDebito: unknown;
}) => {
  if (rowData.dataPagamentoDebito) {
    return formatarDinheiro(rowData.valorDebito);
  }

  return "";
};
