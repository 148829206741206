import { IonButton } from "@ionic/react";
import { openURL } from "../utils/functions";
import Page from "../components/Page";
import useWindowDimensions from "../hooks/useWindowDimensions";

export default function FramePage(props: { src: string; title: string }) {
  const { isLittleScreen } = useWindowDimensions();

  return (
    <Page title={props.title} showCardConta={false} framePage>
      <div className="h-full">
        <div className="card h-full" hidden={isLittleScreen}>
          <iframe
            title={props.title}
            className="w-full h-full aspect-video "
            src={props.src}
          />
        </div>

        <div className="flex flex-col space-y-4" hidden={!isLittleScreen}>
          <div className="card">
            <div className="text-slate-700">
              Por favor clique no botão abaixo para que assim você seja
              redirecionado para a página desejada.
            </div>

            <div className="text-center pt-4">
              <IonButton
                type="button"
                mode="ios"
                fill="outline"
                onClick={() => openURL(props.src)}
              >
                {props.title}
              </IonButton>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}
