import { useContaUsuario } from "../contexts/contaUsuarioContext";

export default function CardCliente() {
  const { contaSelecionada } = useContaUsuario();

  return (
    <div
      className="card"
      // hidden={!contaSelecionada}
    >
      <div className="card-title">Identificação do cliente</div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <div className="text-sm text-secondary font-medium">Proprietário</div>
          <div className="">{contaSelecionada?.nomeProprietario}</div>
        </div>

        <div>
          <div className="text-sm text-secondary font-medium">Titular</div>
          <div className="">{contaSelecionada?.nomeTitular}</div>
        </div>
      </div>
    </div>
  );
}
