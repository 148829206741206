import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { z } from "zod";
import { useIonLoading } from "@ionic/react";
import { useAuth } from "../contexts/authContext";
import { Mensagens, TIPOS_PESSOA, TipoPessoa } from "../models/types";
import { isCnpjValido, isCpfValido } from "../utils/functions";
import { IonButton, IonMenuButton } from "@ionic/react";
import InputText from "../components/form/InputText";
import ReactForm from "../components/form/ReactForm";
import SubmitButton from "../components/form/SubmitButton";
import Page from "../components/Page";
import useWindowDimensions from "../hooks/useWindowDimensions";
import SelectField from "../components/form/SelectField";

export default function LoginPage() {
  const { isMobileScreen } = useWindowDimensions();
  const [present, dismiss] = useIonLoading();

  const authContext = useAuth();
  const navigate = useHistory();

  useEffect(() => {
    authContext.recuperarSessao();
  }, []);

  const formSchema = z
    .object({
      cpfCnpj: z.string(),
      senha: z.string().min(1, "Informe uma senha."),
      tipoCliente: z.any().transform((input) => input?.value),
    })
    .refine(
      (data) => data.cpfCnpj,
      (data) => ({
        message:
          data.tipoCliente === TipoPessoa.PESSOA_FISICA
            ? "Informe um CPF."
            : "Informe um CNPJ.",
        path: ["cpfCnpj"],
      })
    )
    .refine(
      (data) =>
        data.tipoCliente === TipoPessoa.PESSOA_FISICA
          ? isCpfValido(data.cpfCnpj)
          : isCnpjValido(data.cpfCnpj),
      (data) => ({
        message:
          data.tipoCliente === TipoPessoa.PESSOA_FISICA
            ? "CPF inválido."
            : "CNPJ inválido.",
        path: ["cpfCnpj"],
      })
    );

  async function logar(dados: any) {
    await present({ message: Mensagens.VALIDANDO_CREDENCIAIS });

    const response = await authContext.login(dados.cpfCnpj, dados.senha);

    dismiss();

    if (response) {
      navigate.push("/home");
    }
  }

  return (
    <Page title=" " showCardConta={false}>
      <LayoutDesktop formSchema={formSchema} submit={(e: any) => logar(e)} />
    </Page>
  );

  // return isMobileScreen ? (
  //   <IonPage>
  //     <LayoutMobile formSchema={formSchema} submit={(e: any) => logar(e)} />
  //   </IonPage>
  // ) : (
  //   <Page title=" " showCardConta={false}>
  //     <LayoutDesktop formSchema={formSchema} submit={(e: any) => logar(e)} />
  //   </Page>
  // );
}

function LayoutDesktop({ formSchema, submit }: any) {
  return (
    <div>
      {/* formulario */}
      <div className="flex justify-center items-center h-page">
        <div className="w-full space-y-12 sm:w-1/2 md:w-2/3 lg:w-2/3 xl:w-1/2 2xl:w-1/2">
          <div className="w-full flex justify-center items-start">
            <img
              className="w-28 lg:w-32"
              src="./assets/logo-nome-baixo.svg"
              alt=""
            ></img>
          </div>

          <div className="card bg-opacity-70">
            <div className="space-y-6">
              <div className="font-extrabold text-xl text-primary">Login</div>
              <LayoutForm
                formSchema={formSchema}
                submit={(e: any) => submit(e)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function LayoutMobile({ formSchema, submit }: any) {
  return (
    <div className="h-screen w-screen">
      {/* icone menu */}
      <div className="absolute z-10 top-3 left-3 rounded-full bg-white h-10 w-10">
        <IonMenuButton color="primary" />
      </div>

      {/* imagem degrade */}
      <div className="absolute -z-30 bg-fundo-login w-screen h-screen bg-no-repeat bg-center bg-cover" />

      <div className="flex justify-center">
        {/* logo saneago */}
        <div className="logo-saneago-estilo bg-logo-saneago" />

        {/* imagem mulher */}
        <div className="fundo-mulher-estilo bg-fundo-mulher" />
      </div>

      {/* formulario */}
      <div className="flex justify-center items-center h-screen w-screen">
        <div className="w-full p-4">
          <div className=" flex flex-row  justify-end items-end h-page">
            <div className="card bg-opacity-70">
              <div className="">
                <LayoutForm
                  formSchema={formSchema}
                  submit={(e: any) => submit(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function LayoutForm({ formSchema, submit }: any) {
  const [tipoCliente, setTipoCliente] = useState<string>();

  const isTipoClientePessoaFisica = (() => {
    return tipoCliente === TipoPessoa.PESSOA_FISICA;
  })();

  return (
    <ReactForm
      schema={formSchema}
      onError={(error, e) => {}}
      onSubmit={(e) => submit(e)}
    >
      <div className="flex flex-col justify-center items-center space-y-4">
        <div className="w-full">
          <SelectField
            name="tipoCliente"
            placeHolder="Tipo de cliente"
            defaultValuePosition={0}
            onSelect={(item) => {
              setTipoCliente(item?.value as string);
            }}
            items={TIPOS_PESSOA}
          />
        </div>

        <div className="w-full">
          <InputText
            name="cpfCnpj"
            label={isTipoClientePessoaFisica ? "CPF" : "CNPJ"}
            mask={
              isTipoClientePessoaFisica
                ? "999.999.999-99"
                : "99.999.999/9999-99"
            }
          />
        </div>
        <div className="w-full">
          <InputText
            name="senha"
            label="Senha"
            type={"password"}
            inputProps={{ maxLength: 12 }}
            autoComplete="off"
          />
        </div>

        <div className="grid grid-cols-1 gap-2 lg:grid-cols-2 md:grid-cols-2 w-full">
          <SubmitButton label="Entrar" fullWidth />

          <IonButton
            style={{ height: "52px" }}
            type="button"
            className="w-full m-0"
            mode="ios"
            fill="outline"
            routerLink="/cadastro"
          >
            Cadastrar
          </IonButton>
        </div>

        <IonButton
          style={{ height: "15px" }}
          type="button"
          className="w-full"
          mode="ios"
          fill="clear"
          routerLink="/recuperar"
        >
          <div className="font-bold text-sm">Esqueci minha senha</div>
        </IonButton>
      </div>
    </ReactForm>
  );
}
