import { useState } from "react";
import CadastroTelefone from "./CadastroTelefone";
import CadastroEmail from "./CadastroEmail";
import CadastroTabelaTelefones from "./CadastroTabelaTelefones";
import { getNumeroDDD, getNumeroSemDDD } from "../../utils/functions";
import { useContaUsuario } from "../../contexts/contaUsuarioContext";
import { useAuth } from "../../contexts/authContext";
import {
  ApiRoutes,
  CadastroFaturaDigitalData,
  MATRICULA_SOLICITANTE,
  Mensagens,
} from "../../models/types";
import { useIonLoading } from "@ionic/react";
import { useAxiosClient } from "../../hooks/useAxiosClient";
import useNotification from "../../hooks/useNotification";

// interfaces para constrole das instancias de dados

export interface EmailFormData {
  email: string;
  confirmarEmail: string;
}

export interface TelefoneFormData {
  telefoneCompleto: string;
  ddd: number;
  numero: number;
  tipo: string;
  possuiWhats: boolean;
}

export interface CadastroFaturaDigitalFormData {
  email: string;
  telefones: TelefoneFormData[];
}

const CadastroFaturaDigital = () => {
  const estadoInicial = { email: "", telefones: [] };
  const [etapa, setEtapa] = useState(1); // 1: Email, 2: Telefone, 3: Tabela de Telefones
  const [dadosCadastro, setDadosCadastro] =
    useState<CadastroFaturaDigitalFormData>(estadoInicial);
  const { usuario } = useAuth();
  const [present, dismiss] = useIonLoading();
  const notification = useNotification();
  const { contaSelecionada, reconsultarDadosContaSelecionada } =
    useContaUsuario();

  const axiosClient = useAxiosClient(ApiRoutes.ECO_ATENDIMENTO);

  const adicionarEmail = (emailData: EmailFormData) => {
    setDadosCadastro({ ...dadosCadastro, email: emailData.email });
    setEtapa(2); // Muda para a etapa de cadastro de telefone
  };

  const adicionarTelefone = (telefoneData: TelefoneFormData) => {
    const telefoneDataDividido = {
      telefoneCompleto: telefoneData.telefoneCompleto,
      ddd: getNumeroDDD(telefoneData.telefoneCompleto),
      numero: getNumeroSemDDD(telefoneData.telefoneCompleto),
      tipo: telefoneData.tipo,
      possuiWhats: telefoneData.possuiWhats,
    };

    const indiceExistente = dadosCadastro.telefones.findIndex(
      (tel) => tel.telefoneCompleto === telefoneDataDividido.telefoneCompleto
    );

    if (indiceExistente !== -1) {
      notification.showWarning({
        message: "Telefone já inserido na lista.",
      });
      return;
    }

    let telefonesAtualizados;
    telefonesAtualizados = [...dadosCadastro.telefones, telefoneDataDividido];

    setDadosCadastro({
      ...dadosCadastro,
      telefones: telefonesAtualizados,
    });

    setEtapa(3); // Volta para a tabela de telefones após adicionar um telefone
  };

  const removerTelefone = (index: number) => {
    const telefoneARemover = dadosCadastro.telefones[index];

    // Verifica se o telefone a ser removido é do tipo celular
    if (telefoneARemover.tipo === "CELULAR") {
      const totalCelulares = dadosCadastro.telefones.filter(
        (tel) => tel.tipo === "CELULAR"
      ).length;
      const existemOutrosTipos = dadosCadastro.telefones.some(
        (tel) => tel.tipo !== "CELULAR"
      );

      // Se houver apenas um celular na lista e existirem telefones de outros tipos, não permitir a remoção
      if (totalCelulares === 1 && existemOutrosTipos) {
        notification.showWarning({
          message:
            "Antes de remover este número, um novo número de celular deverá ser adicionado.",
        });
        return;
      }
    }

    // Continua com a remoção se a condição acima não for atendida
    const telefonesAtualizados = dadosCadastro.telefones.filter(
      (_, i) => i !== index
    );
    setDadosCadastro({ ...dadosCadastro, telefones: telefonesAtualizados });

    // Se a lista de telefones estiver vazia após a remoção, volte para a etapa de adição de telefone
    if (telefonesAtualizados.length === 0) {
      setEtapa(2);
    }
  };

  const handleCancelar = () => {
    if (etapa === 2 && dadosCadastro.telefones.length > 0) {
      // Se estiver na etapa 2 e o telefone já estiver sido definido, volte para a etapa 3
      setEtapa(3);
    } else {
      // Em todos os outros casos, volte para a etapa 1 e redefina os dados
      setDadosCadastro(estadoInicial);
      setEtapa(1);
    }
  };

  const submeterCadastro = async () => {
    const dadosCadastroData: CadastroFaturaDigitalData = {
      numeroConta: contaSelecionada?.numeroConta || 0,
      tipoCliente: usuario?.tipoCliente || "",
      numeroCpfCnpj: usuario?.cpfCnpj || "",
      email: dadosCadastro.email,
      telefones: dadosCadastro.telefones.map((telefone) => ({
        ddd: telefone.ddd,
        numero: telefone.numero,
        tipo: telefone.tipo,
        possuiWhats: telefone.possuiWhats,
      })),
      matriculaSolicitante: MATRICULA_SOLICITANTE,
    };

    try {
      await present({ message: "Enviando dados..." });
      const response = await axiosClient.post(
        "/atendimentos/aderir-fatura-digital",
        dadosCadastroData
      );
      dismiss();

      notification.showSuccess({
        message:
          "O email de confirmação foi enviado para o endereço informado, verifique sua caixa de entrada.",
      });
    } catch (e: any) {
      dismiss();
      await notification.showError({ exception: e });
    }

    await reconsultarDadosContaSelecionada();
  };

  const renderizarEtapa = () => {
    switch (etapa) {
      case 1:
        return (
          <CadastroEmail
            onSubmit={adicionarEmail}
            handleCancelar={handleCancelar}
          />
        );
      case 2:
        return (
          <CadastroTelefone
            onSubmit={adicionarTelefone}
            handleCancelar={handleCancelar}
            telefones={dadosCadastro.telefones}
          />
        );
      case 3:
        return (
          <CadastroTabelaTelefones
            telefones={dadosCadastro.telefones}
            onAdicionarTelefone={() => setEtapa(2)}
            onRemoverTelefone={removerTelefone}
            onSubmit={submeterCadastro}
            handleCancelar={handleCancelar}
          />
        );
      default:
        return <div>Etapa desconhecida</div>;
    }
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="card bg-opacity-70">
        <div className="card-title">Cadastro fatura digital</div>
        <div>{renderizarEtapa()}</div>
      </div>
    </div>
  );
};

export default CadastroFaturaDigital;
