import { TIPOS_ORIGEM_OCORRENCIA } from "../../models/types";
import { useContaUsuario } from "../../contexts/contaUsuarioContext";
import { z } from "zod";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";

import Page from "../../components/Page";
import Accordion from "../../components/Accordion";
import useNotification from "../../hooks/useNotification";
import ReactForm from "../../components/form/ReactForm";
import SubmitButton from "../../components/form/SubmitButton";
import DivReclamacaoAgua from "../../components/DivReclamacaoAgua";
import SelectField from "../../components/form/SelectField";

export default function ReclamacaoAgua() {
  const [open, setOpen] = useState<boolean>(true);

  const contaUsuarioContext = useContaUsuario();
  const navigate = useHistory();
  const notification = useNotification();

  useEffect(() => {
    setOpen(true);
  }, []);

  function nextPage({ origemOcorrencia }: any) {
    if (origemOcorrencia === "conta") {
      //
      if (!contaUsuarioContext.contaSelecionada) {
        notification.showNecessarioSelecionarConta();
        return;
      }

      navigate.push("/reclamacaoAguaNaConta");
    } else {
      navigate.push({
        pathname: "/reclamacaoAguaNoEndereco",
        state: { close: true },
      });
    }
  }

  const formSchema = z.object({
    origemOcorrencia: z
      .any()
      .refine((input) => input, "Selecione onde ocorre a reclamação.")
      .transform((input) => input.value),
  });

  return (
    <Page title="Reclamação sobre qualidade de água">
      <div className="flex flex-col space-y-4">
        <Accordion title="Observações" open={open}>
          <DivReclamacaoAgua />
        </Accordion>

        <ReactForm
          schema={formSchema}
          onSubmit={(e) => {
            nextPage(e);
            // setOpen(false);
          }}
          onError={(error, e) => {
            // setOpen(false);
          }}
        >
          <SelectField
            name="origemOcorrencia"
            placeHolder="Informe onde ocorre a reclamação"
            items={TIPOS_ORIGEM_OCORRENCIA}
          />

          <div className="pt-4">
            <SubmitButton label="Avançar" />
          </div>
        </ReactForm>
      </div>
    </Page>
  );
}
