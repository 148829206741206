import { IonIcon, IonItem, IonLabel } from "@ionic/react";
import { openURL } from "../utils/functions";
import Page from "../components/Page";

interface ItemDocumento {
  title: string;
  url: string;
}

export default function Documentos() {
  const documentos: ItemDocumento[] = [
    {
      title: "Requerimento para declaração de manancial",
      url: "https://www.saneago.com.br/agencia-virtual/arquivos/Req-dec-manancial.pdf",
    },
    {
      title:
        "Requerimento de declaração de manancial de superfície - orientações",
      url: "https://www.saneago.com.br/agencia-virtual/arquivos/Form-dec-manancial-superficie.pdf",
    },
    {
      title: "Formulário de laudo de avaliação de poço tubular profundo",
      url: "https://www.saneago.com.br/agencia-virtual/arquivos/Form_Laudo_Aval_Poco.pdf",
    },
    {
      title:
        "Formulário de solicitação de apoio de água para eventos/atividades",
      url: "https://www.saneago.com.br/agencia-virtual/arquivos/Form_Solicitacao_Apoio_Agua.pdf",
    },
  ];

  return (
    <Page title="Documentos auxiliares" showCardConta={false}>
      <div className="flex flex-col space-y-4">
        <div className="card bg-opacity-70">
          <div className="grid grid-cols-1 gap-4">
            {documentos.map((item, index) => {
              return (
                <IonItem
                  routerDirection="none"
                  lines="none"
                  detail={false}
                  key={index}
                  color="transparent"
                  onClick={() => openURL(item.url)}
                  className="hover:bg-primary hover:bg-opacity-10"
                >
                  <div className="flex flex-row items-center cursor-pointer">
                    <div className="flex-none w-8">
                      <IonIcon
                        src="assets/icon/download.svg"
                        className="text-2xl"
                      />
                    </div>
                    <div className="grow">
                      <IonLabel className="ion-text-wrap">
                        <div className="text-slate-700 px-2">{item.title}</div>
                      </IonLabel>
                    </div>
                  </div>
                </IonItem>
              );
            })}
          </div>
        </div>
      </div>
    </Page>
  );
}
