import React, { useEffect, useState } from "react";
import { Debito, Mensagens, SegundaVia } from "../models/types";
import { formatarData, formatarDinheiro } from "../utils/functions";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { classNames } from "primereact/utils";
import Button from "./Button";

export interface CardFaturasProps {
  data: SegundaVia | null;
  hidden?: boolean;
  onSelect?(debitos: Debito[] | null): void;
  onGerarFatura?(debitos: Debito[] | null): void;
}

const CardFaturasEmAberto: React.FC<CardFaturasProps> = (
  props: CardFaturasProps
) => {
  const [selectedDebitos, setSelectedDebitos] = useState<Debito[] | null>(null);
  const [data, setData] = useState<SegundaVia | null>(null);

  useEffect(() => {
    setSelectedDebitos(null);
    setData(props.data);
  }, [props.data]);

  useEffect(() => {
    if (props.onSelect) {
      props.onSelect(selectedDebitos);
    }
  }, [selectedDebitos]);

  const desabilitar =
    !data || !data.listaResultados || data.listaResultados.length === 0;

  const selecionados = [
    {
      descricao: "Total de débitos selecionados",
      destacar: true,
      valor: formatarDinheiro(
        selectedDebitos?.reduce<number>((accumulator, current) => {
          return accumulator + current.valorDebito;
        }, 0) as number
      ),
    },
    {
      descricao: "Total de faturas selecionadas",
      destacar: false,
      valor: selectedDebitos?.length || 0,
    },
    {
      descricao: "Total de faturas em aberto",
      destacar: false,
      valor: data?.listaResultados.length || 0,
    },
  ];

  const isSelectable = (data: Debito) => data?.podeGerarSegundaVia;

  const isRowSelectable = (event: { data: Debito }) => isSelectable(event.data);

  const rowClassName = (data: Debito) =>
    isSelectable(data) ? "" : "p-disabled";

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footerStyle={{ textAlign: "right" }}
          footer="Total do débito:"
          colSpan={3}
        />
        <Column
          footerStyle={{ textAlign: "right" }}
          footer={formatarDinheiro(
            data?.listaResultados.reduce<number>((accumulator, current) => {
              return accumulator + (current.podeGerarSegundaVia ? current.valorDebito : 0);
            }, 0) as number
          )}
        />
      </Row>
    </ColumnGroup>
  );

  return (
    <div className="flex flex-col space-y-4" hidden={props.hidden || !data}>
      <div className="card">
        <div className="font-medium text-red-600">Atenção</div>
        <div className="mt-2 text-slate-700" hidden={!data || !data.mensagem}>
          {data?.mensagem}
        </div>
        <div
          className="mt-4 text-slate-700"
          hidden={
            !!(data && data.mensagem) ||
            !!(data && data.listaResultados && data.listaResultados.length > 0)
          }
        >
          0 débito(s) encontrado(s) nesta conta para emissão de segunda via.
        </div>
      </div>

      <div className="card-borda" hidden={desabilitar}>
        <div className="card-title">Faturas em aberto</div>

        <DataTable
          value={data?.listaResultados}
          emptyMessage={Mensagens.NENHUM_RESULTADO_ENCONTRADO}
          responsiveLayout="scroll"
          size="small"
          stripedRows
          selection={selectedDebitos}
          onSelectionChange={(e) => setSelectedDebitos(e.value)}
          footerColumnGroup={footerGroup}
          isDataSelectable={isRowSelectable}
          rowClassName={rowClassName}
        >
          <Column selectionMode="multiple"></Column>
          <Column
            field="referenciaFormatada"
            header="Referência"
            alignHeader={"right"}
            align={"right"}
            style={{ fontSize: "0.9rem" }}
          ></Column>
          <Column
            field="dataVencimento"
            header="Vencimento"
            alignHeader={"right"}
            align={"right"}
            style={{ fontSize: "0.9rem" }}
            body={dataVencimentoBodyTemplate}
          ></Column>
          <Column
            field="valorDebito"
            header="Valor"
            alignHeader={"right"}
            align={"right"}
            style={{ fontSize: "0.9rem" }}
            body={valorBodyTemplate}
          ></Column>
        </DataTable>
      </div>

      <div className="card-borda" hidden={desabilitar}>
        <div className="card-title">Faturas selecionadas</div>

        <DataTable
          value={selecionados}
          emptyMessage={Mensagens.NENHUM_RESULTADO_ENCONTRADO}
          responsiveLayout="scroll"
          size="small"
        >
          <Column field="descricao" headerStyle={{ padding: 0 }}></Column>
          <Column
            field="valor"
            headerStyle={{ padding: 0 }}
            body={styleBodyTemplate}
          ></Column>
        </DataTable>
      </div>

      <div
        className="px-2 text-center"
        hidden={desabilitar || !props.onGerarFatura}
      >
        <Button
          label="Gerar fatura"
          disabled={!(selectedDebitos && selectedDebitos.length > 0)}
          onClick={() => {
            if (props.onGerarFatura) {
              props.onGerarFatura(selectedDebitos);
            }
          }}
        />
      </div>
    </div>
  );
};

const styleBodyTemplate = (rowData: { destacar: boolean; valor: string }) => {
  const stockClassName = classNames({
    "text-red-600 text-right": rowData.destacar,
    "text-right": !rowData.destacar,
  });

  return <div className={stockClassName}>{rowData.valor}</div>;
};

const valorBodyTemplate = (rowData: { valorDebito: number | bigint }) => {
  return formatarDinheiro(rowData.valorDebito);
};

const dataVencimentoBodyTemplate = (rowData: { dataVencimento: unknown }) => {
  return formatarData(rowData.dataVencimento);
};

export default CardFaturasEmAberto;
