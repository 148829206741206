import {
  ApiRoutes,
  CODIGO_UNIDADE_ATENDIMENTO,
  Conta,
  MATRICULA_SOLICITANTE,
  Mensagens,
  TIPOS_DENUNCIA,
  TIPOS_IRREGULARIDADE_AGUA,
  TIPOS_IRREGULARIDADE_ESGOTO,
} from "../../models/types";
import { getOnlyNumbers } from "../../utils/functions";
import { useContaUsuario } from "../../contexts/contaUsuarioContext";
import { useEffect, useState } from "react";
import { z } from "zod";
import { useAxiosClient } from "../../hooks/useAxiosClient";
import { useAuth } from "../../contexts/authContext";
import { useIonLoading } from "@ionic/react";
import { useProtocolo } from "../../contexts/protocoloContext";
import Page from "../../components/Page";
import Accordion from "../../components/Accordion";
import useNotification from "../../hooks/useNotification";
import ReactForm from "../../components/form/ReactForm";
import InputText from "../../components/form/InputText";
import SubmitButton from "../../components/form/SubmitButton";
import InputComplete from "../../components/form/InputComplete";
import DivDenunciaIrregularidade from "../../components/DivDenunciaIrregularidade";
import ButtonBack from "../../components/ButtonBack";

export default function DenunciaIrregularidadeNaConta() {
  const { usuario } = useAuth();
  const { getProtocolo } = useProtocolo();
  const contaUsuarioContext = useContaUsuario();
  const notification = useNotification();
  const axiosClient = useAxiosClient(ApiRoutes.ECO_ATENDIMENTO);

  const [present, dismiss] = useIonLoading();
  const [tipoDenuncia, setTipoDenuncia] = useState<string | null>("agua");

  const [dadosConta, setDadosConta] = useState<Conta | null>({
    nomeCidade: "",
    nomeBairro: "",
    nomeLogradouro: "",
    quadraImovel: "",
    loteImovel: "",
    numeroImovel: "",
  });

  useEffect(() => {
    if (contaUsuarioContext.contaSelecionada) {
      setDadosConta(contaUsuarioContext.contaSelecionada);
    }
  }, [contaUsuarioContext.contaSelecionada]);

  const formSchema = z.object({
    pontoReferencia: z.string().min(1, "Informe um ponto de referência."),
    celular: z
      .string()
      .min(11, "Informe um número de contato.")
      .transform((val) => Number(getOnlyNumbers(val))),
    tipoDenuncia: z.string().min(1, "Selecione o tipo de denúncia."),
    tipoIrregularidade: z
      .string()
      .min(1, "Selecione o tipo de irregularidade."),
  });

  type formType = z.infer<typeof formSchema>;

  async function enviarDados(dados: formType, resetFields: any) {
    await present({ message: Mensagens.SALVANDO });

    const dddTelefone = String(dados.celular).substring(0, 2);
    const numeroTelefone = String(dados.celular).substring(2, 11);

    const protocolo = await getProtocolo({
      numeroConta: dadosConta?.numeroConta || null,
      dddTelefoneContato: Number(dddTelefone),
      numeroTelefoneContato: Number(numeroTelefone),
    });

    if (!protocolo || !protocolo.numeroProtocolo) {
      dismiss();
      return;
    }

    try {
      let body = {
        matriculaSolicitante: MATRICULA_SOLICITANTE,
        codigoUnidadeAtendimento: CODIGO_UNIDADE_ATENDIMENTO,
        cpfCnpj: usuario?.cpfCnpj,
        nomeContato: usuario?.nome,
        tipoCliente: usuario?.tipoCliente,
        numeroConta: dadosConta?.numeroContaComDigito,
        pontoReferencia: dados.pontoReferencia,
        dddTelefoneContato: dddTelefone,
        numeroTelefoneContato: numeroTelefone,
        isDenunciaAnonima: false,
      };

      let url = `/atendimentos/${protocolo.numeroProtocolo}/registrar/`;

      if (isTipoDenunciaAgua()) {
        url = url.concat("irregularidade-agua");

        body = {
          ...body,
          ...{ descricaoTipoIrregularidadeAgua: dados.tipoIrregularidade },
        };
      } else {
        url = url.concat("irregularidade-esgoto");

        body = {
          ...body,
          ...{ descricaoTipoIrregularidadeEsgoto: dados.tipoIrregularidade },
        };
      }

      await axiosClient.post(url, JSON.stringify(body), {
        headers: {
          "content-type": "application/json",
        },
      });

      notification.showProtocolo(protocolo.numeroProtocolo).then(() => {
        resetFields();
      });

      dismiss();
    } catch (e: any) {
      dismiss();

      let msg = Mensagens.ERRO_INESPERADO;

      if (e.response.status === 403) {
        msg = Mensagens.SOLICITACAO_ABERTA;
      }

      await notification.showError({ message: msg, exception: e });
    }
  }

  function isTipoDenunciaAgua() {
    return tipoDenuncia === "agua";
  }

  return (
    <Page title="Denuncia de irregularidade">
      <div className="flex flex-col space-y-4">
        <Accordion title="Observações">
          <DivDenunciaIrregularidade autoDenuncia />
        </Accordion>

        <div className="card bg-opacity-70">
          <div className="card-title">Dados da ocorrência</div>

          <div className="items-center">
            <ReactForm
              schema={formSchema}
              onError={(error, e) => {}}
              onSubmit={(data, resetFields) =>
                enviarDados(data as formType, resetFields)
              }
            >
              <div className="flex flex-col space-y-4">
                <InputText
                  value={dadosConta?.nomeCidade}
                  name="nomeCidade"
                  label="Cidade"
                  disabled
                />
                <InputText
                  value={dadosConta?.nomeBairro}
                  name="nomeBairro"
                  label="Bairro"
                  disabled
                />
                <InputText
                  value={dadosConta?.nomeLogradouro}
                  name="nomeLogradouro"
                  label="Logradouro"
                  disabled
                />

                <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3 gap-4">
                  <InputText
                    value={dadosConta?.quadraImovel}
                    name="quadraImovel"
                    label="Quadra"
                    disabled
                  />
                  <InputText
                    value={dadosConta?.loteImovel}
                    name="loteImovel"
                    label="Lote"
                    disabled
                  />
                  <InputText
                    value={dadosConta?.numeroImovel}
                    name="numeroImovel"
                    label="Número"
                    disabled
                  />
                </div>

                <InputText
                  name="pontoReferencia"
                  label="Ponto de referência"
                  inputProps={{ maxLength: 50 }}
                />

                <InputText
                  name="celular"
                  label="Celular"
                  mask="99 99999-9999"
                />

                <InputComplete
                  name="tipoDenuncia"
                  placeHolder="Tipo de denúncia"
                  items={TIPOS_DENUNCIA}
                  defaultValuePosition={0}
                  disableClearable={true}
                  onSelect={(item) => {
                    setTipoDenuncia(item?.value as string);
                  }}
                />

                <InputComplete
                  name="tipoIrregularidade"
                  placeHolder="Tipo de irregularidade"
                  showDivDetalhes
                  titleDivDetalhes="Descrição da irregularidade selecionada"
                  items={
                    isTipoDenunciaAgua()
                      ? TIPOS_IRREGULARIDADE_AGUA
                      : TIPOS_IRREGULARIDADE_ESGOTO
                  }
                />

                <div className="grid grid-cols-2 gap-2 xs:grid-cols-1">
                  <SubmitButton label="Enviar" />
                  <ButtonBack label="Voltar" />
                </div>
              </div>
            </ReactForm>
          </div>
        </div>
      </div>
    </Page>
  );
}
