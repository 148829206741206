import axios, { AxiosError, AxiosResponse } from "axios";
import { useAuth } from "../contexts/authContext";
import { ApiRoutes, Auth } from "../models/types";
import { getBaseURL } from "../utils/functions";

export function useAxiosClient(apiRoute: ApiRoutes) {
  const authContext = useAuth();

  const dataAtual = Date.now();
  const timestampInicioSessao = authContext.auth?.timestampInicioSessao;
  const timestampFimSessao = authContext.auth?.timestampFimSessao;
  const referesh_in = 300000; // 5 minutos

  const api = axios.create({
    baseURL: getBaseURL(apiRoute),
    headers: authContext.headers!,
  });

  api.interceptors.request.use(
    async function (request) {
      if (
        timestampInicioSessao &&
        dataAtual >= timestampInicioSessao! + referesh_in
      ) {
        const data = await authContext.refreshToken();

        if (data) {
          // atualiza o header da requisição com o token atualizado
          request.headers = authContext.getHeadersRequisicao(
            (data as Auth).access_token
          );
        }
      }

      return request;
    },
    function (error: AxiosError) {
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    function (response: AxiosResponse) {
      return response;
    },

    function (error: AxiosError) {
      if (error && error.response && error.response.status === 401) {
        authContext.refreshToken();
      }

      return Promise.reject(error);
    }
  );

  return api;
}
