import { IonIcon } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { classNames } from "primereact/utils";
import { FilePicker } from "@capawesome/capacitor-file-picker";
import { toBase64 } from "../../utils/functions";
import useNotification from "../../hooks/useNotification";

export interface CardAnexarArquivoProps {
  name: string;
  types: string[];
  maxSize?: number; // megabyte
  title?: string;
  hidden?: boolean;
  // multiple?: boolean;
}

export default function CardAnexarArquivo(props: CardAnexarArquivoProps) {
  const { title, types, maxSize, name, hidden } = props;
  const [arquivo, setArquivo] = useState<any>();

  const inputData = useRef<any>();
  const notification = useNotification();

  const {
    register,
    resetField,
    formState: { isSubmitting, errors },
  } = useFormContext();

  const { ref, ...rest } = register(name);

  useEffect(() => {
    if (!inputData.current?.value) {
      inputData.current.value = null;
      inputData.current.focus();

      setArquivo("");
    }
  }, [inputData.current?.value]);

  useEffect(() => {
    if (hidden) {
      inputData.current.value = null;
      inputData.current.focus();
      resetField(name, { defaultValue: "" });

      setArquivo("");
    }
  }, [hidden]);

  const pickFiles = async () => {
    try {
      const result = await FilePicker.pickFiles({
        types: types,
        // multiple: multiple || false, // caso um dia implemente upload multiplo
      });

      // caso um dia implemente upload multiplo sera necessario rescrever essa parte do codigo
      // para validar o tamanho de todos os arquivos da lista
      let _file = result?.files[0] || null;

      if (maxSize && _file?.size > maxSize * 1000000) {
        notification.showAlert({
          message: `O arquivo anexado não pode ter mais do que ${maxSize}MB.`,
        });

        return;
      }

      let _blob = _file?.blob ? await toBase64(_file?.blob) : null;

      inputData.current.value = _blob || result?.files[0];
      inputData.current.focus();

      setArquivo(result?.files[0]);
    } catch (e) {
      // ignorando o erro de quando o usuario fecha a janela sem anexar nada
    }
  };

  let returnRender = <></>;

  if (arquivo) {
    returnRender = (
      <div className="flex flex-col gap-4">
        <div className="self-center text-center">{arquivo?.name}</div>
        <div className="self-center cursor-pointer bg-primary hover:bg-opacity-30 bg-opacity-20 px-4 py-2 text-center rounded-lg max-w-sm text-slate-700 text-md font-medium">
          <div className="flex flex-row gap-2">
            <IonIcon
              className="text-xl self-center"
              icon="assets/icon/document-attach-sharp.svg"
            />
            <div onClick={() => pickFiles()}>Alterar arquivo anexado</div>
          </div>
        </div>
      </div>
    );
  } else {
    returnRender = (
      <div className="flex flex-col">
        <div
          onClick={() => pickFiles()}
          style={{ height: 150, maxWidth: 200 }}
          className={classNames(
            "w-full self-center  cursor-pointer bg-primary py-10 hover:bg-opacity-10 bg-opacity-5 flex flex-col justify-center rounded-lg",
            { "bg-red-500": !!errors[name] }
          )}
        >
          <IonIcon
            className="text-2xl self-center"
            icon="assets/icon/document-attach-sharp.svg"
          />
          <div className="text-center">{title}</div>
        </div>
        <div className="text-red-600 text-sm text-center mt-1 self-center">
          {errors[name]?.message?.toString()}
        </div>
      </div>
    );
  }

  return (
    <div hidden={hidden}>
      <div>{returnRender}</div>

      <div style={{ height: "0px" }}>
        {/* esse input ficara invisivel (width: 0) e sera utilizado para armazenar o value */}
        <input
          style={{ width: "0px" }}
          type="text"
          {...rest}
          name={name}
          ref={(e) => {
            ref(e);
            inputData.current = e;
          }}
        ></input>
      </div>
    </div>
  );
}
