import {
  ProtocoloAcao,
  ApiRoutes,
  Mensagens,
  ProtocoloItem,
} from "../../models/types";
import { useEffect, useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useHistory } from "react-router";
import { useAxiosClient } from "../../hooks/useAxiosClient";
import { formatarData, formatarDataNoPadrao } from "../../utils/functions";
import { useIonLoading } from "@ionic/react";
import useNotification from "../../hooks/useNotification";
import Page from "../../components/Page";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function ProtocoloDetalhe() {
  const { isMobileScreen } = useWindowDimensions();
  const [present, dismiss] = useIonLoading();
  const [data, setData] = useState<ProtocoloItem | null>(null);
  const [numeroProtocolo, setNumeroProtocolo] = useState<number | null>(null);

  const axiosClient = useAxiosClient(ApiRoutes.ECO_ATENDIMENTO);
  const notification = useNotification();
  const navigate = useHistory<any>();

  useEffect(() => {
    //dados vindos da navegação
    setNumeroProtocolo(navigate.location.state?.numeroProtocolo);
  }, [navigate.location.state?.numeroProtocolo]);

  useEffect(() => {
    if (numeroProtocolo) {
      consultar();
    }
  }, [numeroProtocolo]);

  async function consultar() {
    await present({ message: Mensagens.CONSULTANDO });

    try {
      const url = `/atendimentos/protocolos/${numeroProtocolo}`;
      const response = await axiosClient.get(url);

      setData(response.data);

      dismiss();
    } catch (e: any) {
      dismiss();

      await notification.showError({
        message: "Protocolo não encontrado.",
        exception: e,
      });
    }
  }

  return (
    <Page title="Protocolo" showCardConta={false} buttonType="back">
      <div className="flex flex-col space-y-4">
        <div className="card bg-opacity-70">
          <div className="card-title">Detalhes</div>

          <div className="grid grid-cols-1 gap-2">
            <div className="grid grid-cols-2 xs:grid-cols-1 px-4 py-2 bg-secondary bg-opacity-5 rounded-2xl">
              <div className="font-medium text-secondary">Número protocolo</div>
              <div className="">
                {data?.numeroProtocolo || "Protocolo não encontrado"}
              </div>
            </div>

            <div className="grid grid-cols-2 xs:grid-cols-1 px-4 py-2 bg-secondary bg-opacity-5 rounded-2xl">
              <div className="font-medium text-secondary">Data da abertura</div>

              <div className="">
                {formatarDataNoPadrao(
                  data?.dataHoraAbertura,
                  "dd/MM/yyyy HH:mm"
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 xs:grid-cols-1 px-4 py-2 bg-secondary bg-opacity-5 rounded-2xl">
              <div className="font-medium text-secondary">
                Motivo do contato
              </div>
              <div className="">{data?.motivoContato || "Não informado"}</div>
            </div>
          </div>
        </div>

        <div className="card-borda">
          <div className="card-title">Dados do(s) RA(s)</div>

          <DataTable
            value={data?.acoesRealizadas}
            emptyMessage="Não existe RA aberta para este protocolo"
            responsiveLayout={"scroll"}
            size="small"
            stripedRows
          >
            {/* Na versao mobile so renderiza essa coluna */}
            <Column
              headerStyle={{ padding: 0 }}
              body={styleBodyTemplate}
              hidden={!isMobileScreen}
            ></Column>

            {/* Na versao web renderiza as outras colunas */}
            <Column
              field="numeroRegistroAtendimento"
              header="Número do RA"
              className="text-sm"
              hidden={isMobileScreen}
            />

            <Column
              field="servicoPrestado"
              header="Serviço"
              headerClassName="text-sm"
              bodyClassName="text-xs"
              hidden={isMobileScreen}
            />

            <Column
              field="dataHoraExecucao"
              header="Data da execução"
              className="text-sm"
              body={dataExecucaoBodyTemplate}
              hidden={isMobileScreen}
            />

            <Column
              field="situacaoReprogramacao"
              header="Situação do atendimento"
              className="text-sm"
              hidden={isMobileScreen}
            />
          </DataTable>
        </div>
      </div>
    </Page>
  );
}

const styleBodyTemplate = (rowData: ProtocoloAcao) => {
  return (
    <div className="text-sm">
      <div className="flex flex-col gap-2">
        <div>
          <div className="font-medium">Número do RA</div>
          <div className="">{rowData.numeroRegistroAtendimento}</div>
        </div>

        <div>
          <div className="font-medium">Serviço</div>
          <div className="">{rowData.servicoPrestado}</div>
        </div>

        <div>
          <div className="font-medium">Data da execução</div>
          <div className="">
            {rowData.dataHoraExecucao
              ? formatarData(rowData.dataHoraExecucao)
              : "---"}
          </div>
        </div>

        <div>
          <div className="font-medium">Situação do atendimento </div>
          <div className="">{rowData.situacaoReprogramacao}</div>
        </div>
      </div>
    </div>
  );
};

const dataExecucaoBodyTemplate = (rowData: { dataHoraExecucao: unknown }) => {
  return rowData.dataHoraExecucao
    ? formatarData(rowData.dataHoraExecucao)
    : "---";
};
