import { isPlatform } from "@ionic/react";
import { Camera, CameraResultType } from "@capacitor/camera";
import { Filesystem } from "@capacitor/filesystem";
import { toBase64 } from "../utils/functions";
import { fromURL } from "image-resize-compress";

export interface PhotoOption {
  maxHeight?: number;
  maxWidth?: number;
  quality?: number;
}

export function usePhotoGallery() {
  const takePhoto = async ({
    maxHeight,
    maxWidth,
    quality,
  }: PhotoOption): Promise<string> => {
    //
    // ate o momento unica forma encontrada para traduzir os textos
    setTimeout(() => {
      let cameraElement = document
        .querySelector("pwa-camera-modal-instance")
        ?.shadowRoot?.querySelector("pwa-camera");

      if (cameraElement) {
        cameraElement.noDevicesText = "Câmera não encontrada";
        cameraElement.noDevicesButtonText = "Escolha uma imagem";
      }
    }, 200);

    const photo = await Camera.getPhoto({
      allowEditing: false,
      height: maxHeight,
      width: maxWidth,
      quality: quality,
      resultType: CameraResultType.Uri,
      promptLabelHeader: "Foto Frente",
      promptLabelPhoto: "Foto da Galeria",
      promptLabelPicture: "Foto da Câmera",
      promptLabelCancel: "Cancelar",
    });

    if (isPlatform("hybrid")) {
      // se estiver em ios ou android apenas obtem a imagem
      // que deve ja vir com tamanho e qualidade minimizados

      const file = await Filesystem.readFile({
        path: photo.path!,
      });

      return file.data;
    }

    const blobretorno = await fromURL(
      photo.webPath!,
      quality,
      "auto",
      maxHeight,
      "image/jpeg"
    );

    const retorno = await toBase64(blobretorno);
    return retorno;
  };

  return {
    takePhoto,
  };
}
