import {
  completarCpfCnpj,
  downloadPDF,
  formatarCpfCnpj,
  getBaseURL,
  isApenasNumeros,
  isCnpjValido,
  isCpfValido,
} from "../../utils/functions";
import {
  ApiRoutes,
  Debito,
  Mensagens,
  SegundaVia,
  TIPOS_PESSOA,
  TipoPessoa,
} from "../../models/types";
import { useIonLoading } from "@ionic/react";
import { z } from "zod";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useCallback, useEffect, useState } from "react";
import InputText from "../../components/form/InputText";
import ReactForm from "../../components/form/ReactForm";
import SubmitButton from "../../components/form/SubmitButton";
import Page from "../../components/Page";
import SelectField from "../../components/form/SelectField";
import axios from "axios";
import useNotification from "../../hooks/useNotification";
import CardFaturasEmAberto from "../../components/CardFaturasEmAberto";
import ButtonBack from "../../components/ButtonBack";

export interface Cliente {
  tipoCliente?: string;
  cpfCnpj?: string;
  numeroContaComDigito: string;
}

export default function SegundaViaSimplificada() {
  const notification = useNotification();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [present, dismiss] = useIonLoading();
  const [tipoCliente, setTipoCliente] = useState<string>();
  const [data, setData] = useState<SegundaVia | null>(null);
  const [cliente, setCliente] = useState<Cliente | null>();
  const [isFluxoConsulta, setIsFluxoConsulta] = useState<boolean>(true);
  const [numeroConta, setNumeroConta] = useState("");
  const [digitoConta, setDigitoConta] = useState("");

  const [clienteComTitularidade, setClienteComTitularidade] =
    useState<boolean>(false);

  useEffect(() => {
    setClienteComTitularidade(false);
    setCliente(null);
  }, [numeroConta, digitoConta]);

  const isTipoClientePessoaFisica = (() => {
    return tipoCliente === TipoPessoa.PESSOA_FISICA;
  })();

  const formSchema = z
    .object({
      numeroConta: z.string().min(1, "Informe o número da conta."),
      digitoConta: z.string().min(1, "Informe o dígito da conta."),
      tipoCliente: z.any().transform((input) => input?.value),
      cpfCnpj: z.string(),
    })
    .refine((data) => !(clienteComTitularidade && !data.tipoCliente), {
      message: "Selecione uma opção de cliente.",
      path: ["tipoCliente"],
    })
    .refine((data) => !(clienteComTitularidade && !data.cpfCnpj), {
      message: isTipoClientePessoaFisica
        ? "Informe um CPF."
        : "Informe um CNPJ.",
      path: ["cpfCnpj"],
    })
    .refine(
      (data) =>
        !(
          clienteComTitularidade &&
          !(isTipoClientePessoaFisica
            ? isCpfValido(data.cpfCnpj)
            : isCnpjValido(data.cpfCnpj))
        ),
      {
        message: isTipoClientePessoaFisica ? "CPF inválido." : "CNPJ inválido.",
        path: ["cpfCnpj"],
      }
    );

  type formType = z.infer<typeof formSchema>;

  async function enviarDados(dados: formType, resetFields: any) {
    await present({ message: Mensagens.CONSULTANDO });

    let dadosCliente = {
      numeroContaComDigito: dados.numeroConta + "-" + dados.digitoConta,
    };

    if (clienteComTitularidade) {
      dadosCliente = {
        ...dadosCliente,
        ...{
          cpfCnpj: completarCpfCnpj(dados.cpfCnpj),
          tipoCliente: dados.tipoCliente,
        },
      };
    }

    setCliente(dadosCliente);

    try {
      const url =
        getBaseURL(ApiRoutes.ECO_FATURAMENTO_EXTERNO) +
        "/faturas/pendentes/segunda-via";

      const token = await getReCaptchaToken();

      const params = new URLSearchParams({
        ...dadosCliente,
        ...{ "g-recaptcha-response": token as string },
      });

      const res = await axios.get(url, { params: params });

      setData(res?.data);
      setIsFluxoConsulta(false);

      dismiss();
    } catch (e: any) {
      dismiss();

      if (e?.response?.status === 422) {
        setClienteComTitularidade(true);
        notification.showAlert({
          message:
            "Para contas com titularidade é preciso informar o CPF/CNPJ.",
        });

        return;
      }

      await notification.showError({ exception: e });
    }
  }

  async function gerarFatura(debitos: Debito[] | null) {
    if (!debitos) {
      return;
    }

    await present({ message: Mensagens.CONSULTANDO });

    try {
      const documentos = debitos?.map((item) => {
        return item.numeroDocumentoFormatado;
      });

      const url =
        getBaseURL(ApiRoutes.ECO_FATURAMENTO_EXTERNO) +
        "/segunda-via/agrupada";

      const token = await getReCaptchaToken();

      const params = new URLSearchParams({
        "g-recaptcha-response": token as string,
      });

      const body = {
        ...cliente,
        ...{
          numeroConta: cliente?.numeroContaComDigito,
          documentos: documentos,
          exibirDadosFatura: false,
        },
      };

      delete body.numeroContaComDigito;

      const res = await axios.post(url, JSON.stringify(body), {
        params: params,
        headers: { "content-type": "application/json" },
        responseType: "blob",
      });

      notification.showSuccess({
        message: Mensagens.FATURA_GERADA,
      });

      downloadPDF("Segunda via Saneago", res.data);

      dismiss();
    } catch (e: any) {
      dismiss();

      await notification.showError({ exception: e });
    }
  }

  const getReCaptchaToken = useCallback(async () => {
    if (!executeRecaptcha) {
      notification.showWarning({ message: Mensagens.RECAPTCHA_INDISPONIVEL });
      return null;
    }

    const token = await executeRecaptcha();
    return token;
  }, [executeRecaptcha]);

  const handleChangeNumeroConta = (e: any) => {
    if (e.target.value === "" || isApenasNumeros(e.target.value)) {
      setNumeroConta(e.target.value);
    }
  };

  const handleChangeDigitoConta = (e: any) => {
    if (e.target.value === "" || isApenasNumeros(e.target.value)) {
      setDigitoConta(e.target.value);
    }
  };

  return (
    <Page title="Emissão de 2ª via da fatura" reCaptcha showCardConta={false}>
      <div className="flex flex-col space-y-4">
        <div className="card bg-opacity-70">
          <div className="card-title">Dados para consulta</div>
          <div className="items-center" hidden={!isFluxoConsulta}>
            <ReactForm
              schema={formSchema}
              onError={(error, e) => {}}
              onSubmit={(data, resetFields) =>
                enviarDados(data as formType, resetFields)
              }
            >
              <div className="flex flex-col space-y-4">
                <div className="grid grid-cols-2 gap-4 xs:grid-cols-1">
                  <InputText
                    name="numeroConta"
                    label="Número da conta"
                    onInput={(e) => handleChangeNumeroConta(e)}
                    value={numeroConta}
                    inputProps={{ maxLength: 8 }}
                  />

                  <InputText
                    name="digitoConta"
                    label="Dígito da conta"
                    onInput={(e) => handleChangeDigitoConta(e)}
                    value={digitoConta}
                    inputProps={{ maxLength: 1 }}
                  />
                </div>

                <div hidden={!clienteComTitularidade}>
                  <SelectField
                    name="tipoCliente"
                    placeHolder="Tipo de cliente"
                    defaultValuePosition={0}
                    onSelect={(item) => {
                      setTipoCliente(item?.value as string);
                    }}
                    items={TIPOS_PESSOA}
                  />
                </div>

                <div hidden={!clienteComTitularidade}>
                  <InputText
                    name="cpfCnpj"
                    label={isTipoClientePessoaFisica ? "CPF" : "CNPJ"}
                    mask={
                      isTipoClientePessoaFisica
                        ? "999.999.999-99"
                        : "99.999.999/9999-99"
                    }
                  />
                </div>

                <SubmitButton label="Consultar" />
              </div>
            </ReactForm>
          </div>

          <div className="grid grid-cols-2 gap-4" hidden={isFluxoConsulta}>
            <div>
              <div className="text-sm text-secondary font-medium">
                Número da conta
              </div>
              <div className="text-slate-700">
                {cliente?.numeroContaComDigito}
              </div>
            </div>

            <div hidden={!cliente?.cpfCnpj}>
              <div className="text-sm text-secondary font-medium">CPF/CNPJ</div>
              <div className="text-slate-700">
                {formatarCpfCnpj(cliente?.cpfCnpj)}
              </div>
            </div>
          </div>
        </div>

        <div hidden={isFluxoConsulta}>
          <ButtonBack onClickEvent={() => setIsFluxoConsulta(true)} />
        </div>

        <CardFaturasEmAberto
          data={data}
          hidden={isFluxoConsulta}
          onGerarFatura={(debitos) => {
            gerarFatura(debitos);
          }}
        />
      </div>
    </Page>
  );
}
