export interface Props {
  autoDenuncia?: boolean;
}

// Foi componentizado pois as informações se repetem em mais de uma pagina
export default function DivDenunciaIrregularidade(props: Props) {
  return (
    <div className="">
      <div className="text-sm">
        Os serviços públicos de água e esgoto são regidos pelo REGULAMENTO DE
        ÁGUA E ESGOTO. A inobservância de qualquer dispositivo sujeita à
        aplicação de penalidades, em conformidade com o Código de Defesa do
        Consumidor e Ministério Público.
      </div>

      <div className="mt-4 text-sm" hidden={!props.autoDenuncia}>
        <span className="font-bold">Auto denúncia: </span>
        Desconto de 50% na multa. Neste caso, é necessário que NÃO haja nenhum
        registro na SANEAGO anterior, referente à irregularidade denunciada.
      </div>

      <div className="mt-4 text-sm" hidden={props.autoDenuncia}>
        <span className="font-bold">Confidencialidade: </span>
        Seus dados serão mantidos em sigilo.
      </div>
    </div>
  );
}
