import { StatusFaturaDigital } from "../../models/types";
import { useContaUsuario } from "../../contexts/contaUsuarioContext";
import Page from "../../components/Page";
import CadastroFaturaDigital from "./CadastroFaturaDigital";
import AguardandoConfirmacao from "./AguardandoConfirmacao";
import AderidoFaturaDigital from "./AderidoFaturaDigital";

export default function FaturaDigital() {
  const { contaSelecionada } = useContaUsuario();

  return (
    <Page title="Fatura digital" necessarioSelecionarConta>
      <>
        {contaSelecionada?.situacaoAdesaoFaturaDigital ===
          StatusFaturaDigital.NAO_ADERIU && <CadastroFaturaDigital />}

        {contaSelecionada?.situacaoAdesaoFaturaDigital ===
          StatusFaturaDigital.AGUARDANDO_CONFIRMACAO_EMAIL && (
          <AguardandoConfirmacao />
        )}

        {contaSelecionada?.situacaoAdesaoFaturaDigital ===
          StatusFaturaDigital.ADERIU && <AderidoFaturaDigital />}
      </>
    </Page>
  );
}
