import { IonIcon, IonLabel } from "@ionic/react";
import { personCircle } from "ionicons/icons";
import { useHistory } from "react-router";
import { Mensagens, Usuario } from "../../models/types";
import { useAuth } from "../../contexts/authContext";
import Button from "../../components/Button";
import Page from "../../components/Page";

export default function Finalizar() {
  const { logout } = useAuth();
  const navigate = useHistory<any>();

  //dados vindos da navegação do cadastro inicial
  const usuario: Usuario = navigate.location?.state?.data;

  //Se por ventura não obteve os dados da navegação anterior então retorna erro
  if (!usuario) {
    return (
      <Page title="Cadastro" showCardConta={false}>
        <div className="flex flex-col justify-center items-center p-4">
          <IonLabel>{Mensagens.ERRO_INESPERADO}</IonLabel>
        </div>
      </Page>
    );
  }

  return (
    <Page showCardConta={false}>
      <div className="card">
        <div className="flex flex-col justify-center items-center text-center text-slate-700">
          <div className="self-center">
            <IonIcon
              color="primary"
              icon={personCircle}
              className="text-5xl"
            ></IonIcon>
          </div>
          <div className="font-bold text-xl">{usuario.nome}</div>
          <div className="text-sm">{usuario.email}</div>

          <div className="text-lg pt-4">
            Os dados de cadastro foram salvos com sucesso!
          </div>

          <div className="pt-4">
            <Button label="Acessar aplicativo" onClick={() => logout()} />
          </div>
        </div>
      </div>
    </Page>
  );
}
