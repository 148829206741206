import { useEffect, useState } from "react";
import { useAuth } from "../../contexts/authContext";
import Page from "../../components/Page";
import HomeLogado from "./HomeLogado";
import HomeNaoLogado from "./HomeNaoLogado";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function Home() {
  const authContext = useAuth();

  const { isMobileScreen } = useWindowDimensions();
  const [mostrarSplash, setMostrarSplash] = useState<boolean>(false);

  useEffect(() => {
    if (!authContext.auth) {
      authContext.recuperarSessao();
    }

    // apenas na primeira renderização mostra a imagem
    if (window.history.length <= 1 && isMobileScreen) {
      setMostrarSplash(true);

      setTimeout(() => {
        setMostrarSplash(false);
      }, 2800);
    }
  }, []);

  return (
    <div>
      <div hidden={!mostrarSplash} className="bg-primary">
        <div className="h-screen w-screen animate-fade">
          <div className="absolute -z-30 bg-fundo-splash w-screen h-screen bg-no-repeat bg-center bg-cover" />
        </div>
      </div>

      <div hidden={mostrarSplash}>
        <Page>{authContext.isLogged ? <HomeLogado /> : <HomeNaoLogado />}</Page>
      </div>
    </div>
  );
}
