import {
  ApiRoutes,
  CODIGO_UNIDADE_ATENDIMENTO,
  MATRICULA_SOLICITANTE,
  Mensagens,
  TIPOS_RECLAMACAO_QUALIDADE_AGUA,
} from "../../models/types";
import { getOnlyNumbers } from "../../utils/functions";
import { useEffect, useState } from "react";
import { z } from "zod";
import { useAxiosClient } from "../../hooks/useAxiosClient";
import { useAuth } from "../../contexts/authContext";
import { useHistory } from "react-router";
import { useIonLoading } from "@ionic/react";
import { useProtocolo } from "../../contexts/protocoloContext";
import Page from "../../components/Page";
import useNotification from "../../hooks/useNotification";
import ReactForm from "../../components/form/ReactForm";
import InputText from "../../components/form/InputText";
import SubmitButton from "../../components/form/SubmitButton";
import Accordion from "../../components/Accordion";
import DivReclamacaoAgua from "../../components/DivReclamacaoAgua";
import PesquisaCidade from "../../components/form/PesquisaCidade";
import PesquisaBairro from "../../components/form/PesquisaBairro";
import PesquisaLogradouro from "../../components/form/PesquisaLogradouro";
import InputComplete from "../../components/form/InputComplete";
import ButtonBack from "../../components/ButtonBack";

export default function ReclamacaoAguaNoEndereco() {
  const [present, dismiss] = useIonLoading();
  const [open, setOpen] = useState<boolean>(true);
  const [cidade, setCidade] = useState<number | null>();
  const [bairro, setBairro] = useState<number | null>();

  const { usuario } = useAuth();
  const { getProtocolo } = useProtocolo();
  const navigate = useHistory<any>();

  const notification = useNotification();
  const axiosClient = useAxiosClient(ApiRoutes.ECO_ATENDIMENTO);

  useEffect(() => {
    //dados vindos da navegação
    setOpen(!navigate.location.state?.close);
  }, [navigate.location.state?.close]);

  const formSchema = z.object({
    codigoCidade: z
      .string()
      .min(1, "Selecione uma cidade.")
      .transform((val) => Number(getOnlyNumbers(val))),
    codigoBairro: z
      .string()
      .min(1, "Selecione um bairro.")
      .transform((val) => Number(getOnlyNumbers(val))),
    codigoLogradouro: z
      .string()
      .min(1, "Selecione um logradouro.")
      .transform((val) => Number(getOnlyNumbers(val))),
    quadraImovel: z.string().min(1, "Informe a quadra."),
    loteImovel: z.string().min(1, "Informe o lote."),
    numeroImovel: z.string().min(1, "Informe o número."),
    pontoReferencia: z.string().min(1, "Informe um ponto de referência."),
    reclamacao: z.string().min(1, "Selecione a descrição da reclamação."),
    celular: z
      .string()
      .min(11, "Informe um número de contato.")
      .transform((val) => Number(getOnlyNumbers(val))),
  });

  type formType = z.infer<typeof formSchema>;

  async function enviarDados(dados: formType, resetFields: any) {
    await present({ message: Mensagens.SALVANDO });

    const dddTelefone = String(dados.celular).substring(0, 2);
    const numeroTelefone = String(dados.celular).substring(2, 11);

    const protocolo = await getProtocolo({
      numeroConta: null,
      dddTelefoneContato: Number(dddTelefone),
      numeroTelefoneContato: Number(numeroTelefone),
    });

    if (!protocolo || !protocolo.numeroProtocolo) {
      dismiss();
      return;
    }

    try {
      let body = {
        matriculaSolicitante: MATRICULA_SOLICITANTE,
        codigoUnidadeAtendimento: CODIGO_UNIDADE_ATENDIMENTO,
        nomeContato: usuario?.nome,
        tipoCliente: usuario?.tipoCliente,
        cpfCnpj: usuario?.cpfCnpj,

        codigoCidade: dados.codigoCidade,
        codigoBairro: dados.codigoBairro,
        codigoLogradouro: dados.codigoLogradouro,
        quadraImovel: dados.quadraImovel,
        loteImovel: dados.loteImovel,
        numeroImovel: dados.numeroImovel,
        pontoReferencia: dados.pontoReferencia,
        dddTelefoneContato: dddTelefone,
        numeroTelefoneContato: numeroTelefone,
        descricaoLocalProblema: dados.reclamacao,
      };

      await axiosClient.post(
        `/atendimentos/${protocolo.numeroProtocolo}/registrar/qualidade-agua`,
        JSON.stringify(body),
        {
          headers: {
            "content-type": "application/json",
          },
        }
      );

      notification.showProtocolo(protocolo.numeroProtocolo).then(() => {
        resetFields();
      });

      dismiss();
    } catch (e: any) {
      dismiss();

      let msg = Mensagens.ERRO_INESPERADO;

      if (e.response.status === 403) {
        msg = Mensagens.SOLICITACAO_ABERTA;
      }

      await notification.showError({ message: msg, exception: e });
    }
  }

  return (
    <Page
      title="Reclamação sobre qualidade de água"
      buttonType="back"
      showCardConta={false}
    >
      <div className="flex flex-col space-y-4">
        <Accordion title="Observações" open={open}>
          <DivReclamacaoAgua />
        </Accordion>

        <div className="card bg-opacity-70">
          <div className="card-title">Dados da ocorrência</div>

          <div className="items-center">
            <ReactForm
              schema={formSchema}
              onError={(error, e) => setOpen(false)}
              onSubmit={(data, resetFields) =>
                enviarDados(data as formType, resetFields)
              }
            >
              <div className="flex flex-col space-y-4">
                <PesquisaCidade
                  name="codigoCidade"
                  placeHolder="Cidade"
                  onSelect={(item) => {
                    setCidade(item?.value as number);
                  }}
                />

                <PesquisaBairro
                  name="codigoBairro"
                  placeHolder="Bairro"
                  codigoCidade={cidade}
                  onSelect={(item) => {
                    setBairro(item?.value as number);
                  }}
                />

                <PesquisaLogradouro
                  name="codigoLogradouro"
                  placeHolder="Logradouro"
                  codigoCidade={cidade}
                  codigoBairro={bairro}
                />

                <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3 gap-4">
                  <InputText
                    name="quadraImovel"
                    label="Quadra"
                    inputProps={{ maxLength: 5 }}
                  />
                  <InputText
                    name="loteImovel"
                    label="Lote"
                    inputProps={{ maxLength: 9 }}
                  />
                  <InputText
                    name="numeroImovel"
                    label="Número"
                    inputProps={{ maxLength: 5 }}
                  />
                </div>

                <InputText
                  name="pontoReferencia"
                  label="Ponto de referência"
                  inputProps={{ maxLength: 50 }}
                />

                <InputText
                  name="celular"
                  label="Celular"
                  mask="99 99999-9999"
                />

                <InputComplete
                  name="reclamacao"
                  placeHolder="Reclamação"
                  items={TIPOS_RECLAMACAO_QUALIDADE_AGUA}
                />

                <div className="grid grid-cols-2 gap-2 xs:grid-cols-1">
                  <SubmitButton label="Enviar" />
                  <ButtonBack label="Voltar" />
                </div>
              </div>
            </ReactForm>
          </div>
        </div>
      </div>
    </Page>
  );
}
