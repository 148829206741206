import { TIPOS_ORIGEM_OCORRENCIA } from "../../models/types";
import { useContaUsuario } from "../../contexts/contaUsuarioContext";
import { z } from "zod";
import { useHistory } from "react-router";
import { useState } from "react";
import Page from "../../components/Page";
import Accordion from "../../components/Accordion";
import useNotification from "../../hooks/useNotification";
import DivDenunciaIrregularidade from "../../components/DivDenunciaIrregularidade";
import ReactForm from "../../components/form/ReactForm";
import SubmitButton from "../../components/form/SubmitButton";
import SelectField from "../../components/form/SelectField";

export default function DenunciaIrregularidade() {
  const contaUsuarioContext = useContaUsuario();
  const navigate = useHistory();
  const notification = useNotification();

  const [origemOcorrencia, setOrigemOcorrencia] = useState<string | null>();

  function nextPage({ origemOcorrencia }: any) {
    if (origemOcorrencia === "conta") {
      //
      if (!contaUsuarioContext.contaSelecionada) {
        notification.showNecessarioSelecionarConta();
        return;
      }

      navigate.push("/denunciaNaConta");
    } else {
      navigate.push("/denunciaNoEndereco");
    }
  }

  const formSchema = z.object({
    origemOcorrencia: z
      .any()
      .refine((input) => input, "Selecione onde ocorre a denúncia.")
      .transform((input) => input.value),
  });

  return (
    <Page title="Denuncia de irregularidade">
      <div className="flex flex-col space-y-4">
        <Accordion title="Observações" open>
          <DivDenunciaIrregularidade
            autoDenuncia={origemOcorrencia !== "endereco"}
          />
        </Accordion>

        <ReactForm
          schema={formSchema}
          onSubmit={(e) => nextPage(e)}
          onError={(error, e) => {}}
        >
          <SelectField
            name="origemOcorrencia"
            placeHolder="Informe onde ocorre a denúncia"
            items={TIPOS_ORIGEM_OCORRENCIA}
            onSelect={(item) => {
              setOrigemOcorrencia(item?.value as string);
            }}
          />

          <div className="pt-4">
            <SubmitButton label="Avançar" />
          </div>
        </ReactForm>
      </div>
    </Page>
  );
}
