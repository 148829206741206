import { useIonLoading } from "@ionic/react";
import { useAuth } from "../contexts/authContext";
import { useContaUsuario } from "../contexts/contaUsuarioContext";
import { useAxiosClient } from "../hooks/useAxiosClient";
import { ApiRoutes, Mensagens } from "../models/types";
import { downloadPDF } from "../utils/functions";

import Button from "../components/Button";
import CardCliente from "../components/CardCliente";
import Page from "../components/Page";
import useNotification from "../hooks/useNotification";

export default function CertidaoNegativaDebitos() {
  const { contaSelecionada } = useContaUsuario();
  const { usuario } = useAuth();

  const [present, dismiss] = useIonLoading();
  const notification = useNotification();
  const axiosClient = useAxiosClient(ApiRoutes.ECO_ARRECADACAO);

  async function enviarDados() {
    let body = {
      numeroCpfCnpj: usuario?.cpfCnpj,
      tipoCliente: usuario?.tipoCliente,
      email: usuario?.email,
      numeroContaComDigito: contaSelecionada?.numeroContaComDigito,
    };

    await present({ message: Mensagens.ENVIANDO_EMAIL });

    try {
      const res = await axiosClient.post(
        "/debitos-conta/certidao-negativa",
        JSON.stringify(body),
        { responseType: "arraybuffer" }
      );

      downloadPDF("Certidão", res.data);

      notification.showSuccess({
        message:
          "A certidão negativa de débitos também foi enviada por e-mail.",
      });

      dismiss();
    } catch (e: any) {
      dismiss();

      if (e?.response?.data) {
        const data = JSON.parse(new TextDecoder().decode(e.response.data));

        if (data?.detalhe) {
          notification.showAlert({ message: data.detalhe });
          return;
        }
      }

      await notification.showError({ exception: e });
    }
  }

  return (
    <Page title="Certidão negativa de débitos" necessarioSelecionarConta>
      <div className="flex flex-col space-y-4" hidden={!contaSelecionada}>
        <CardCliente />

        <div className="card">
          <div className="font-medium text-red-600">Atenção</div>
          <div className="mt-2 text-justify text-slate-700">
            A certidão também será enviada para o e-mail (
            <span className="text-sm">{usuario?.emailOfuscado}</span>) informado
            no cadastro do aplicativo.
          </div>
        </div>

        <Button label="Emitir certidão" onClick={() => enviarDados()} />
      </div>
    </Page>
  );
}
