import { downloadPDF, formatarData } from "../../utils/functions";
import { ApiRoutes, LaudosAfericao, Mensagens } from "../../models/types";
import { IonIcon, useIonLoading } from "@ionic/react";
import { useState } from "react";
import useNotification from "../../hooks/useNotification";
import PageRequestConta from "../../components/PageRequest";
import CardCliente from "../../components/CardCliente";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useContaUsuario } from "../../contexts/contaUsuarioContext";
import { useAxiosClient } from "../../hooks/useAxiosClient";
import { useAuth } from "../../contexts/authContext";

export default function LaudoAfericao() {
  const axiosClient = useAxiosClient(ApiRoutes.ECO_ATENDIMENTO);
  const { usuario } = useAuth();
  const { contaSelecionada } = useContaUsuario();

  const notification = useNotification();
  const [present, dismiss] = useIonLoading();

  const [data, setData] = useState<LaudosAfericao | any>(null);

  async function consultar(
    numeroLaudoAfericao: number,
    anoLaudoAfericao: number
  ) {
    await present({ message: Mensagens.CONSULTANDO });

    try {
      let body = {
        cpfCnpj: usuario?.cpfCnpj,
        tipoCliente: usuario?.tipoCliente,
        numeroConta: contaSelecionada?.numeroConta,
        numeroLaudoAfericao: numeroLaudoAfericao,
        anoLaudoAfericao: anoLaudoAfericao,
      };

      const res = await axiosClient.post(
        "atendimentos/laudo-afericao/gerar-laudo",
        JSON.stringify(body),
        { responseType: "blob" }
      );

      dismiss();

      downloadPDF(`Laudo Aferição ${numeroLaudoAfericao}`, res.data);
    } catch (e: any) {
      dismiss();

      await notification.showError({ exception: e });
    }
  }

  const downloadBodyTemplate = (rowData: {
    numeroLaudoAfericao: any;
    anoLaudoAfericao: any;
  }) => {
    if (rowData.numeroLaudoAfericao) {
      return (
        <IonIcon
          src="assets/icon/download.svg"
          className="text-2xl"
          onClick={() => {
            consultar(rowData.numeroLaudoAfericao, rowData.anoLaudoAfericao);
          }}
        />
      );
    }

    return null;
  };

  return (
    <PageRequestConta<LaudosAfericao>
      title="Laudo de Aferição"
      api={ApiRoutes.ECO_ATENDIMENTO}
      endPoint="/atendimentos/laudo-afericao/listar-laudos"
      onContaChange={(data: LaudosAfericao) => setData(data)}
      necessarioSelecionarConta
      modoUsoConta="SEM_DIGITO"
    >
      <div className="flex flex-col space-y-4">
        <CardCliente />

        <div className="card-borda">
          <DataTable
            value={data?.listaResultados}
            emptyMessage={Mensagens.NENHUM_REGISTRO_CONTA}
            responsiveLayout="scroll"
            size="normal"
            stripedRows
          >
            <Column body={downloadBodyTemplate}></Column>
            <Column
              field="numeroLaudoAfericao"
              header="Número do Laudo"
              alignHeader={"center"}
              align={"center"}
              style={{ fontSize: "0.9rem" }}
            ></Column>
            <Column
              field="anoLaudoAfericao"
              header="Ano"
              alignHeader={"center"}
              align={"center"}
              style={{ fontSize: "0.9rem" }}
            ></Column>
            <Column
              field="dataSolicitacao"
              header="Data da Solicitação"
              alignHeader={"center"}
              align={"center"}
              style={{ fontSize: "0.9rem" }}
              body={dataSolicitacaoBodyTemplate}
            ></Column>
          </DataTable>
        </div>
      </div>
    </PageRequestConta>
  );
}

const dataSolicitacaoBodyTemplate = (rowData: { dataSolicitacao: unknown }) => {
  return formatarData(rowData.dataSolicitacao);
};
