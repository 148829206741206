import {
  ApiRoutes,
  MATRICULA_SOLICITANTE,
  Mensagens,
} from "../../models/types";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useAuth } from "../../contexts/authContext";
import { useAxiosClient } from "../../hooks/useAxiosClient";
import { useIonLoading } from "@ionic/react";
import { useProtocolo } from "../../contexts/protocoloContext";
import { useContaUsuario } from "../../contexts/contaUsuarioContext";
import InputText from "../../components/form/InputText";
import SubmitButton from "../../components/form/SubmitButton";
import ReactForm from "../../components/form/ReactForm";
import InputRadioGroupComponent from "../../components/form/InputRadioGroup";
import useNotification from "../../hooks/useNotification";
import Accordion from "../../components/Accordion";
import Page from "../../components/Page";
import ButtonBack from "../../components/ButtonBack";
import DivEmailIndividualizados from "../../components/DivEmailIndividualizados";

export default function CadastroEmailIndividualizados() {
  const contaUsuarioContext = useContaUsuario();
  const notification = useNotification();
  const axiosClient = useAxiosClient(ApiRoutes.ECO_ATENDIMENTO);

  const { getProtocolo } = useProtocolo();
  const { usuario } = useAuth();

  const [present, dismiss] = useIonLoading();
  const [usaEmailLogin, setUsaEmailLogin] = useState<boolean>(true);

  const formSchema = z
    .object({
      email: z.string().email({ message: "Informe um e-mail válido." }),
      confirmarEmail: z
        .string()
        .email({ message: "Confirmação de e-mail inválida." }),
    })
    .refine((data) => data.email === data.confirmarEmail, {
      message: "Os dois e-mails informados devem ser iguais.",
      path: ["confirmarEmail"],
    });

  const { register, reset } = useForm({
    resolver: zodResolver(formSchema),
  });

  type formType = z.infer<typeof formSchema>;

  async function enviarDados(dados: formType, resetFields: any) {
    await present({ message: Mensagens.SALVANDO });

    const protocolo = await getProtocolo({
      numeroConta: contaUsuarioContext?.contaSelecionada?.numeroConta || null,
      dddTelefoneContato: 0,
      numeroTelefoneContato: 0,
    });

    if (!protocolo || !protocolo.numeroProtocolo) {
      dismiss();
      return;
    }

    try {
      await axiosClient.post(
        "/atendimentos/resumo-faturamento-individualizado",
        JSON.stringify({
          email: dados.email,
          matriculaSolicitante: MATRICULA_SOLICITANTE,
          numeroConta:
            contaUsuarioContext?.contaSelecionada?.numeroContaComDigito,
          protocolo: protocolo.numeroProtocolo,
        }),
        {
          headers: {
            "content-type": "application/json",
          },
        }
      );

      notification.showProtocolo(protocolo.numeroProtocolo).then(() => {
        contaUsuarioContext.reconsultarDadosContaSelecionada();
        resetFields();
      });

      dismiss();
    } catch (e: any) {
      dismiss();

      await notification.showError({ exception: e });
    }
  }

  return (
    <Page title="Consumo e faturamento individualizados">
      <div className="flex flex-col space-y-4">
        <Accordion title="Observações" open>
          <DivEmailIndividualizados />
        </Accordion>

        <div className="card bg-opacity-70">
          <div className="card-title">Cadastro de e-mail automático</div>

          <div className="items-center">
            <ReactForm
              schema={formSchema}
              onError={(error, e) => {}}
              onSubmit={(data, resetFields) =>
                enviarDados(data as formType, resetFields)
              }
            >
              <div className="flex flex-col space-y-4">
                <InputRadioGroupComponent
                  name="preferenciaEmail"
                  label=""
                  items={[
                    {
                      value: "emailLogin",
                      label: "Utilizar e-mail do login",
                    },
                    {
                      value: "outroEmail",
                      label: "Utilizar outro e-mail",
                    },
                  ]}
                  onSelectItem={(value) =>
                    setUsaEmailLogin(value?.value === "emailLogin")
                  }
                  defaultValuePosition={0}
                />
                <InputText
                  name="email"
                  label="E-mail"
                  inputProps={{ maxLength: 60, ...register("email") }}
                  defaultValue={usaEmailLogin ? usuario?.email : ""}
                  readonly={usaEmailLogin}
                />
                <InputText
                  name="confirmarEmail"
                  label="Confirmar e-mail"
                  inputProps={{ maxLength: 60, ...register("confirmarEmail") }}
                  onPaste={(e) => e.preventDefault()}
                  defaultValue={usaEmailLogin ? usuario?.email : ""}
                  readonly={usaEmailLogin}
                />

                <div className="grid grid-cols-2 gap-2 xs:grid-cols-1">
                  <SubmitButton label="Cadastrar" />
                  <ButtonBack label="Voltar" />
                </div>
              </div>
            </ReactForm>
          </div>
        </div>
      </div>
    </Page>
  );
}
