import { ApiRoutes, Conta, Mensagens } from "../models/types";
import { createContext, useContext, useEffect, useState } from "react";
import { useAxiosClient } from "../hooks/useAxiosClient";
import { useAuth } from "./authContext";
import useNotification from "../hooks/useNotification";

export interface ContaUsuarioContextData {
  contaSelecionada: Conta | null;
  contasUsuario: Conta[] | null;
  alterarContaSelecionada(conta: Conta): void;
  reconsultarDadosContaSelecionada(): void;
}

const ContaUsuarioContext = createContext<ContaUsuarioContextData>(
  {} as ContaUsuarioContextData
);

export const ContaUsuarioProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const { isLogged, usuario } = useAuth();

  const [contasUsuario, setContasUsuario] = useState<Conta[] | null>(null);
  const [contaSelecionada, setContaSelecionada] = useState<Conta | null>(null);

  const useAxiosAtendimento = useAxiosClient(ApiRoutes.ECO_ATENDIMENTO);
  const useAxiosFaturamento = useAxiosClient(ApiRoutes.ECO_FATURAMENTO);
  const notification = useNotification();

  useEffect(() => {
    if (usuario?.cpfCnpj && usuario?.ativo && usuario?.clienteSaneago) {
      consultarContas();
    }
  }, [usuario?.cpfCnpj, usuario?.ativo, usuario?.clienteSaneago]);

  useEffect(() => {
    if (!isLogged) {
      resetContaUsuario();
    }
  }, [isLogged]);

  async function consultarContas() {
    const lista: Conta[] = await consultarContasUsuario();
    setContasUsuario(lista);

    // seleciona a primeira conta da lista automaticamente
    if (lista && lista[0]) {
      await alterarContaSelecionada(lista[0]);
    }
  }

  async function consultarContasUsuario(): Promise<Conta[]> {
    try {
      const response = await useAxiosFaturamento.get("/titularidades/contas", {
        params: {
          cpfCnpj: usuario?.cpfCnpj,
          tipoCliente: usuario?.tipoCliente,
        },
      });

      const lista = response?.data?.listaResultados;

      if (!lista) {
        notification.showWarning({
          message: Mensagens.NENHUMA_CONTA_ENCONTRADA,
          dismiss: false,
        });
      }

      return lista;
    } catch (e: any) {
      //
      await notification.showError({
        message: "Problemas ao consultar as contas do usuário.",
        exception: e,
      });
    }

    return [] as Conta[];
  }

  async function alterarContaSelecionada(conta: Conta) {
    const _conta = await consultarDadosConta(conta);
    setContaSelecionada(_conta);
  }

  async function consultarDadosConta(conta: Conta): Promise<Conta> {
    try {
      const res = await useAxiosAtendimento.get(
        `/atendimentos/contas/${conta.numeroConta}`
      );

      const _conta = { ...conta, ...(res.data as Conta) };

      return _conta;
    } catch (e: any) {
      //
      await notification.showError({
        message:
          "Problemas ao consultar os dados da conta: " +
          conta.numeroContaComDigito,
        exception: e,
      });
    }

    return {} as Conta;
  }

  async function reconsultarDadosContaSelecionada() {
    const _contaSelecionada = { ...{}, ...contaSelecionada };

    const lista: Conta[] = await consultarContasUsuario();
    setContasUsuario(lista);

    if (lista) {
      const itemSelecionado = lista.find(
        (item) => _contaSelecionada.numeroConta === item.numeroConta
      ) as Conta;

      await alterarContaSelecionada(itemSelecionado);
    }
  }

  function resetContaUsuario() {
    setContaSelecionada(null);
    setContasUsuario(null);
  }

  return (
    <ContaUsuarioContext.Provider
      value={{
        contaSelecionada,
        contasUsuario,
        alterarContaSelecionada,
        reconsultarDadosContaSelecionada,
      }}
    >
      {children}
    </ContaUsuarioContext.Provider>
  );
};

export function useContaUsuario() {
  const context = useContext(ContaUsuarioContext);
  return context;
}
