import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { openURL } from "../../utils/functions";
import { useHistory } from "react-router";
import Page from "../../components/Page";
import Button from "../../components/Button";

export default function Agencia() {
  const navigate = useHistory();

  const listaTelefoneGoias = [
    {
      descricao: "Atendimento Geral",
      telefone: "0800 645 0115",
    },
    {
      descricao: "Grandes Clientes",
      telefone: "0800 645 0116",
    },
    {
      descricao: "Ouvidoria",
      telefone: "0800 645 0117",
    },
  ];

  const listaTelefone = [
    {
      descricao: "Atendimento Geral",
      telefone: "+55 62 3221-6000",
    },
    {
      descricao: "Grandes Clientes",
      telefone: "+55 62 3221-6002",
    },
    {
      descricao: "Ouvidoria",
      telefone: "+55 62 3221-6001",
    },
  ];

  return (
    <Page title="Encontre uma agência" showCardConta={false}>
      <div className="flex flex-col space-y-4">
        <div className="card">
          <div className="card-title">Atendimento para o estado de Goiás</div>

          <DataTable
            value={listaTelefoneGoias}
            responsiveLayout="scroll"
            size="small"
          >
            <Column field="descricao" headerStyle={{ padding: 0 }}></Column>
            <Column
              field="telefone"
              headerStyle={{ padding: 0 }}
              body={styleBodyTemplate}
            ></Column>
          </DataTable>
        </div>

        <div className="card">
          <div className="card-title">
            Atendimento para fora do estado de Goiás ou do Brasil
          </div>

          <DataTable
            value={listaTelefone}
            responsiveLayout="scroll"
            size="small"
          >
            <Column field="descricao" headerStyle={{ padding: 0 }}></Column>
            <Column
              field="telefone"
              headerStyle={{ padding: 0 }}
              body={styleBodyTemplate}
            ></Column>
          </DataTable>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <Button
            label="Vapt Vupt"
            fullWidth
            onClick={() => openURL("http://www.vaptvupt.go.gov.br/unidades")}
          />

          <Button
            label="Unidades do interior"
            fullWidth
            onClick={() => {
              navigate.push("/unidadesInterior");
            }}
          />

          <Button
            label="Unidades da Saneago"
            fullWidth
            onClick={() => {
              navigate.push("/unidadesSaneago");
            }}
          />
        </div>
      </div>
    </Page>
  );
}

const styleBodyTemplate = (rowData: { telefone: string }) => {
  return <div className="text-right">{rowData.telefone}</div>;
};
