import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import InputText from "../../components/form/InputText";
import SubmitButton from "../../components/form/SubmitButton";
import ReactForm from "../../components/form/ReactForm";
import { EmailFormData } from "./CadastroFaturaDigital";
import { useAuth } from "../../contexts/authContext";
import InputRadioGroupComponent from "../../components/form/InputRadioGroup";

// Definição do esquema de validação
const emailSchema = z
  .object({
    email: z.string().email({ message: "Informe um e-mail válido." }),
    confirmarEmail: z
      .string()
      .email({ message: "Confirmação de e-mail inválida." }),
  })
  .refine((data) => data.email === data.confirmarEmail, {
    message: "Os dois e-mails informados devem ser iguais.",
    path: ["confirmarEmail"],
  });

// Definição do tipo para as props do componente
interface CadastroEmailProps {
  onSubmit: (data: EmailFormData) => void;
  handleCancelar: () => void;
}

const CadastroEmail: React.FC<CadastroEmailProps> = ({
  onSubmit,
  handleCancelar,
}) => {
  const { usuario } = useAuth();
  const { register, reset } = useForm({
    resolver: zodResolver(emailSchema),
  });

  // Função para lidar com a submissão do formulário
  const handleFormSubmit = async (data: any) => {
    onSubmit(data);
    await reset(); // Reseta o formulário após a submissão
  };

  const opcoesPreferenciaEmail = [
    {
      value: "emailLogin",
      label: "Utilizar e-mail do login",
    },
    {
      value: "outroEmail",
      label: "Utilizar outro e-mail",
    },
  ];

  const [usaEmailLogin, setUsaEmailLogin] = useState<boolean>(true);

  return (
    <>
      <ReactForm
        schema={emailSchema}
        onSubmit={(data) => handleFormSubmit(data)}
        onError={(error, e) => {}}
      >
        <div className="flex flex-col space-y-4">
          <div className="text-slate-700">
            Sua conta ainda não aderiu à fatura digital. Escolha a praticidade
            da Saneago, recebendo suas contas de água diretamente no e-mail para
            facilitar o gerenciamento de pagamentos.
          </div>
          <InputRadioGroupComponent
            name="preferenciaEmail"
            label="Preferência de E-mail"
            items={opcoesPreferenciaEmail}
            onSelectItem={(value) =>
              setUsaEmailLogin(value?.value === "emailLogin")
            }
            defaultValuePosition={0}
          />
          <InputText
            name="email"
            label="E-mail"
            inputProps={{ maxLength: 60, ...register("email") }}
            defaultValue={usaEmailLogin ? usuario?.email : ""}
            readonly={usaEmailLogin}
          />
          <InputText
            name="confirmarEmail"
            label="Confirmar e-mail"
            inputProps={{ maxLength: 60, ...register("confirmarEmail") }}
            onPaste={(e) => e.preventDefault()} // Desabilita a opção de colar no campo
            defaultValue={usaEmailLogin ? usuario?.email : ""}
            readonly={usaEmailLogin}
          />
          <SubmitButton label="Próximo" />
        </div>
      </ReactForm>
    </>
  );
};

export default CadastroEmail;
