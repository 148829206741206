import { IonSpinner } from "@ionic/react";

export interface Props {
  label: string | null;
}

export default function DivInputLoading(props: Props) {
  return (
    <div
      className="bg-slate-50 w-full border rounded px-3 border-slate-300"
      style={{ height: 56 }}
    >
      <div className="flex flex-row items-center justify-start h-full space-x-2 font-light">
        <div className="font-normal text-slate-500">{props.label}</div>
        <IonSpinner name="dots"></IonSpinner>
      </div>
    </div>
  );
}
