import { IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/react";
import { ApiRoutes, Mensagens, NoticiaComunicado } from "../../models/types";
import { getBaseURL, limitarString } from "../../utils/functions";
import { useEffect, useState } from "react";
import { classNames } from "primereact/utils";
import { useHistory } from "react-router";

import axios from "axios";
import Page from "../../components/Page";
import useNotification from "../../hooks/useNotification";
import DOMPurify from "dompurify";
import DivLoading from "../../components/DivLoading";

export default function Noticias() {
  const navigate = useHistory();
  const notification = useNotification();

  const [data, setData] = useState<NoticiaComunicado[] | null>(null);

  useEffect(() => {
    consultar();
  }, []);

  async function consultar(event?: CustomEvent<void>) {
    let parametros = {
      limit: 10,
      destaque: false,
      offset: data?.length || 0,
      tipo: String([1, 3]),
    };

    try {
      const url = getBaseURL(ApiRoutes.NOTICIAS) + "";

      const response = await axios.get(url, {
        params: parametros,
        headers: { "Content-Type": "application/json" },
      });

      let lista =
        data && data.length > 0 ? data.concat(response.data) : response.data;

      setData(lista);

      (event?.target as HTMLIonInfiniteScrollElement)?.complete();
    } catch (e: any) {
      (event?.target as HTMLIonInfiniteScrollElement)?.complete();

      await notification.showError({ exception: e });
    }
  }

  function detalhar(item: NoticiaComunicado) {
    navigate.push({ pathname: "/noticiaDetalhe", state: item });
  }

  if (!data) {
    return (
      <Page title="Notícias e comunicados" showCardConta={false}>
        <DivLoading label="Consultando notícias e comunicados..." />
      </Page>
    );
  }

  return (
    <Page title="Notícias e comunicados" showCardConta={false}>
      <div className="flex flex-col space-y-4" hidden={!data}>
        {data?.map((item, index) => {
          return (
            <div
              className="cursor-pointer"
              key={index}
              onClick={() => detalhar(item)}
            >
              <CardNoticia
                titulo={item.titulo}
                tipo={item.tipo}
                tag={item.tag}
                data={item.dataEdicao}
                resumo={item.resumo}
                conteudo={item.conteudo}
              />
            </div>
          );
        })}

        <IonInfiniteScroll
          onIonInfinite={(e: CustomEvent<void>) => consultar(e)}
        >
          <IonInfiniteScrollContent
            loadingText="Carregando mais..."
            loadingSpinner="bubbles"
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </div>
    </Page>
  );
}

function CardNoticia({
  titulo,
  data,
  resumo,
  conteudo,
  tipo,
  tag,
}: {
  titulo: string;
  data: string;
  resumo: string;
  conteudo: string;
  tipo: number;
  tag: string;
}) {
  return (
    <div className="bg-white w-full rounded-lg shadow-md bg-opacity-70 p-4 space-y-2">
      <div className="text-slate-700 font-medium">{titulo}</div>
      <div className="flex flex-row space-x-2 items-center">
        <div
          className={classNames("rounded-xl py-1 px-2 text-xs text-white", {
            "bg-sky-500": tipo === 1,
            "bg-primary": tipo !== 1,
          })}
        >
          {tag}
        </div>

        <div className="text-sm text-slate-400">{data}</div>
      </div>

      <div className="text-sm pt-2">
        <span
          className="text-slate-700"
          dangerouslySetInnerHTML={{
            __html: truncate(resumo || ""),
          }}
        ></span>
        <span className="text-secondary">clique para ler mais.</span>
      </div>
    </div>
  );
}

function truncate(texto: string): string {
  const purify = DOMPurify.sanitize(texto);

  const _texto = limitarString(purify, 150);
  return _texto.substring(0, _texto.lastIndexOf(" ")) + "... ";
}
