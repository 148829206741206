import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  RadioProps,
} from "@mui/material";
import { useFormContext } from "react-hook-form";
import { classNames } from "primereact/utils";
import { Item } from "../../models/types";

export interface InputRadioGroupProps extends RadioProps {
  name: string;
  label?: string;
  items: Item[];
  defaultValuePosition?: number;
  readonly?: boolean;
  onSelectItem?(item: Item | null): void;
}

const InputRadioGroupComponent: React.FC<InputRadioGroupProps> = (
  props: InputRadioGroupProps
) => {
  //

  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const {
    name,
    label,
    items,
    defaultValuePosition,
    readonly,
    onSelectItem,
    ...propsOriginal
  } = props;

  // useEffect(() => {
  //   if ((defaultValuePosition || defaultValuePosition === 0) && items) {
  //     const defaultValue = items[defaultValuePosition].value;
  //     setValue(name, defaultValue);
  //     // Definindo o valor padrão no contexto do formulário
  //     const selectedItem = items.find((item) => item.value === defaultValue) || null;
  //     if (onSelect) {
  //       onSelect(selectedItem);
  //     }
  //   }
  // }, [defaultValuePosition, items, name, setValue, onSelect]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    setValue(name, selectedValue); // Atualizando o valor no contexto do formulário

    const selectedItem =
      items.find((item) => item.value === selectedValue) || null;
    if (onSelectItem) {
      onSelectItem(selectedItem);
    }
  };

  return (
    <div
      className={classNames("", {
        "pointer-events-none opacity-70": readonly,
      })}
    >
      <FormControl component="fieldset" error={!!errors[name]}>
        <FormLabel component="legend">{label}</FormLabel>
        <RadioGroup
          name={name}
          onChange={onChange}
          row // Exibe os radio buttons na horizontal
          defaultValue={
            defaultValuePosition || defaultValuePosition === 0
              ? items[defaultValuePosition].value
              : undefined
          }
        >
          {items.map((item) => (
            <FormControlLabel
              key={item.value}
              value={item.value}
              control={<Radio {...register(name)} {...propsOriginal} />}
              label={item.label}
              disabled={readonly}
            />
          ))}
        </RadioGroup>
      </FormControl>

      <div className="text-xs text-red-600" hidden={!errors[name]}>
        {errors[name as string]?.message?.toString()}
      </div>
    </div>
  );
};

export default InputRadioGroupComponent;
