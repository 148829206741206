import { useHistory } from "react-router";
import { NoticiaComunicado } from "../../models/types";
import { useEffect, useState } from "react";
import Page from "../../components/Page";
import DOMPurify from "dompurify";

export default function NoticiaDetalhe() {
  const navigate = useHistory<any>();

  const [data, setData] = useState<NoticiaComunicado | null>(null);

  useEffect(() => {
    //dados vindos da navegação
    setData(navigate.location.state);
  }, [navigate.location.state]);

  return (
    <Page
      title="Notícias e comunicados"
      showCardConta={false}
      buttonType="back"
    >
      <div className="flex flex-col space-y-4">
        <div className="card bg-opacity-70">
          <div className="card-title">{data?.tag}</div>

          <div className="space-y-2">
            <div className="text-secondary text-lg">
              {data?.titulo}
            </div>

            <div className="text-sm text-slate-400">{data?.dataEdicao}</div>

            <div
              className="pt-4 text-slate-700"
              dangerouslySetInnerHTML={{
                __html: sanitize(data?.conteudo || ""),
              }}
            ></div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export function sanitize(texto: string): string {
  const purify = DOMPurify.sanitize(texto);

  // react nao reconhece/renderiza o "\n" como quebra de linha
  return purify.replace(/\n/g, "<br/>");
}
