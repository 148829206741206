import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button as ButtonRP } from "primereact/button";
import ButtonBack from "../../components/ButtonBack";
import { TelefoneFormData } from "./CadastroFaturaDigital";
import Button from "../../components/Button";
import { TIPOS_TELEFONE } from "../../models/types";
import "primeicons/primeicons.css";

// Definição do tipo para as props do componente
interface CadastroTabelaTelefonesProps {
  telefones: TelefoneFormData[];
  onAdicionarTelefone: () => void;
  onRemoverTelefone: (index: number) => void;
  onSubmit: () => void;
  handleCancelar: () => void;
}

const CadastroTabelaTelefones: React.FC<CadastroTabelaTelefonesProps> = ({
  telefones,
  onAdicionarTelefone,
  onRemoverTelefone,
  onSubmit,
  handleCancelar,
}) => {
  // const tipoTelefoneBodyTemplate = (rowData: TelefoneFormData) => {
  //   const tipoEncontrado = TIPOS_TELEFONE.find(tipo => tipo.value === rowData.tipo);
  //   return (
  //     <>
  //       {tipoEncontrado ? tipoEncontrado.label : ''}
  //     </>
  //   );
  // };

  const telefoneBodyTemplate = (
    rowData: TelefoneFormData,
    props: { rowIndex: number }
  ) => {
    const bool = String(rowData.possuiWhats) === "true";
    const tipoEncontrado = TIPOS_TELEFONE.find(
      (tipo) => tipo.value === rowData.tipo
    );

    return (
      <>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <span>{tipoEncontrado ? `${tipoEncontrado.label}: ` : ""}</span>
          <span>{rowData.telefoneCompleto}</span>
          {bool && <i className="pi pi-whatsapp" style={{ color: "green" }} />}
        </div>
      </>
    );
  };

  const acoesBodyTemplate = (
    rowData: TelefoneFormData,
    props: { rowIndex: number }
  ) => {
    return (
      <ButtonRP
        icon="pi pi-trash"
        className="p-button-rounded p-button-warning custom-slate-300"
        style={{ backgroundColor: "#cbd5e1" }}
        onClick={() => onRemoverTelefone(props.rowIndex)}
      />
    );
  };

  return (
    <>
      <div className="flex flex-col space-y-8">
        <div className="flex flex-col space-y-4">
          <DataTable value={telefones} responsiveLayout="scroll">
            <Column body={telefoneBodyTemplate} header="Telefone" />
            <Column body={acoesBodyTemplate} header="Ações" />
          </DataTable>
          <Button
            label="Adicionar outro telefone"
            onClick={onAdicionarTelefone}
          />

          <div className="text-slate-700">
            Ao solicitar a Fatura Digital da Saneago, será enviado um e-mail de
            confirmação para o endereço informado com todas as instruções para a
            conclusão da adesão.
          </div>

          <div className="grid grid-cols-2 gap-2 xs:grid-cols-1">
            <Button label="Solicitar Fatura Digital" onClick={onSubmit} />
            <ButtonBack label="Cancelar" onClickEvent={handleCancelar} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CadastroTabelaTelefones;
