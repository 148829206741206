import {
  completarCpfCnpj,
  isCnpjValido,
  isCpfValido,
} from "../../utils/functions";
import {
  ApiRoutes,
  Mensagens,
  TIPOS_PESSOA,
  TipoPessoa,
} from "../../models/types";
import { useHistory } from "react-router";
import { z } from "zod";
import { useAxiosClient } from "../../hooks/useAxiosClient";
import { useIonLoading } from "@ionic/react";
import { useState } from "react";
import ButtonBack from "../../components/ButtonBack";
import InputText from "../../components/form/InputText";
import ReactForm from "../../components/form/ReactForm";
import SubmitButton from "../../components/form/SubmitButton";
import Page from "../../components/Page";
import useNotification from "../../hooks/useNotification";
import SelectField from "../../components/form/SelectField";

export default function RecuperarSenha() {
  const [present, dismiss] = useIonLoading();
  const [tipoCliente, setTipoCliente] = useState<string>();

  const axiosClient = useAxiosClient(ApiRoutes.ESI_USUARIO_AGENCIA_VIRTUAL);
  const navigate = useHistory();
  const notification = useNotification();

  const isTipoClientePessoaFisica = (() => {
    return tipoCliente === TipoPessoa.PESSOA_FISICA;
  })();

  const formSchema = z
    .object({
      tipoCliente: z.any().transform((input) => input?.value),
      cpfCnpj: z.string(),
    })
    .refine(
      (data) => data.cpfCnpj,
      (data) => ({
        message: isTipoClientePessoaFisica
          ? "Informe um CPF."
          : "Informe um CNPJ.",
        path: ["cpfCnpj"],
      })
    )
    .refine(
      (data) =>
        isTipoClientePessoaFisica
          ? isCpfValido(data.cpfCnpj)
          : isCnpjValido(data.cpfCnpj),
      (data) => ({
        message: isTipoClientePessoaFisica ? "CPF inválido." : "CNPJ inválido.",
        path: ["cpfCnpj"],
      })
    );

  async function enviarDados(usuario: any) {
    const tipoCliente = usuario.tipoCliente;
    const cpfCnpj = completarCpfCnpj(usuario.cpfCnpj);

    await present({ message: Mensagens.VERIFICANDO_DADOS });

    try {
      const url = `/atendimentos/usuarios/${tipoCliente}/${cpfCnpj}`;
      const response = await axiosClient.get(url);

      const _usuario = {
        cpfCnpj: cpfCnpj,
        tipoCliente: tipoCliente,
        ...response?.data,
      };

      enviarEmail(_usuario);
      //
    } catch (e: any) {
      dismiss();

      if (e.response.status === 404) {
        notification.showAlert({
          message: Mensagens.CLIENTE_NAO_CADASTRADO_NAO_CONFIRMADO,
        });

        return;
      }

      await notification.showError({ exception: e });
    }
  }

  async function enviarEmail(usuario: any) {
    try {
      const url = `/atendimentos/usuarios/${usuario.tipoCliente}/${usuario.cpfCnpj}/senha/recuperar`;

      await axiosClient.get(url);

      navigate.push({
        pathname: "/recuperarConfirmaEmail",
        state: { data: usuario },
      });

      dismiss();
    } catch (e: any) {
      dismiss();

      if (e.response.status === 403) {
        notification.showAlert({
          message: Mensagens.CLIENTE_NAO_CADASTRADO_NAO_CONFIRMADO,
        });
        return;
      }

      await notification.showError({ exception: e });
    }
  }

  return (
    <Page showCardConta={false}>
      <div className="card bg-opacity-70">
        <div className="p-2">
          <div className="font-bold text-lg text-primary pb-4">
            Recuperar senha
          </div>

          <ReactForm
            schema={formSchema}
            onSubmit={(e) => enviarDados(e)}
            onError={(error, e) => {}}
          >
            <div className="flex flex-col space-y-4">
              <SelectField
                name="tipoCliente"
                placeHolder="Tipo de cliente"
                defaultValuePosition={0}
                onSelect={(item) => {
                  setTipoCliente(item?.value as string);
                }}
                items={TIPOS_PESSOA}
              />

              <InputText
                name="cpfCnpj"
                label={isTipoClientePessoaFisica ? "CPF" : "CNPJ"}
                mask={
                  isTipoClientePessoaFisica
                    ? "999.999.999-99"
                    : "99.999.999/9999-99"
                }
              />

              <div className="grid grid-cols-2 gap-2 xs:grid-cols-1">
                <SubmitButton label="Enviar" />
                <ButtonBack />
              </div>
            </div>
          </ReactForm>
        </div>
      </div>
    </Page>
  );
}
