import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../../contexts/authContext";

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const { isLogged } = useAuth();

  // rotas privadas precisam que o usuario esteja logado, caso não esteja redireciona para o login
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogged ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

export default PrivateRoute;
