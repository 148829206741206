import { useState, useEffect } from "react";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  const size =
    width < 501 // min-width: 0px
      ? "xs"
      : width < 768 // min-width: 501px
      ? "sm"
      : width < 1024 // min-width: 768px
      ? "md"
      : width < 1280 // min-width: 1024px
      ? "lg"
      : width < 1536 // min-width: 1280px
      ? "xl" // min-width: 1536px
      : "2xl";

  return {
    width,
    height,
    size,
    isMobileScreen: size === "xs",
    isLittleScreen: size === "xs" || size === "sm",
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
