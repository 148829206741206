import { useState } from "react";
import { ApiRoutes, SegundaVia } from "../../models/types";
import { useHistory } from "react-router";
import PageRequestConta from "../../components/PageRequest";
import CardCliente from "../../components/CardCliente";
import CardFaturasEmAberto from "../../components/CardFaturasEmAberto";
import Button from "../../components/Button";

export default function SegundaViaPage() {
  const navigate = useHistory<any>();

  const [data, setData] = useState<SegundaVia | null>(null);

  return (
    <PageRequestConta<SegundaVia>
      title="Emissão de 2ª via da fatura"
      api={ApiRoutes.ECO_FATURAMENTO}
      endPoint="/faturamentos/faturas/pendentes/segunda-via"
      nomeParametroConta="numeroContaComDigito"
      onContaChange={(data: SegundaVia) => setData(data)}
      necessarioSelecionarConta
    >
      <div className="flex flex-col space-y-4">
        <CardCliente />

        <CardFaturasEmAberto
          data={data}
          onGerarFatura={(debitos) => {
            navigate.push({
              pathname: "/segundaViaFatura",
              state: { data: debitos },
            });
          }}
        />

        <div className="card">
          <div className="font-light text-sm">
            Gostaria de emitir faturas de outras contas que não pertecem a essa
            titularidade?
          </div>
          <Button
            className="mt-2 px-4 py-1 bg-tertiary hover:bg-secondary rounded-xl text-sm font-medium text-slate-50 transition duration-200"
            label="Consultar outras titularidades/contas"
            onClick={() => navigate.push("/segundaViaSimplificada")}
          />
        </div>
      </div>
    </PageRequestConta>
  );
}
