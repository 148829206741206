import React, { useEffect, useRef, useState } from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { Item, Mensagens } from "../../models/types";
import { arrayEquals } from "../../utils/functions";
import { classNames } from "primereact/utils";
import usePrevious from "../../hooks/usePrevious";

export interface InputCompleteProps {
  items?: Item[];
  name: string;
  placeHolder?: string;
  defaultValuePosition?: number; // informe qual posicao do array deve vir selecionada por padrao
  disableClearable?: boolean;
  readonly?: boolean;
  showDivDetalhes?: boolean;
  titleDivDetalhes?: string;
  onSelect?(item: Item | null): void;
}

const InputComplete: React.FC<InputCompleteProps> = (
  props: InputCompleteProps
) => {
  const {
    register,
    formState: { isSubmitting, errors },
  } = useFormContext();

  const {
    name,
    placeHolder,
    items,
    defaultValuePosition,
    disableClearable,
    readonly,
    onSelect,
    showDivDetalhes,
    titleDivDetalhes,
  } = props;

  const { ref, ...rest } = register(name);

  const [value, setValue] = useState<Item | null>(null);
  const [options, setOptions] = useState<Item[]>();
  const [erro, setErro] = useState<boolean>(false);

  const prevItems = usePrevious(items);
  const inputData = useRef<any>();

  useEffect(() => {
    if (!arrayEquals(items, prevItems, "value")) {
      setOptions(items);
      onChange(null);
    }
  }, [items]);

  useEffect(() => {
    if ((defaultValuePosition || defaultValuePosition === 0) && items) {
      setTimeout(() => {
        onChange(items[defaultValuePosition]);
      }, 200);
    }
  }, [defaultValuePosition]);

  useEffect(() => {
    if (!inputData.current?.value && inputData.current?.value !== 0) {
      onChange(null);
    }
  }, [inputData.current?.value]);

  useEffect(() => {
    if (errors[name]) {
      setErro(true);
    } else {
      setErro(false);
    }
  }, [errors, name]);

  const onChange = (item: Item | null) => {
    setValue(item);
    setErro(item ? false : errors[name] && !item ? true : false);

    inputData.current.value = item ? item.value : "";
    inputData.current.focus();

    if (onSelect) {
      onSelect(item);
    }
  };

  return (
    <div className={classNames({ "pointer-events-none opacity-70": readonly })}>
      <div style={{ height: "0px" }}>
        {/* esse input ficara invisivel (width: 0) e sera utilizado para armazenar o value */}
        <input
          style={{ width: "0px" }}
          autoFocus={true}
          type="text"
          {...rest}
          name={name}
          ref={(e) => {
            ref(e);
            inputData.current = e;
          }}
        />
      </div>

      <Autocomplete
        options={options || []}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        value={value}
        disableClearable={disableClearable}
        onChange={(event: any, newValue: Item | null) => onChange(newValue)}
        renderOption={(props, option) => (
          <Box component="li" {...props} key={option.value}>
            {option.label}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            className="bg-slate-50 w-full"
            label={placeHolder ? placeHolder : name}
            variant="outlined"
            helperText={erro ? errors[name as string]?.message?.toString() : ""}
            error={erro}
          />
        )}
        noOptionsText={Mensagens.NENHUM_RESULTADO_ENCONTRADO}
      />

      <div
        hidden={!value?.detalhes || !showDivDetalhes}
        className="mt-2 p-2 text-sm bg-slate-100"
      >
        <div hidden={!titleDivDetalhes} className="text-secondary">
          {titleDivDetalhes}
        </div>
        <div className="pt-1 text-justify">{value?.detalhes}</div>
      </div>
    </div>
  );
};

export default InputComplete;
