import { format } from "date-fns";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useState } from "react";
import { ApiRoutes, HistoricoConsumo, Mensagens } from "../models/types";
import { formatarDinheiro } from "../utils/functions";
import PageRequestConta from "../components/PageRequest";

export default function HistoricoConsumoFaturado() {
  const [data, setData] = useState<HistoricoConsumo | null>(null);

  return (
    <PageRequestConta<HistoricoConsumo>
      title="Histórico de consumo faturado"
      api={ApiRoutes.ECO_FATURAMENTO}
      endPoint="/contas-leitura/consumos/historicos"
      nomeParametroConta="conta"
      extraParams={{ quantidadeMeses: 12 }}
      onContaChange={(data: HistoricoConsumo) => setData(data)}
      necessarioSelecionarConta
    >
      <div className="flex flex-col space-y-4">
        <div className="card">
          <div className="card-title">Estatística de consumo</div>

          <div className="font-medium grid grid-cols-1 lg:grid-cols-2 items-center justify-center gap-4 p-2">
            <div className="grid grid-cols-2 bg-secondary bg-opacity-5 px-4 py-2 rounded-2xl">
              <div className="text-secondary ">Médio</div>
              <div className="text-right">{data?.consumoMedio}</div>
            </div>

            <div className="grid grid-cols-2 bg-secondary bg-opacity-5 px-4 py-2 rounded-2xl">
              <div className="text-secondary ">Estimado</div>
              <div className="text-right ">{data?.consumoEstimado}</div>
            </div>
          </div>
        </div>

        <div className="card-borda">
          <div className="card-title">Consumo dos últimos 12 meses</div>

          <DataTable
            value={data?.historicoConsumo}
            emptyMessage={Mensagens.NENHUM_RESULTADO_ENCONTRADO}
            responsiveLayout="scroll"
            size="small"
            stripedRows
          >
            <Column
              field="referencia"
              header="Referência"
              alignHeader={"right"}
              align={"right"}
              body={referenciaBodyTemplate}
            ></Column>
            <Column
              field="consumo"
              header="Consumo(m³)"
              alignHeader={"right"}
              align={"right"}
            ></Column>
            <Column
              field="valor"
              header="Valor"
              alignHeader={"right"}
              align={"right"}
              body={valorBodyTemplate}
            ></Column>
          </DataTable>
        </div>
      </div>
    </PageRequestConta>
  );
}

const valorBodyTemplate = (rowData: { valor: number | bigint }) => {
  return formatarDinheiro(rowData.valor);
};

const referenciaBodyTemplate = (rowData: { referencia: unknown }) => {
  return format(new Date(rowData.referencia as Date), "MM/yyyy");
};
