import React from "react";

const DivFaltaAgua: React.FC = () => {
  return (
    <div className="">
      <div className="mb-2 text-sm font-medium text-slate-700">
        A falta de água pode ter três motivos:
      </div>

      <ol className="list-decimal pl-4 space-y-2">
        <li>Manutenção programada no sistema de água;</li>
        <li>
          Manutenção não programada no sistema de água devido a rompimentos em
          adutoras, falta de energia e outros problemas;
        </li>
        <li>Por interrupção no fornecimento de água por falta de pagamento.</li>
      </ol>

      <div className="mt-4 text-sm font-medium text-slate-700">
        Antes, verificar se a falta de água é somente em seu imóvel ou se o
        registro está aberto ou se há a existência de corte.
      </div>
    </div>
  );
};

export default DivFaltaAgua;
