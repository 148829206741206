import { IonLabel } from "@ionic/react";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useAuth } from "../contexts/authContext";
import { useContaUsuario } from "../contexts/contaUsuarioContext";
import { useAxiosClient } from "../hooks/useAxiosClient";
import { ApiRoutes, Mensagens } from "../models/types";
import useNotification from "../hooks/useNotification";
import Page from "./Page";
import DivLoading from "./DivLoading";
import Button from "./Button";

export declare type ButtonType = "menu" | "back" | "none";
export declare type RequestType = "get" | "post" | "put" | "delete";
export declare type ModoUsoConta = "COM_DIGITO" | "SEM_DIGITO" | "NAO_USAR";

export interface PageRequestProps<T> {
  children: JSX.Element;
  title?: string;
  showDegradeBackground?: boolean;
  showCardConta?: boolean;
  desabilitarCardConta?: boolean;
  necessarioSelecionarConta?: boolean;
  modoUsoConta?: ModoUsoConta;
  buttonType?: ButtonType;
  api: ApiRoutes;
  endPoint: string;
  extraParams?: any;
  nomeParametroConta?: string;
  requestType?: RequestType;
  onContaChange(data: T | null): void;
}

export default function PageRequestConta<T>({
  title,
  showCardConta,
  desabilitarCardConta,
  showDegradeBackground,
  necessarioSelecionarConta,
  modoUsoConta,
  buttonType,
  onContaChange,
  endPoint,
  api,
  nomeParametroConta,
  extraParams,
  requestType,
  children,
}: PageRequestProps<T>) {
  const auth = useAuth();
  const contaUsuarioContext = useContaUsuario();
  const useAxios = useAxiosClient(api);
  const notification = useNotification();

  const [data, setData] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const cpfCnpj = auth.usuario!.cpfCnpj;
  const tipoCliente = auth.usuario!.tipoCliente;
  const contaSelecionada = contaUsuarioContext.contaSelecionada;

  useEffect(() => {
    consultarDados();
  }, [contaSelecionada]);

  async function consultarDados() {
    if (!contaSelecionada) {
      return;
    }

    setIsLoading(true);
    setData(null);
    setError(false);

    const params: any = {
      cpfCnpj: cpfCnpj,
      tipoCliente: tipoCliente,
    };

    // Definir o nome do parâmetro da conta
    let numeroContaKey: string | undefined;
    let numeroContaValue: string | undefined;

    switch (modoUsoConta) {
      case "SEM_DIGITO":
        numeroContaKey = "numeroConta";
        numeroContaValue = contaSelecionada.numeroConta?.toString();
        break;
      case "NAO_USAR":
        numeroContaKey = undefined;
        numeroContaValue = undefined;
        break;
      default:
        //  "COM_DIGITO" padrão
        numeroContaKey = nomeParametroConta || "numeroContaComDigito";
        numeroContaValue = contaSelecionada.numeroContaComDigito?.toString();
    }

    if (numeroContaKey && numeroContaValue) {
      params[numeroContaKey] = numeroContaValue;
    }

    const finalParams = { ...params, ...extraParams };

    try {
      let response: AxiosResponse<T, any>;

      switch (requestType) {
        case "post":
          response = await useAxios.post<T>(
            endPoint,
            JSON.stringify(finalParams),
            {
              headers: {
                "content-type": "application/json",
              },
            }
          );

          break;

        default:
          response = await useAxios.get<T>(endPoint, { params: finalParams });
          break;
      }

      setIsLoading(false);
      setData(response.data);
      onContaChange(response.data);
    } catch (e: any) {
      setIsLoading(false);
      setError(true);

      await notification.showError({ exception: e });
    }
  }

  if (isLoading) {
    return (
      <Page
        title={title}
        buttonType={buttonType}
        necessarioSelecionarConta={necessarioSelecionarConta}
        showCardConta={showCardConta}
        desabilitarCardConta={desabilitarCardConta}
        showDegradeBackground={showDegradeBackground}
      >
        <DivLoading label={`Consultando ${title}...`} />
      </Page>
    );
  }

  if (error) {
    return (
      <Page
        title={title}
        buttonType={buttonType}
        necessarioSelecionarConta={necessarioSelecionarConta}
        showCardConta={showCardConta}
        desabilitarCardConta={desabilitarCardConta}
        showDegradeBackground={showDegradeBackground}
      >
        <div className="flex flex-col justify-center items-center p-4 space-y-4">
          <IonLabel className="text-center">
            {Mensagens.ERRO_INESPERADO}
          </IonLabel>

          <Button label="Consultar novamente" onClick={consultarDados} />
        </div>
      </Page>
    );
  }

  if (!data) {
    return (
      <Page
        title={title}
        buttonType={buttonType}
        necessarioSelecionarConta={necessarioSelecionarConta}
        showCardConta={showCardConta}
        desabilitarCardConta={desabilitarCardConta}
        showDegradeBackground={showDegradeBackground}
      >
        <div
          className="text-md font-bold text-center p-4"
          hidden={!contaSelecionada}
        >
          {Mensagens.NENHUM_RESULTADO_ENCONTRADO}
        </div>
      </Page>
    );
  }

  return (
    <Page
      title={title}
      buttonType={buttonType}
      necessarioSelecionarConta={necessarioSelecionarConta}
      showCardConta={showCardConta}
      desabilitarCardConta={desabilitarCardConta}
      showDegradeBackground={showDegradeBackground}
    >
      <div className="flex flex-col">{children}</div>
    </Page>
  );
}
