import {
  IonBackButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { classNames } from "primereact/utils";
import { isIos } from "../utils/functions";
import useWindowDimensions from "../hooks/useWindowDimensions";

export declare type ButtonType = "menu" | "back" | "none";

export interface HeaderSaneagoProps {
  buttonType: ButtonType;
  title?: string;
}

const HeaderSaneago = ({ buttonType, title }: HeaderSaneagoProps) => {
  const { isMobileScreen, width, size } = useWindowDimensions();

  return (
    <IonHeader>
      <IonToolbar color="primary">
        <IonButtons slot="start">
          {buttonType === "menu" ? (
            <IonMenuButton className="mx-1">

              <IonIcon
                src="assets/icon/menu.svg"
                className={classNames(
                  { "text-4xl": isIos() },
                  { "text-5xl": !isIos() }
                )}
              ></IonIcon>
            </IonMenuButton>
          ) : buttonType === "back" ? (
            isIos() ? (
              <IonBackButton defaultHref="/" text="Voltar" />
            ) : (
              <IonBackButton defaultHref="/" />
            )
          ) : (
            <></>
          )}
        </IonButtons>

        <IonTitle
          className={classNames(
            "px-0",
            { "-ml-12": width < 992 && !isIos() } // centralizando title na versao android
          )}
        >
          {title ? (
            <div
              // diminuindo o tamanho da font quando o texto eh muito grande
              className={classNames("text-center ", {
                "text-base":
                  isMobileScreen && title?.length > 27 && title?.length < 30,
                "text-sm": isMobileScreen && title?.length >= 30,
              })}
            >
              {title}
            </div>
          ) : (
            <div>
              <div className="w-full flex justify-center items-start">
                <img
                  className="w-40 lg:w-44"
                  src="./assets/logo_saneago_horizontal.svg"
                  alt=""
                ></img>
              </div>
            </div>
          )}
        </IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

export default HeaderSaneago;
