import { IonButton, IonToggle } from "@ionic/react";
import { openURL } from "../utils/functions";
import { useEffect, useState } from "react";
import { Mensagens, POLITICA_PRIVACIDADE } from "../models/types";
import { usePoliticaPrivacidade } from "../contexts/politicaPrivacidadeContext";
import Page from "../components/Page";
import Button from "../components/Button";

export default function CentroPreferencias() {
  const [politica, setPolitica] = useState<boolean>(true);
  const { aceitePolitica, aceitarPolitica, recusarPolitica } =
    usePoliticaPrivacidade();

  useEffect(() => {
    setPolitica(aceitePolitica);
  }, [aceitePolitica]);

  async function confirmar() {
    if (politica) {
      aceitarPolitica();
    } else {
      recusarPolitica();
    }
  }

  return (
    <Page title="Centro de preferências de privacidade" showCardConta={false}>
      <div className="flex flex-col space-y-4">
        <div className="card">
          <div className="text-slate-700">
            Como respeitamos seu direito à privacidade, você pode optar por não
            permitir o aceite do termo. No entanto, o bloqueio do aceite não
            permitirá o acesso ao aplicativo.
          </div>

          <div className="text-center pt-4">
            <IonButton
              type="button"
              mode="ios"
              fill="outline"
              onClick={() => openURL(POLITICA_PRIVACIDADE)}
            >
              {Mensagens.VEJA_POLITICA_PRIVACIDADE}
            </IonButton>
          </div>
        </div>

        <div className="card">
          <div className="grid grid-cols-2 gap-2">
            <div className="text-primary font-medium text-lg">Item</div>
            <div className="text-primary font-medium text-lg text-end">
              Aceite
            </div>

            <div className="text-slate-700">Política de privacidade</div>
            <div className="text-end">
              <IonToggle
                slot="end"
                checked={politica}
                onIonChange={(event) => setPolitica(event.target.checked)}
              ></IonToggle>
            </div>
          </div>
        </div>

        <div className="text-center">
          <Button label="Confirmar escolhas" onClick={() => confirmar()} />
        </div>
      </div>
    </Page>
  );
}
