import { ErrorGetter } from "react-zorm/dist/types";
import { ApiRoutes, TipoPessoa } from "../models/types";
import { format } from "date-fns";
import { Browser } from "@capacitor/browser";
import { isPlatform } from "@ionic/react";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { FileOpener } from "@capacitor-community/file-opener";

export function getErrorMessage(error: ErrorGetter) {
  return error((e) => e.message);
}

export function getOnlyNumbers(texto: string): string {
  if (!texto) {
    return "";
  }

  return texto.toString().replace(/\D+/g, "");
}

export async function delay(ms: number) {
  await new Promise((res) => setTimeout(res, ms));
}

export function completeLeft(
  value: string | number,
  size: number,
  symbol: string
): string {
  symbol = symbol || "0";
  value = value === null ? "" : value + "";

  return value.length >= size
    ? value
    : new Array(size - value.length + 1).join(symbol) + value;
}

export function completarCpfCnpj(cpfCnpj: string): string {
  const numeros = getOnlyNumbers(cpfCnpj);

  return numeros.toString().length > 11
    ? completeLeft(numeros, 14, "0")
    : completeLeft(numeros, 11, "0");
}

export function formatarCpfCnpj(value: any) {
  return value?.length > 11 ? formatarCNPJ(value) : formatarCPF(value);
}

export function formatarCPF(cpf: any) {
  cpf = cpf === null ? "" : cpf + "";
  cpf = cpf.replace(/[^\d]/g, "");
  cpf = completeLeft(cpf, 11, "0");

  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export function formatarCNPJ(cnpj: any) {
  cnpj = cnpj === null ? "" : cnpj + "";
  cnpj = cnpj.replace(/[^\d]/g, "");
  cnpj = completeLeft(cnpj, 14, "0");

  return cnpj.replace(
    /^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/,
    "$1.$2.$3/$4-$5"
  );
}

export function isCpfValido(cpfParametro: string) {
  const cpf = cpfParametro.split(".").join("").replace("-", "");

  let numeros, digitos, soma, i, resultado, digitosIguais;

  digitosIguais = 1;

  if (cpf.length < 11) {
    return false;
  }

  for (i = 0; i < cpf.length - 1; i++) {
    if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
      digitosIguais = 0;
      break;
    }
  }

  if (!digitosIguais) {
    numeros = cpf.substring(0, 9);
    digitos = cpf.substring(9);
    soma = 0;

    for (i = 10; i > 1; i--) {
      soma += Number(numeros.charAt(10 - i)) * i;
    }

    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

    if (String(resultado) !== digitos.charAt(0)) {
      return false;
    }

    numeros = cpf.substring(0, 10);
    soma = 0;

    for (i = 11; i > 1; i--) {
      soma += Number(numeros.charAt(11 - i)) * i;
    }

    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

    if (String(resultado) !== digitos.charAt(1)) {
      return false;
    }

    return true;
  } else {
    return false;
  }
}

export function getItemFromList(
  field: any,
  value: any,
  listParam: Array<any> | undefined
): any {
  if (!field || !value || !listParam) {
    return null;
  }

  const item = listParam.find((item) => item[field] === value);

  return item || null;
}

export function filterList(
  field: any,
  value: any,
  listParam: Array<any> | undefined
): Array<any> {
  if (!field || !value || !listParam) {
    return [];
  }

  return listParam.filter((item) => item[field] === value);
}

export async function base64FromPath(path: string): Promise<string> {
  const response = await fetch(path);
  const blob = await response.blob();

  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onerror = reject;
    reader.onload = () => {
      if (typeof reader.result === "string") {
        resolve(reader.result);
      } else {
        reject("method did not return a string");
      }
    };

    reader.readAsDataURL(blob);
  });
}

export async function blobFromPath(path: string): Promise<Blob> {
  const response = await fetch(path);
  const blob = await response.blob();
  return blob;
}

export const toBase64 = (file: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export function limitarString(texto: string, tamanho: number) {
  if (!texto || !tamanho) {
    return "";
  }

  return texto.substring(0, tamanho);
}

export function getTipoPessoa(cpfCnpj: string) {
  const numeros = getOnlyNumbers(cpfCnpj);
  return numeros.toString().length > 11
    ? TipoPessoa.PESSOA_JURIDICA
    : TipoPessoa.PESSOA_FISICA;
}

export function isHomolog() {
  return getVariable("REACT_APP_AMBIENTE")?.toLowerCase() === "homolog";
}

export function getBaseURL(api: ApiRoutes) {
  return getVariable(api);
}

export function getVariable(variable: string) {
  // devido a uma inconsistência no env-cmd que carrega as variaves é feito uma dupla checagem
  // com maiusculo e minusculo, pois o windows e o mac funcionam de forma diferente nesse caso
  return process.env[variable] || process.env[variable.toLocaleLowerCase()];
}

export function formatarDinheiro(value: number | bigint) {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value || 0);
}

export function formatarDataHora(value: unknown): string {
  if (!value) {
    return "";
  }

  return (
    formatarDataNoPadrao(value, "dd/MM/yyyy") +
    " às " +
    formatarDataNoPadrao(value, "HH:mm:ss")
  );
}

export function formatarData(value: unknown): string {
  if (!value) {
    return "";
  }

  return formatarDataNoPadrao(value, "dd/MM/yyyy");
}

export function formatarDataNoPadrao(value: unknown, pattern?: string): string {
  if (!value) {
    return "";
  }

  return format(new Date(value as Date), pattern || "dd/MM/yyyy");
}

export function isDataValida(date: any) {
  try {
    let dateParts = date.split("/");
    let y = dateParts[2];
    let m = dateParts[1];
    let d = dateParts[0];

    m = parseInt(m) - 1;

    let dateParser = new Date(y, m, d);

    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
      dateParser
    );

    let mo = new Intl.DateTimeFormat("en", { month: "numeric" }).format(
      dateParser
    );

    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
      dateParser
    );

    if (Number(mo) < 10) {
      mo = "0" + mo;
    }

    let test = `${da}/${mo}/${ye}`;

    if (date === test) {
      return true;
    } else {
      return false;
    }

    //
  } catch (e) {
    return false;
  }
}

export function converterStringEmDate(data: any): Date | null {
  if (!data) {
    return null;
  }

  if (data instanceof Date) {
    return data;
  } else {
    var day = null;
    var month = null;
    var year = null;

    if (data.length === 7) {
      day = "01";
    }

    var dataArray = data.split("/").reverse();

    if (dataArray.length === 1) {
      dataArray = data.split("-").reverse();
    }

    if (!day) {
      day = dataArray[2];
      month = dataArray[1] - 1;
      year = dataArray[0];
    } else {
      month = dataArray[1] - 1;
      year = dataArray[0];
    }

    return new Date(year, month, day);
  }
}

export function arrayEquals(a: any, b: any, field?: string) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) =>
      field ? val[field] === b[index][field] : val === b[index]
    )
  );
}

export async function openURL(url: string) {
  if (url) {
    await Browser.open({ url: url });
    // window.open(url, "_blank");
  }
}

export function isIos() {
  return isPlatform("ios");
}

export function isAndroid() {
  return isPlatform("android");
}

export function isIphone() {
  return isPlatform("iphone");
}

export function isMobile() {
  return isPlatform("android") || isPlatform("iphone");
}

export function isCapacitor() {
  return isPlatform("capacitor");
}

export function ordernarLista(lista: any, field: string) {
  if (!Array.isArray(lista)) {
    return [];
  }

  return lista.sort((a, b) =>
    a[field]?.toUpperCase() > b[field]?.toUpperCase() ? 1 : -1
  );
}

export function distinct(lista: any, field: string) {
  return lista.filter(
    (element: any, index: any, arr: any[]) =>
      arr.findIndex((item) => item[field] === element[field]) === index
  );
}

export function getNumeroDDD(numeroTelefoneCompleto: string | number): number {
  return Number(getOnlyNumbers(numeroTelefoneCompleto + "").substring(0, 2));
}

export function getNumeroSemDDD(
  numeroTelefoneCompleto: string | number
): number {
  return Number(getOnlyNumbers(numeroTelefoneCompleto + "").substring(2, 11));
}

export async function downloadPDF(fileName: string, data: any) {
  const blob = new Blob([data], {
    type: "application/pdf",
  });

  if (isCapacitor()) {
    let base64 = await toBase64(blob);

    const file = await Filesystem.writeFile({
      data: base64,
      directory: Directory.Cache,
      path: `${fileName} ${Date.now()}.pdf`,
    });

    FileOpener.open({ filePath: file.uri });
    // const shareResult = await Share.share({ url: file.uri });
  } else {
    const url = (
      window.URL ||
      window.webkitURL ||
      window ||
      {}
    ).createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();

    Browser.open({ url: url });
    // window.open(url, "_blank");
  }
}

export function isCnpjValido(value: string | number | number[] = "") {
  if (!value) return false;

  // Aceita receber o valor como string, número ou array com todos os dígitos
  const isString = typeof value === "string";
  const validTypes =
    isString || Number.isInteger(value) || Array.isArray(value);

  // Elimina valor de tipo inválido
  if (!validTypes) return false;

  if (isString) {
    // Teste Regex para veificar se é uma string apenas dígitos válida
    const digitsOnly = /^\d{14}$/.test(value);

    // Teste Regex para verificar se é uma string formatada válida
    const validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(value);

    // Verifica se o valor passou em ao menos 1 dos testes
    const isValid = digitsOnly || validFormat;

    // Se o formato não é válido, retorna inválido
    if (!isValid) return false;
  }

  // Elimina tudo que não é dígito
  const numbers = matchNumbers(value);

  // Valida a quantidade de dígitos
  if (numbers.length !== 14) return false;

  // Elimina inválidos com todos os dígitos iguais
  let digitosIguais = true;

  for (let i = 0; i < numbers.length - 1; i++) {
    if (numbers[i] !== numbers[i + 1]) {
      digitosIguais = false;
      break;
    }
  }

  if (digitosIguais) return false;

  // Separa os 2 últimos dígitos verificadores
  const digits = numbers.slice(12);

  // Valida o primeiro dígito verificador
  const digit0 = validCalc(12, numbers);
  if (digit0 !== digits[0]) return false;

  // Valida o segundo dígito verificador
  const digit1 = validCalc(13, numbers);
  return digit1 === digits[1];
}

// Cálculo validador
function validCalc(x: number, numbers: number[]) {
  const slice = numbers.slice(0, x);
  let factor = x - 7;
  let sum = 0;

  for (let i = x; i >= 1; i--) {
    const n = slice[x - i];
    sum += n * factor--;
    if (factor < 2) factor = 9;
  }

  const result = 11 - (sum % 11);

  return result > 9 ? 0 : result;
}

// Elimina tudo que não é dígito
function matchNumbers(value: string | number | number[] = "") {
  const match = value.toString().match(/\d/g);
  return Array.isArray(match) ? match.map(Number) : [];
}

export function isApenasNumeros(value: string): boolean {
  const regex = /^[0-9\b]+$/;
  let validNumber = false;

  if (regex.test(value)) {
    validNumber = true;
  }

  return validNumber;
}

export function isTamanhoMenorIgual(value: string, maxLength: number): boolean {
  let validSize = true;

  if (maxLength) {
    if (value.length <= maxLength) {
      validSize = true;
    } else {
      validSize = false;
    }
  }

  return validSize;
}

export function colocarDigitoNaConta(value: string | number): string {
  let conta = value + "";

  return (
    conta.slice(0, conta.length - 1) +
    "-" +
    conta.slice(conta.length - 1, conta.length)
  );
}
