import { Calendar, CalendarProps } from "primereact/calendar";
import { classNames } from "primereact/utils";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import usePrevious from "../../hooks/usePrevious";

export interface InputDateProps extends CalendarProps {
  name: string;
  label?: string;
  readonly?: boolean;
}

const InputDate: React.FC<InputDateProps> = (props: InputDateProps) => {
  const { name, label, readonly, ...propsOriginal } = props;
  const _backgroundColor = "rgb(249, 250, 251, 0.95)";

  const {
    register,
    resetField,
    formState: { isSubmitting, errors },
  } = useFormContext();

  const previousValue = usePrevious(propsOriginal.value);

  useEffect(() => {
    if (previousValue !== propsOriginal.value) {
      resetField(name, { defaultValue: propsOriginal.value });
    }
  }, [propsOriginal.value]);

  return (
    <div className={classNames({ "pointer-events-none opacity-70": readonly })}>
      <span className="p-float-label">
        <Calendar
          {...propsOriginal}
          style={{ backgroundColor: _backgroundColor, height: 56 }}
          className={classNames("w-full", {
            "p-invalid": !!errors[name as string],
          })}
          {...register(name)}
          disabled={propsOriginal.disabled || isSubmitting}
          value={propsOriginal.value}
          onChange={propsOriginal.onChange}
          onBlur={propsOriginal.onBlur}
        ></Calendar>
        <label
          htmlFor="inputdate"
          style={{ backgroundColor: _backgroundColor }}
        >
          <div
            className={classNames({
              "text-red-600": !!errors[name as string],
            })}
          >
            {label ? label : name}
          </div>
        </label>
      </span>

      <div
        className="py-1 pl-3 text-xs text-red-600"
        style={{ backgroundColor: _backgroundColor }}
        hidden={!errors[name]}
      >
        {errors[name as string]?.message?.toString()}
      </div>
    </div>
  );
};

export default InputDate;
