import React from "react";
import { IonIcon, IonSelect, IonSelectOption } from "@ionic/react";
import { useContaUsuario } from "../contexts/contaUsuarioContext";
import { personCircleOutline } from "ionicons/icons";
import { Conta } from "../models/types";

const CardConta: React.FC = () => {
  const { contasUsuario, contaSelecionada, alterarContaSelecionada } =
    useContaUsuario();

  function alterarConta(conta: Conta) {
    if (
      conta.numeroContaComDigito &&
      contaSelecionada?.numeroContaComDigito !== conta.numeroContaComDigito
    ) {
      alterarContaSelecionada(conta);
    }
  }

  return (
    <div className="bg-white rounded-3xl shadow-md px-4 w-full">
      <div className="flex flex-row items-center justify-start">
        <div className="flex items-center">
          <IonIcon
            className="text-3xl opacity-80"
            color="primary"
            icon={personCircleOutline}
          />
        </div>
        <IonSelect
          placeholder="Conta"
          interface="alert"
          cancelText="Cancelar"
          interfaceOptions={{ subHeader: "Conta(s)", translucent: true }}
          onIonChange={(e) => alterarConta(e.detail.value)}
          value={contaSelecionada}
          compareWith={(a: Conta, b: Conta) =>
            a?.numeroContaComDigito === b?.numeroContaComDigito
          }
          selectedText={
            contaSelecionada?.numeroContaComDigito
              ? "Conta: " + contaSelecionada?.numeroContaComDigito
              : "Selecione uma conta"
          }
        >
          {contasUsuario?.map((conta) => {
            return (
              <IonSelectOption value={conta} key={conta.numeroContaComDigito}>
                {conta.numeroContaComDigito}
              </IonSelectOption>
            );
          })}
        </IonSelect>
      </div>
    </div>
  );
};

export default CardConta;
