import { useIonLoading } from "@ionic/react";
import { z } from "zod";
import { useAxiosClient } from "../../hooks/useAxiosClient";
import { ApiRoutes, Mensagens } from "../../models/types";
import { useHistory } from "react-router";

import React from "react";
import ReactForm from "../../components/form/ReactForm";
import Page from "../../components/Page";
import CardCapturarFoto from "../../components/form/CardCapturarFoto";
import SubmitButton from "../../components/form/SubmitButton";
import useNotification from "../../hooks/useNotification";

const FotoPessoal: React.FC = () => {
  //hooks
  const axiosClient = useAxiosClient(ApiRoutes.ESI_USUARIO_AGENCIA_VIRTUAL);
  const navigate = useHistory<any>();

  const tipoDocumento = navigate.location.state?.tipoDocumento;

  //alerts e loadings
  const [present, dismiss] = useIonLoading();
  const notification = useNotification();

  //Zod schema
  const formSchema = z.object({
    imagemSelfie: z.string().min(1, "Necessário adicionar a imagem da selfie."),
  });

  type formType = z.infer<typeof formSchema>;

  async function enviarDados(dados: formType) {
    //Loading
    await present({ message: "Enviando foto..." });

    try {
      const selfieData = {
        arquivoDocumentoCodificado: dados.imagemSelfie
          .replace(/^data:image\/[a-z]+;base64,/, "")
          .trim(),
        ladoDocumento: "FRENTE",
        tipoDocumentoIdentificacao: "SELFIE",
        extensaoArquivoDocumentoCodificado: "jpg",
      };

      await axiosClient.post(
        "/atendimentos/salvarDocumento",
        JSON.stringify(selfieData)
      );

      navigate.push({
        pathname: "/dadosPessoais",
        state: {
          tipoDocumento: tipoDocumento,
        },
      });

      dismiss();
    } catch (e: any) {
      dismiss();

      await notification.showError({ exception: e });
    }
  }

  return (
    <Page title="Recadastro" showCardConta={false}>
      <div className="card bg-opacity-70">
        <div className="p-2">
          <div className="font-bold text-lg text-primary">Sua foto</div>

          <ReactForm
            schema={formSchema}
            onSubmit={(e) => enviarDados(e as formType)}
            onError={(error, e) => {}}
          >
            <div className="flex flex-col space-y-4">
              <div className="font-light text-sm">
                Não se preocupe com a foto perfeita. Seguem algumas dicas para
                te ajudar:
              </div>

              <div className="pl-4 font-light text-sm">
                <ol className="list-disc space-y-2">
                  <li>Não utilize acessórios como boné ou óculos.</li>
                  <li>Centralize seu rosto na tela do celular.</li>
                </ol>
              </div>

              <CardCapturarFoto name="imagemSelfie" title="Selfie" />
              <SubmitButton label="Prosseguir" />
            </div>
          </ReactForm>
        </div>
      </div>
    </Page>
  );
};

export default FotoPessoal;
