import {
  formatarDataHora,
  getBaseURL,
  getOnlyNumbers,
  isApenasNumeros,
} from "../utils/functions";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
  useIonLoading,
} from "@ionic/react";
import { z } from "zod";
import { ApiRoutes, Mensagens } from "../models/types";
import { useCallback, useEffect, useState } from "react";
import { checkmarkCircleOutline } from "ionicons/icons";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Page from "../components/Page";
import useNotification from "../hooks/useNotification";
import ReactForm from "../components/form/ReactForm";
import InputText from "../components/form/InputText";
import SelectField from "../components/form/SelectField";
import SubmitButton from "../components/form/SubmitButton";
import axios from "axios";

export enum OpcoesValidacaoEnum {
  NUMERO_CONTA = "C",
  ORGAO_AGRUPADOR = "O",
}

export default function ValidarCertidaoNegativa() {
  const [present, dismiss] = useIonLoading();
  const [tipoValidacao, setTipoValidacao] = useState<string>();
  const [data, setData] = useState<any>();
  const [isOpen, setIsOpen] = useState(false);
  const [orgaoAgrupador, setOrgaoAgrupador] = useState("");
  const [numeroConta, setNumeroConta] = useState("");
  const [digitoConta, setDigitoConta] = useState("");

  const notification = useNotification();
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    setNumeroConta("");
    setDigitoConta("");
    setOrgaoAgrupador("");
  }, [tipoValidacao]);

  const opcoesValidacao = [
    {
      value: OpcoesValidacaoEnum.NUMERO_CONTA,
      label: "Usando o número da conta",
    },
    {
      value: OpcoesValidacaoEnum.ORGAO_AGRUPADOR,
      label: "Usando o órgão agrupador",
    },
  ];

  const isTipoValidacaoConta = (() => {
    return tipoValidacao === OpcoesValidacaoEnum.NUMERO_CONTA;
  })();

  const formSchema = z
    .object({
      numeroCertidao: z
        .string()
        .min(1, "Informe o número da certidão (são 9 dígitos).")
        .transform((val) => getOnlyNumbers(val)),
      opcaoValidacao: z
        .any()
        .refine((input) => input, "Selecione uma opção de validação.")
        .transform((input) => input.value),
      orgaoAgrupador: z.string(),
      numeroConta: z.string(),
      digitoConta: z.string(),
    })
    .refine((data) => !(isTipoValidacaoConta && !data.numeroConta), {
      message: "Informe o número da conta.",
      path: ["numeroConta"],
    })
    .refine((data) => !(isTipoValidacaoConta && !data.digitoConta), {
      message: "Informe o dígito da conta.",
      path: ["digitoConta"],
    })
    .refine((data) => !(!isTipoValidacaoConta && !data.orgaoAgrupador), {
      message: "Informe o órgão agrupador.",
      path: ["orgaoAgrupador"],
    });

  type formType = z.infer<typeof formSchema>;

  async function enviarDados(dados: formType, resetFields: any) {
    let body = {
      numeroCertidaoNegativaComDigito: dados.numeroCertidao,
      tipoConsulta: dados.opcaoValidacao,
    };

    body = isTipoValidacaoConta
      ? {
          ...body,
          ...{
            numeroContaComDigito: dados.numeroConta + "-" + dados.digitoConta,
          },
        }
      : { ...body, ...{ orgaoAgrupador: dados.orgaoAgrupador } };

    await present({ message: Mensagens.CONSULTANDO });

    try {
      const url =
        getBaseURL(ApiRoutes.ECO_ARRECADACAO_EXTERNO) +
        "/certidoes-negativas/validar-certidao";

      const token = await getReCaptchaToken();

      const params = new URLSearchParams({
        "g-recaptcha-response": token as string,
      });

      const res = await axios.post(url, JSON.stringify(body), {
        params: params,
        headers: { "content-type": "application/json" },
      });

      setData({ ...res.data, ...dados });
      setIsOpen(true);

      dismiss();
    } catch (e: any) {
      dismiss();

      let msg = e?.response?.data?.detalhe || Mensagens.ERRO_INESPERADO;

      notification.showAlert({ message: msg });
    }
  }

  const getReCaptchaToken = useCallback(async () => {
    if (!executeRecaptcha) {
      notification.showWarning({ message: Mensagens.RECAPTCHA_INDISPONIVEL });
      return null;
    }

    const token = await executeRecaptcha();
    return token;
  }, [executeRecaptcha]);

  const handleChangeOrgaoAgrupador = (e: any) => {
    if (e.target.value === "" || isApenasNumeros(e.target.value)) {
      setOrgaoAgrupador(e.target.value);
    }
  };

  const handleChangeNumeroConta = (e: any) => {
    if (e.target.value === "" || isApenasNumeros(e.target.value)) {
      setNumeroConta(e.target.value);
    }
  };

  const handleChangeDigitoConta = (e: any) => {
    if (e.target.value === "" || isApenasNumeros(e.target.value)) {
      setDigitoConta(e.target.value);
    }
  };

  return (
    <Page title="Validar certidão negativa" reCaptcha showCardConta={false}>
      <div className="flex flex-col space-y-4">
        <div className="card bg-opacity-70">
          <div className="card-title">Dados para validação</div>

          <div className="items-center">
            <ReactForm
              schema={formSchema}
              onError={(error, e) => {}}
              onSubmit={(data, resetFields) =>
                enviarDados(data as formType, resetFields)
              }
            >
              <div className="flex flex-col space-y-4">
                <InputText
                  name="numeroCertidao"
                  label="Número da certidão"
                  mask="999.999.999"
                />

                <SelectField
                  name="opcaoValidacao"
                  placeHolder="Opção de validação"
                  items={opcoesValidacao}
                  defaultValuePosition={0}
                  onSelect={(item) => {
                    setTipoValidacao(item?.value as string);
                  }}
                />

                <div hidden={isTipoValidacaoConta}>
                  <InputText
                    name="orgaoAgrupador"
                    label={"Órgão agrupador"}
                    onInput={(e) => handleChangeOrgaoAgrupador(e)}
                    value={orgaoAgrupador}
                    inputProps={{ maxLength: 4 }}
                  />
                </div>

                <div hidden={!isTipoValidacaoConta}>
                  <div className="grid grid-cols-2 gap-4 xs:grid-cols-1">
                    <InputText
                      name="numeroConta"
                      label="Número da conta"
                      onInput={(e) => handleChangeNumeroConta(e)}
                      value={numeroConta}
                      inputProps={{ maxLength: 8 }}
                    />

                    <InputText
                      name="digitoConta"
                      label="Dígito da conta"
                      onInput={(e) => handleChangeDigitoConta(e)}
                      value={digitoConta}
                      inputProps={{ maxLength: 1 }}
                    />
                  </div>
                </div>

                <SubmitButton label="Consultar" />
              </div>
            </ReactForm>
          </div>
        </div>

        <div>
          <IonModal isOpen={isOpen} onWillDismiss={() => {}}>
            <IonHeader>
              <IonToolbar>
                <IonTitle className="text-secondary">Saneago</IonTitle>
                <IonButtons slot="end">
                  <IonButton
                    onClick={() => setIsOpen(false)}
                    className="font-bold text-primary"
                  >
                    Fechar
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <div className="flex flex-col p-4 text-slate-700">
                <div>
                  Certidão de número
                  <span className="font-medium text-secondary">
                    {" "}
                    {data?.numeroCertidao}{" "}
                  </span>
                  validada com sucesso.
                </div>

                <div className="pt-4 text-secondary">
                  Está certidão foi emitida dia:
                </div>
                <div className="">
                  {formatarDataHora(data?.dataHoraEmissao)}
                </div>

                <div className="pt-4 text-secondary">
                  Ela contém a seguinte observação:
                </div>
                <div className="">{data?.descricaoObservacaoCertidao}</div>
              </div>

              <div className="pt-4 text-center text-green-600">
                <IonIcon
                  className="text-9xl"
                  ios={checkmarkCircleOutline}
                  md={checkmarkCircleOutline}
                />
              </div>
            </IonContent>
          </IonModal>
        </div>
      </div>
    </Page>
  );
}
