import {
  completarCpfCnpj,
  getBaseURL,
  isCnpjValido,
  isCpfValido,
} from "../../utils/functions";
import {
  ApiRoutes,
  Mensagens,
  TIPOS_PESSOA,
  TipoPessoa,
} from "../../models/types";
import { useHistory } from "react-router";
import { z } from "zod";
import { SHA1 } from "crypto-js";
import { useIonLoading } from "@ionic/react";
import React, { useState } from "react";
import InputText from "../../components/form/InputText";
import ReactForm from "../../components/form/ReactForm";
import SubmitButton from "../../components/form/SubmitButton";
import Page from "../../components/Page";
import useNotification from "../../hooks/useNotification";
import axios from "axios";
import SelectField from "../../components/form/SelectField";
import ButtonBack from "../../components/ButtonBack";

const Cadastro: React.FC = () => {
  const [present, dismiss] = useIonLoading();
  const [tipoCliente, setTipoCliente] = useState<string>();

  const navigate = useHistory();
  const notification = useNotification();

  const isTipoClientePessoaFisica = (() => {
    return tipoCliente === TipoPessoa.PESSOA_FISICA;
  })();

  const formSchema = z
    .object({
      tipoCliente: z.any().transform((input) => input?.value),
      cpfCnpj: z.string(),
      nome: z.string(),
      email: z.string().email("Informe um e-mail válido."),
      confirmarEmail: z.string().email("Informe um e-mail válido."),
      senha: z
        .string()
        .min(
          8,
          "Senha deve possuir no mínimo 8 caracteres com pelo menos uma letra maiúscula e um número."
        )
        .regex(
          new RegExp(/[A-Z]/),
          "Senha deve possuir no mínimo uma letra maiúscula."
        )
        .regex(
          new RegExp(/[a-z]/),
          "Senha deve possuir no mínimo uma letra minúscula."
        )
        .regex(new RegExp(/[0-9]/), "Senha deve possuir no mínimo um número.")
        .transform((val) => SHA1(val).toString()),
      confirmarSenha: z
        .string()
        .min(8, "As duas senhas informadas devem ser iguais.")
        .transform((val) => SHA1(val).toString()),
    })
    .refine((data) => data.senha === data.confirmarSenha, {
      message: "As duas senhas informadas devem ser iguais.",
      path: ["confirmarSenha"],
    })
    .refine((data) => data.email === data.confirmarEmail, {
      message: "Os dois e-mails informados devem ser iguais.",
      path: ["confirmarEmail"],
    })
    .refine(
      (data) => data.nome,
      (data) => ({
        message: isTipoClientePessoaFisica
          ? "Informe o nome completo."
          : "Informe a razão social.",
        path: ["nome"],
      })
    )
    .refine(
      (data) =>
        isTipoClientePessoaFisica
          ? data.nome && !/[0-9]/.test(data.nome)
          : true,
      (data) => ({
        message: "Nome não deve possuir números.",
        path: ["nome"],
      })
    )
    .refine(
      (data) => data.cpfCnpj,
      (data) => ({
        message: isTipoClientePessoaFisica
          ? "Informe um CPF."
          : "Informe um CNPJ.",
        path: ["cpfCnpj"],
      })
    )
    .refine(
      (data) =>
        isTipoClientePessoaFisica
          ? isCpfValido(data.cpfCnpj)
          : isCnpjValido(data.cpfCnpj),
      (data) => ({
        message: isTipoClientePessoaFisica ? "CPF inválido." : "CNPJ inválido.",
        path: ["cpfCnpj"],
      })
    );

  type formType = z.infer<typeof formSchema>;

  async function enviarDados(usuario: formType) {
    const user = {
      ...usuario,
      nomePessoa: usuario.nome,
      cpfCnpj: completarCpfCnpj(usuario.cpfCnpj),
    };

    await present({ message: Mensagens.VERIFICANDO_DADOS });

    try {
      const url =
        getBaseURL(ApiRoutes.ESI_USUARIO_AGENCIA_VIRTUAL) +
        "/atendimentos/usuarios";

      await axios.post(url, JSON.stringify(user), {
        headers: {
          "content-type": "application/json",
        },
      });

      navigate.push({ pathname: "/confirmarEmail", state: { data: user } });
    } catch (e: any) {
      if (e.response.status === 403) {
        notification.showAlert({
          message: Mensagens.CLIENTE_JA_CADASTRADO,
        });

        return;
      }

      await notification.showError({
        message: Mensagens.ERRO_INESPERADO_CADASTRO,
        exception: e,
      });
    } finally {
      dismiss();
    }
  }

  return (
    <Page title="Cadastro" showCardConta={false}>
      <div className="card bg-opacity-70">
        <div className="p-2">
          <div className="font-bold text-lg text-primary">Bem-vindo(a)</div>
          <div className="font-light text-sm pb-6">
            Para ter o acesso digital aos serviços da SANEAGO, precisamos de
            informações iniciais sobre você.
          </div>
          <ReactForm
            schema={formSchema}
            onSubmit={(e) => enviarDados(e as formType)}
            onError={(error, e) => {}}
          >
            <div className="flex flex-col space-y-4">
              <SelectField
                name="tipoCliente"
                placeHolder="Tipo de cliente"
                defaultValuePosition={0}
                onSelect={(item) => {
                  setTipoCliente(item?.value as string);
                }}
                items={TIPOS_PESSOA}
              />

              <InputText
                name="cpfCnpj"
                label={isTipoClientePessoaFisica ? "CPF" : "CNPJ"}
                mask={
                  isTipoClientePessoaFisica
                    ? "999.999.999-99"
                    : "99.999.999/9999-99"
                }
              />
              <InputText
                name="nome"
                label={
                  isTipoClientePessoaFisica ? "Nome completo" : "Razão social"
                }
                inputProps={{ maxLength: 50 }}
              />
              <InputText
                name="email"
                label="E-mail"
                inputProps={{ maxLength: 60 }}
              />
              <InputText
                name="confirmarEmail"
                label="Confirmar e-mail"
                inputProps={{ maxLength: 60 }}
              />
              <InputText
                name="senha"
                label="Senha"
                type={"password"}
                inputProps={{ maxLength: 12 }}
                autoComplete="off"
              />
              <InputText
                name="confirmarSenha"
                label="Confirmar senha"
                type={"password"}
                inputProps={{ maxLength: 12 }}
                autoComplete="off"
              />

              <div className="grid grid-cols-2 gap-2 xs:grid-cols-1">
                <SubmitButton label="Enviar" />
                <ButtonBack />
              </div>
            </div>
          </ReactForm>
        </div>
      </div>
    </Page>
  );
};

export default Cadastro;
