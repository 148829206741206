import { z } from "zod";
import { IonLabel, useIonLoading } from "@ionic/react";
import { useHistory } from "react-router";
import { useAxiosClient } from "../../hooks/useAxiosClient";
import { ApiRoutes, Mensagens } from "../../models/types";

import InputText from "../../components/form/InputText";
import ReactForm from "../../components/form/ReactForm";
import SubmitButton from "../../components/form/SubmitButton";
import Page from "../../components/Page";
import useNotification from "../../hooks/useNotification";

export default function RecuperarSenhaConfirmaEmail() {
  const [present, dismiss] = useIonLoading();

  const navigate = useHistory<any>();
  const notification = useNotification();
  const axiosClient = useAxiosClient(ApiRoutes.ESI_USUARIO_AGENCIA_VIRTUAL);

  //dados vindos da navegacao recuperar senha
  const usuario: any = navigate.location.state?.data;

  const formSchema = z.object({
    codigoValidacao: z
      .string()
      .min(4, Mensagens.INFORME_CODIGO)
      .max(4, Mensagens.INFORME_CODIGO),
  });

  //validar código enviado por email
  async function validarCodigoEmail(codigo: any) {
    try {
      await present({ message: Mensagens.VERIFICANDO_DADOS });

      const res = await axiosClient.get(
        `/atendimentos/usuarios/${usuario.tipoCliente}/${usuario.cpfCnpj}/validar/${codigo}`
      );

      if (res.data) {
        navigate.push({
          pathname: "/novaSenha",
          state: { data: { ...usuario, codigo: codigo } },
        });
      } else {
        notification.showAlert({
          message: Mensagens.CODIGO_INCORRETO,
        });
      }

      dismiss();
    } catch (e: any) {
      dismiss();

      await notification.showError({ exception: e });
    }
  }

  //se por ventura não obteve os dados da navegação anterior então retorna erro
  if (!usuario) {
    return (
      <Page title="Recuperar senha" showCardConta={false} buttonType="back">
        <div className="flex flex-col justify-center items-center p-4">
          <IonLabel>{Mensagens.ERRO_INESPERADO}</IonLabel>
        </div>
      </Page>
    );
  }

  return (
    <Page title="Recuperar senha" showCardConta={false} buttonType="back">
      <div className="card">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 p-4 lg:p-8">
          <div>
            <ReactForm
              schema={formSchema}
              onError={(error, e) => {}}
              onSubmit={(e) => validarCodigoEmail(e.codigoValidacao)}
            >
              <div className="flex flex-col justify-center items-center space-y-4">
                <div className="text-xl text-center font-bold text-primary">
                  Confirmação
                </div>
                <div className="w-full sm:w-6/12 md:w-6/12 lg:w-full xl:w-10/12 2xl:w-9/12">
                  <InputText
                    name="codigoValidacao"
                    label="Digite o código com 4 dígitos aqui"
                    inputProps={{ maxLength: 4 }}
                  />
                </div>

                <SubmitButton label="Enviar" />
              </div>
            </ReactForm>
          </div>

          <div className="text-center space-y-4">
            <div className="text-2xl font-bold text-primary">
              Estamos quase lá
            </div>
            <div className="text-lg text-slate-500">
              Por motivos de segurança, enviamos um código de verificação para
              seu e-mail:
            </div>
            <div className="font-bold text-slate-500">{usuario.email}</div>
          </div>
        </div>
      </div>
    </Page>
  );
}
