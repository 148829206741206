import { useEffect, useRef } from "react";

// hook para informar o estado anterior de uma referencia

function usePrevious(value: any) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export default usePrevious;
