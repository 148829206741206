import { getOnlyNumbers, isApenasNumeros } from "../../utils/functions";
import { z } from "zod";
import { useAuth } from "../../contexts/authContext";
import { useIonLoading } from "@ionic/react";
import { useAxiosClient } from "../../hooks/useAxiosClient";
import { ApiRoutes, Mensagens } from "../../models/types";
import React, { useState } from "react";
import InputText from "../../components/form/InputText";
import ReactForm from "../../components/form/ReactForm";
import SubmitButton from "../../components/form/SubmitButton";
import Page from "../../components/Page";
import CardCapturarFoto from "../../components/form/CardCapturarFoto";
import useNotification from "../../hooks/useNotification";
import CardAnexarArquivo from "../../components/form/CardAnexarArquivo";
import ButtonBack from "../../components/ButtonBack";
import InputRadioGroupComponent from "../../components/form/InputRadioGroup";

const DadosEmpresa: React.FC = () => {
  const { usuario, logout } = useAuth();
  const [present, dismiss] = useIonLoading();
  const [inscricaoEstadual, setInscricaoEstadual] = useState("");
  const [usaFotos, setUsaFotos] = useState<boolean>(true);

  const notification = useNotification();
  const axiosClient = useAxiosClient(ApiRoutes.ESI_USUARIO_AGENCIA_VIRTUAL);

  const formSchema = z
    .object({
      nomeFantasia: z.string().min(1, "Informe o nome fantasia."),
      inscricaoEstadual: z.string(),
      numeroTelefoneCelular: z
        .string()
        .min(1, "Informe um número de telefone celular.")
        .transform((val) => Number(getOnlyNumbers(val))),
      imagemDocumento: z.string(),
      documentoPdf: z.string(),
    })
    .refine(
      (data) => !(data.inscricaoEstadual && data.inscricaoEstadual.length < 9),
      {
        message: "Informe o número completo da inscrição estadual.",
        path: ["inscricaoEstadual"],
      }
    );

  type formType = z.infer<typeof formSchema>;

  async function enviarDados(dados: formType) {
    const dddTelefone = String(dados.numeroTelefoneCelular).substring(0, 2);
    const numeroTelefone = String(dados.numeroTelefoneCelular).substring(2, 11);

    let body = {
      nomeFantasiaCliente: dados.nomeFantasia,
      numeroTelefoneCelular: numeroTelefone,
      dddTelefoneCelular: dddTelefone,
    };

    if (dados.inscricaoEstadual) {
      body = {
        ...body,
        ...{ numeroInscricaoEstadual: dados.inscricaoEstadual },
      };
    }

    if (usaFotos && !dados.imagemDocumento) {
      notification.showAlert({
        message: "É necessário adicionar uma foto do documento.",
      });
      return;
    }

    if (!usaFotos && !dados.documentoPdf) {
      notification.showAlert({
        message: "É necessário adicionar um arquivo PDF do documento.",
      });
      return;
    }

    try {
      await present({ message: Mensagens.SALVANDO });

      if (dados.imagemDocumento) {
        await axiosClient.post(
          "/atendimentos/salvarDocumento",
          JSON.stringify({
            arquivoDocumentoCodificado: dados.imagemDocumento
              .replace(/^data:image\/[a-z]+;base64,/, "")
              .trim(),
            ladoDocumento: "FRENTE",
            tipoDocumentoIdentificacao: "CNPJ",
            extensaoArquivoDocumentoCodificado: "jpg",
          })
        );
        //
      } else if (dados.documentoPdf) {
        //
        await axiosClient.post(
          "/atendimentos/salvarDocumento",
          JSON.stringify({
            arquivoDocumentoCodificado: dados.documentoPdf
              .replace(/^data:application\/[a-z]+;base64,/, "")
              .trim(),
            ladoDocumento: "FRENTE",
            tipoDocumentoIdentificacao: "CNPJ",
            extensaoArquivoDocumentoCodificado: "pdf",
          })
        );
      }

      await axiosClient.post(
        "/atendimentos/conclusaoEmpresa",
        JSON.stringify(body)
      );

      notification.showSuccess({
        message: Mensagens.CADASTRO_FINALIZADO_SUCESSO,
      });

      // usuario loga novamente para verificar a efetivação do cadastro
      logout();

      dismiss();
    } catch (e: any) {
      dismiss();

      let msg: Mensagens | string = Mensagens.ERRO_INESPERADO_CONCLUIR_CADASTRO;

      if (e?.response?.status === 422) {
        msg = Mensagens.NECESSARIO_VINCULO_SANEAGO;
      }

      if (e?.response?.data?.detalhes) {
        const data = e.response.data;

        data.detalhes.forEach((item: string) => {
          if (item) {
            // msg = msg + "<br/> <br/>" + item.substring(item.indexOf(":") + 2);
            msg = msg + "<br/> <br/>" + item;
          }
        });
      }

      notification.showAlert({ message: msg });
    }
  }

  const handleChangeInscricaoEstadual = (e: any) => {
    if (e.target.value === "" || isApenasNumeros(e.target.value)) {
      setInscricaoEstadual(e.target.value);
    }
  };

  return (
    <Page title="Recadastro" showCardConta={false}>
      <div className="card bg-opacity-70">
        <div className="p-2">
          <div className="font-bold text-lg text-primary">
            Dados pessoa jurídica
          </div>
          <div className="font-light text-sm pb-6">
            Preencha seus dados de acordo com o documento enviado. Confira
            atentamente as informações.
          </div>

          <ReactForm
            schema={formSchema}
            onSubmit={(e) => enviarDados(e as formType)}
            onError={(error, e) => {}}
          >
            <div className="flex flex-col space-y-4">
              <InputText
                name="razaoSocial"
                label="Razão social"
                defaultValue={usuario?.nome}
                inputProps={{ maxLength: 70 }}
                disabled={true}
              />

              <InputText
                name="nomeFantasia"
                label="Nome fansatia"
                inputProps={{ maxLength: 50 }}
              />

              <InputText
                name="inscricaoEstadual"
                label="Inscrição estadual"
                onInput={(e) => handleChangeInscricaoEstadual(e)}
                value={inscricaoEstadual}
                inputProps={{ maxLength: 11 }}
              />

              <InputText
                name="numeroTelefoneCelular"
                label="Celular"
                mask="99 99999-9999"
              />

              <div className="font-light text-sm">
                Você pode tira uma foto do do documento de identificação da
                pessoa júridica ou anexar um arquivo PDF. Qual a sua escolha?
              </div>

              <InputRadioGroupComponent
                defaultValuePosition={0}
                name="preferenciaAnexo"
                size="small"
                items={[
                  {
                    value: "fotos",
                    label: "Tirar foto do documento",
                  },
                  {
                    value: "pdf",
                    label: "Anexar PDF do documento",
                  },
                ]}
                onSelectItem={(value) => {
                  setUsaFotos(value?.value === "fotos");
                }}
              />

              <div className="font-light text-sm" hidden={!usaFotos}>
                Seguem algumas observações:
              </div>

              <div className="pl-4 font-light text-sm" hidden={!usaFotos}>
                <ol className="list-disc space-y-2">
                  <li>Centralize o documento.</li>
                  <li>Seu dedo não pode sair na foto.</li>
                </ol>
              </div>

              <CardCapturarFoto
                name="imagemDocumento"
                title="Foto documento"
                hidden={!usaFotos}
              />

              <CardAnexarArquivo
                name="documentoPdf"
                title="PDF documento"
                maxSize={5}
                types={["application/pdf"]}
                hidden={usaFotos}
              />

              <div className="grid grid-cols-2 gap-2 xs:grid-cols-1">
                <SubmitButton label="Prosseguir" />
                <ButtonBack label="Cancelar" />
              </div>
            </div>
          </ReactForm>
        </div>
      </div>
    </Page>
  );
};

export default DadosEmpresa;
