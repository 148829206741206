import {
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonMenu,
  IonNote,
  IonToolbar,
} from "@ionic/react";
import { MenuItemPage } from "../models/types";
import { useAuth } from "../contexts/authContext";
import { getVariable, isAndroid, isHomolog, isIos } from "../utils/functions";
import { useContaUsuario } from "../contexts/contaUsuarioContext";
import MenuItem from "./MenuItem";
import "./Menu.css";

const Menu: React.FC = () => {
  const { isLogged, usuario, logout } = useAuth();
  const { contaSelecionada } = useContaUsuario();

  const isCadastroCompleto = usuario?.ativo && usuario?.clienteSaneago;

  const version = isIos()
    ? getVariable("REACT_APP_VERSION_NAME_IOS")
    : isAndroid()
    ? getVariable("REACT_APP_VERSION_NAME_ANDROID")
    : getVariable("REACT_APP_VERSION_NAME");

  const pagesHome: Array<MenuItemPage> = [
    {
      title: "Home",
      url: "/home",
      icon: "assets/icon/home.svg",
    },
    {
      title: "Login",
      url: "/login",
      icon: "assets/icon/login.svg",
      remover: isLogged,
    },
    {
      title: "Cadastro",
      url: "/cadastro",
      icon: "assets/icon/cadastro.svg",
      remover: isLogged,
    },
  ];

  const pagesFaturas: Array<MenuItemPage> = [
    {
      title: "Emissão de 2ª via da fatura",
      url: isCadastroCompleto ? "/segundaVia" : "/segundaViaSimplificada",
      icon: "assets/icon/segunda_via.svg",
    },
    {
      title: "Histórico de consumo faturado",
      url: "/historico",
      icon: "assets/icon/historico_consumo.svg",
      desabilitar: !isCadastroCompleto,
    },
    {
      title: "Últimas faturas",
      url: "/ultimasFaturas",
      icon: "assets/icon/ultimas_faturas.svg",
      desabilitar: !isCadastroCompleto,
    },
    {
      title: "Consumo e faturamento individualizados",
      url: "/individualizados",
      icon: "assets/icon/layers-outline.svg",
      desabilitar: !isCadastroCompleto,
      remover: !(
        !contaSelecionada ||
        (contaSelecionada && contaSelecionada?.isContaMacro)
      ),
    },
    {
      title: "Fatura digital",
      url: "/faturaDigital",
      icon: "assets/icon/fatura-digital.svg",
      desabilitar: !isCadastroCompleto,
    },
    {
      title: "Certidão negativa de débitos",
      url: "/certidaoNegativaDebitos",
      icon: "assets/icon/certidao_negativa.svg",
      desabilitar: !isCadastroCompleto,
    },
    {
      title: "Validar certidão negativa",
      url: "/validarCertidaoNegativa",
      icon: "assets/icon/checkmark-done-outline.svg",
    },
    {
      title: "Fatura analítica de órgão público",
      url: "/faturaOrgaoPublico",
      icon: "assets/icon/briefcase-outline.svg",
    },
  ];

  const servicosDiversos: Array<MenuItemPage> = [
    {
      title: "Protocolos",
      url: "/protocolos",
      icon: "assets/icon/protocolos.svg",
      desabilitar: !isLogged,
    },
    {
      title: "Comunicar falta de água",
      url: "/comunicarFaltaAgua",
      icon: "assets/icon/falta_agua.svg",
      desabilitar: !isCadastroCompleto,
    },
    {
      title: "Reclamação sobre qualidade da água",
      url: isCadastroCompleto ? "/reclamacaoAgua" : "/reclamacaoAguaNoEndereco",
      icon: "assets/icon/qualidade_agua.svg",
      desabilitar: !isLogged,
    },
    {
      title: "Comunicar vazamento",
      url: isCadastroCompleto ? "/vazamento" : "/vazamentoNoEndereco",
      icon: "assets/icon/vazamento.svg",
      desabilitar: !isLogged,
    },
    {
      title: "Desobstrução de esgoto",
      url: isCadastroCompleto
        ? "/desobstrucaoEsgoto"
        : "/desobstrucaoEsgotoNoEndereco",
      icon: "assets/icon/desobstrucao_esgoto.svg",
      desabilitar: !isLogged,
    },
    {
      title: "Laudo de aferição",
      url: "/laudoAfericao",
      icon: "assets/icon/documentos.svg",
      desabilitar: !isCadastroCompleto,
    },
    // {
    //   title: "Solicitar religação de água",
    //   url: "/religacaoAgua",
    //   icon: "assets/icon/water-outline.svg",
    //   desabilitar: !isCadastroCompleto,
    // },
    {
      title: "Denúncia de irregularidade",
      url: isCadastroCompleto ? "/denuncia" : "/denunciaNoEndereco",
      icon: "assets/icon/denuncia.svg",
    },
    {
      title: "Consulta processos GED",
      url: "/ged",
      icon: "assets/icon/search-outline.svg",
    },
    {
      title: "Notícias e comunicados",
      url: "/noticias",
      icon: "assets/icon/noticias.svg",
    },
    {
      title: "Encontre uma agência",
      url: "/agencia",
      icon: "assets/icon/encontre_uma_agencia.svg",
    },
    {
      title: "Documentos auxiliares",
      url: "/documentos",
      icon: "assets/icon/documentos.svg",
    },
  ];

  const pagesSistema: Array<MenuItemPage> = [
    {
      title: "Centro de preferências",
      url: "/preferencias",
      icon: "assets/icon/preferencias.svg",
    },
    {
      title: "Sair",
      url: "/login",
      icon: "assets/icon/logout.svg",
      removeSelectedCSS: true,
      onClick: () => logout(),
    },
  ];

  return (
    <IonMenu style={{ width: "100%" }} contentId="main" type="overlay">
      <IonHeader>
        <IonToolbar color="primary">
          <div className="flex justify-start pl-2">
            <div className="text-xl px-2 font-bold">
              <span>Agência Virtual</span>
              <span hidden={!isHomolog()} className="text-lime-400 text-sm">
                {` Homologação`}
              </span>
            </div>
          </div>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonList>
          <IonNote className="text-primary text-sm font-bold">
            {usuario?.nome || "Visitante"}
          </IonNote>

          <MenuItem lista={pagesHome} />

          <IonItem className="">
            <div className="text-slate-400 font-bold text-xs">
              FATURAS E CERTIDÕES
            </div>
          </IonItem>

          <MenuItem lista={pagesFaturas} />

          <IonItem>
            <div className="text-slate-400 font-bold text-xs">
              SERVIÇOS DIVERSOS
            </div>
          </IonItem>

          <MenuItem lista={servicosDiversos} />

          <IonItem>
            <div className="text-slate-400 font-bold text-xs">SISTEMA</div>
          </IonItem>

          <MenuItem lista={pagesSistema} />

          <IonNote className="text-slate-400 text-xs pt-2">{version}</IonNote>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
