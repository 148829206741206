import { useHistory } from "react-router";
import { MarketingPages } from "../models/types";

export interface Anuncio {
  titulo?: string;
  subTitulo?: string;
  imagem?: string;
  pages?: MarketingPages[];
  onClickEvent?: Function;
  componente?: any; // sobrescreva tudo fazendo seu proprio componente <></>
}

export default function Marketing({ tipo }: { tipo?: MarketingPages }) {
  const navigate = useHistory();

  let items: Array<Anuncio> = [
    {
      titulo: "Cadastre-se no app",
      subTitulo:
        "Efetue o seu cadastro no app para ter acesso aos demais serviços disponíveis",
      onClickEvent: () => navigate.push("/cadastro"),
      pages: [MarketingPages.HOME_NAO_LOGADO],
    },
    // {
    //   onClickEvent: () => {},
    //   pages: [MarketingPages.HOME_NAO_LOGADO, MarketingPages.HOME_LOGADO],
    //   imagem: "./assets/alerta-golpe.png",
    // },
  ];

  items = tipo
    ? items.filter((item) => !item?.pages || item?.pages.includes(tipo))
    : items;

  return (
    <div className="grid grid-cols-1 gap-4">
      {items.map((item, index) => {
        return (
          <div key={index}>
            {item.componente ? (
              // componente manualmente escrito
              item.componente
            ) : // componente com titulo e subtitulo
            item.subTitulo ? (
              <div
                className="card-apresentacao"
                onClick={() => (item.onClickEvent ? item.onClickEvent() : {})}
              >
                <div className="h-28 flex flex-row items-center justify-center">
                  <div className="">
                    <div className="text-xl font-medium">{item.titulo}</div>
                    <div className="text-sm mt-2">{item.subTitulo}</div>
                  </div>
                </div>
              </div>
            ) : // componente somente com titulo
            item.titulo ? (
              <div
                className="card-apresentacao grow"
                onClick={item.onClickEvent ? item.onClickEvent() : () => {}}
              >
                <div className="h-28 flex flex-row items-center justify-center text-xl font-medium">
                  {item.titulo}
                </div>
              </div>
            ) : // componente somente imagem
            item.imagem ? (
              <div
                className="flex flex-row items-center justify-center"
                onClick={item.onClickEvent ? item.onClickEvent() : () => {}}
              >
                <img
                  className="w-full h-full rounded-lg"
                  src={item.imagem}
                  alt=""
                ></img>
              </div>
            ) : (
              <></>
            )}
          </div>
        );
      })}
    </div>
  );
}
