import { useEffect, useState } from "react";
import { useContaUsuario } from "../../contexts/contaUsuarioContext";
import { useAuth } from "../../contexts/authContext";
import {
  ApiRoutes,
  MATRICULA_SOLICITANTE,
  Mensagens,
} from "../../models/types";
import { useIonLoading } from "@ionic/react";
import { useAxiosClient } from "../../hooks/useAxiosClient";
import useNotification from "../../hooks/useNotification";
import Button from "../../components/Button";
import AlterarEmailFaturaDigital from "./AlterarEmailFaturaDigital";
import CardCliente from "../../components/CardCliente";

const AguardandoConfirmacao = () => {
  const { usuario } = useAuth();
  const [present, dismiss] = useIonLoading();
  const notification = useNotification();
  const { contaSelecionada, reconsultarDadosContaSelecionada } =
    useContaUsuario();
  const [emailReenviado, setEmailReenviado] = useState<boolean>(false);
  const [alterarEmail, setAlterarEmail] = useState<boolean>(false);
  const axiosClient = useAxiosClient(ApiRoutes.ECO_ATENDIMENTO);

  const handleReenviarEmail = async () => {
    const dadosReenviarEmail = {
      numeroConta: contaSelecionada?.numeroConta || 0,
      tipoCliente: usuario?.tipoCliente || "",
      numeroCpfCnpj: usuario?.cpfCnpj || "",
      matriculaSolicitante: MATRICULA_SOLICITANTE,
    };

    try {
      await present({ message: "Enviando dados..." });
      const response = await axiosClient.post(
        "/atendimentos/reenviar-email-confirmacao-fatura-digital",
        dadosReenviarEmail
      );
      dismiss();
      setEmailReenviado(true);
      notification.showSuccess({
        message:
          "O email de confirmação foi enviado para o endereço informado, verifique sua caixa de entrada.",
      });
    } catch (e: any) {
      dismiss();
      await notification.showError({ exception: e });
    }
  };

  const handleCancelarAlterarEmail = () => {
    setAlterarEmail(false);
  };

  useEffect(() => {
    reconsultarDadosContaSelecionada();
  }, []);

  return (
    <>
      {!alterarEmail ? (
        <div className="flex flex-col space-y-4">
          <CardCliente />

          <div className="card">
            <div className="flex flex-col space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <div className="text-sm text-secondary font-medium">
                    E-mail Fatura Digital
                  </div>
                  <div className="">
                    {contaSelecionada?.emailAdesaoFaturaDigital}
                  </div>
                </div>
                <div>
                  <div className="text-sm text-secondary font-medium">
                    Status
                  </div>
                  <div className="font-medium">
                    AGUARDANDO CONFIRMAÇÃO DO E-MAIL
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card text-slate-700">
            Para sua segurança foi enviado um e-mail de confirmação e o seu
            cadastro no serviço de Fatura Digital só será efetivado após a sua
            confirmação. Caso não tenha recebido e-mail, favor entrar em contato
            com a Central de Atendimento ao Cliente pelo telefone 0800 645 0115.
          </div>

          <div className="flex flex-col space-y-4">
            <Button
              label="Reenviar Email"
              onClick={handleReenviarEmail}
              disabled={emailReenviado}
            />
          </div>
        </div>
      ) : (
        <AlterarEmailFaturaDigital
          cancelarAlteracaoEmail={handleCancelarAlterarEmail}
        />
      )}
    </>
  );
};

export default AguardandoConfirmacao;
