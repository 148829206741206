import React from "react";
import { BlockUI } from "primereact/blockui";
import { openURL } from "../utils/functions";
import { Mensagens, POLITICA_PRIVACIDADE } from "../models/types";
import { IonButton } from "@ionic/react";
import { usePoliticaPrivacidade } from "../contexts/politicaPrivacidadeContext";

const PoliticaPrivacidade: React.FC = () => {
  const { aceitePolitica, aceitarPolitica, recusarPolitica } =
    usePoliticaPrivacidade();

  function template() {
    return (
      <div>
        <div className="card bg-opacity-100">
          <div className="flex flex-col space-y-4 w-80">
            <div className="text-primary text-xl text-center font-bold">
              Política de privacidade
            </div>
            <div
              className="text-sm text-secondary text-center cursor-pointer underline"
              onClick={() => openURL(POLITICA_PRIVACIDADE)}
            >
              {Mensagens.VEJA_POLITICA_PRIVACIDADE}
            </div>

            <div className="text-slate-700 text-justify pt-4">
              Ao clicar em "Aceitar", você aceita e concorda com a política, por
              isso dedique um tempo à sua leitura e compreensão.
            </div>

            <div className="flex flex-row gap-4">
              {/* <div className="w-full">
                <IonButton
                  type="button"
                  mode="ios"
                  fill="outline"
                  className="w-full"
                  color={"danger"}
                  onClick={recusarPolitica}
                >
                  Recusar
                </IonButton>
              </div> */}
              <div className="w-full">
                <IonButton
                  type="button"
                  mode="ios"
                  fill="outline"
                  className="w-full"
                  onClick={aceitarPolitica}
                >
                  Aceitar
                </IonButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <BlockUI
        blocked={!aceitePolitica}
        fullScreen
        template={template}
      ></BlockUI>
    </div>
  );
};

export default PoliticaPrivacidade;
