import { useHistory } from "react-router";
import Button from "./Button";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  className?: string; // se o parametro className for passado ele ira substituir todo o estilo do componente
  fullWidth?: boolean;
  onClickEvent?: Function; // passe uma funcao nesse parametro para substituir o navigate.goBack()
}

const ButtonBack = (props: ButtonProps) => {
  const navigate = useHistory();

  const { label, onClickEvent, className, fullWidth, ...propsOriginal } = props;

  const btnClass =
    className ||
    "p-4 bg-tertiary hover:bg-secondary rounded-xl text-sm font-medium text-slate-50 transition duration-200";

  return (
    <Button
      {...propsOriginal}
      className={btnClass}
      label={label || "Voltar"}
      fullWidth={fullWidth}
      onClick={() => (onClickEvent ? onClickEvent() : navigate.goBack())}
    />
  );
};

export default ButtonBack;
