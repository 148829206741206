import { ApiRoutes, Mensagens } from "../../models/types";
import { downloadPDF, formatarDataNoPadrao } from "../../utils/functions";
import { useContaUsuario } from "../../contexts/contaUsuarioContext";
import { z } from "zod";
import { useAxiosClient } from "../../hooks/useAxiosClient";
import { useIonLoading } from "@ionic/react";
import { useState } from "react";
import { useHistory } from "react-router";
import Page from "../../components/Page";
import useNotification from "../../hooks/useNotification";
import ReactForm from "../../components/form/ReactForm";
import SubmitButton from "../../components/form/SubmitButton";
import ButtonBack from "../../components/ButtonBack";
import InputDate from "../../components/form/InputDate";
import Button from "../../components/Button";

export default function ConsumoFaturamentoIndividualizados() {
  const contaUsuarioContext = useContaUsuario();
  const navigate = useHistory();
  const notification = useNotification();
  const axiosClient = useAxiosClient(ApiRoutes.ECO_FATURAMENTO);

  const [present, dismiss] = useIonLoading();
  const [referencia, setReferencia] = useState<any>();

  const formSchema = z.object({
    referencia: z.any().refine((input) => input, "Informe uma referência."),
  });

  type formType = z.infer<typeof formSchema>;

  async function enviarDados(dados: formType, resetFields: any) {
    await present({ message: Mensagens.CONSULTANDO });

    try {
      const params = new URLSearchParams({
        numeroConta: String(
          contaUsuarioContext.contaSelecionada?.numeroContaComDigito
        ),
        referenciaAnoMes: formatarDataNoPadrao(dados.referencia, "yyyyMM"),
      });

      const res = await axiosClient.get(
        "/conta-macro/contas-individualizadas",
        { params: params, responseType: "blob" }
      );

      dismiss();

      downloadPDF(
        `${
          contaUsuarioContext.contaSelecionada?.numeroContaComDigito
        } contas individualizadas ${formatarDataNoPadrao(
          dados.referencia,
          "MM-yyyy"
        )}`,
        res.data
      );
    } catch (e: any) {
      dismiss();

      if (e?.response?.status === 400) {
        notification.showWarning({
          message: Mensagens.NENHUM_RESULTADO_ENCONTRADO,
        });

        return;
      }

      await notification.showError({ exception: e });
    }
  }

  async function verificarEnvioAutomatico() {
    if (
      contaUsuarioContext.contaSelecionada
        ?.emailResumoFaturamentoIndividualizado
    ) {
      navigate.push("/alteracaoEmailIndividualizados");
    } else {
      navigate.push("/cadastroEmailIndividualizados");
    }
  }

  return (
    <Page title="Consumo e faturamento individualizados">
      <div className="flex flex-col space-y-4">
        <div className="card bg-opacity-70">
          <div className="items-center">
            <ReactForm
              schema={formSchema}
              onError={(error, e) => {}}
              onSubmit={(data, resetFields) =>
                enviarDados(data as formType, resetFields)
              }
            >
              <div className="flex flex-col space-y-4">
                <InputDate
                  name="referencia"
                  label="Referência"
                  view="month"
                  dateFormat="mm/yy"
                  value={referencia}
                  onChange={(e) => setReferencia(e?.value)}
                />

                <div className="grid grid-cols-3 gap-2 xs:grid-cols-1">
                  <SubmitButton label="Consultar" />
                  <Button
                    label="Envio automático"
                    onClick={() => verificarEnvioAutomatico()}
                  />
                  <ButtonBack label="Voltar" />
                </div>
              </div>
            </ReactForm>
          </div>
        </div>
      </div>
    </Page>
  );
}
