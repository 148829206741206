import React from "react";
import { useContaUsuario } from "../../contexts/contaUsuarioContext";
import { useAuth } from "../../contexts/authContext";
import {
  ApiRoutes,
  MATRICULA_SOLICITANTE,
  Mensagens,
} from "../../models/types";
import { useIonLoading } from "@ionic/react";
import { useAxiosClient } from "../../hooks/useAxiosClient";
import useNotification from "../../hooks/useNotification";
import ButtonBack from "../../components/ButtonBack";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import InputText from "../../components/form/InputText";
import SubmitButton from "../../components/form/SubmitButton";
import ReactForm from "../../components/form/ReactForm";

// interfaces para constrole das instancias de dados
interface AlterarEmailFaturaDigitalProps {
  cancelarAlteracaoEmail: () => void;
}

const AlterarEmailFaturaDigital: React.FC<AlterarEmailFaturaDigitalProps> = ({
  cancelarAlteracaoEmail,
}) => {
  const { usuario } = useAuth();
  const [present, dismiss] = useIonLoading();
  const notification = useNotification();
  const { contaSelecionada, reconsultarDadosContaSelecionada } =
    useContaUsuario();

  const axiosClient = useAxiosClient(ApiRoutes.ECO_ATENDIMENTO);

  // Definição do esquema de validação e que verifique que o email é diferente do atual
  const emailSchema = z
    .object({
      email: z
        .string()
        .email({ message: "Informe um e-mail válido." })
        .refine((data) => data !== contaSelecionada?.emailAdesaoFaturaDigital, {
          message: "Novo e-mail deve ser diferente do anterior",
        }),
      confirmarEmail: z
        .string()
        .email({ message: "Confirmação de e-mail inválida." })
        .refine((data) => data !== contaSelecionada?.emailAdesaoFaturaDigital, {
          message: "Novo e-mail deve ser diferente do anterior",
        }),
    })
    .refine((data) => data.email === data.confirmarEmail, {
      message: "Os dois e-mails informados devem ser iguais.",
      path: ["confirmarEmail"],
    });

  const handleFormSubmit = async (data: any) => {
    const dadosAlteracaoEmail = {
      numeroConta: contaSelecionada?.numeroConta || 0,
      tipoCliente: usuario?.tipoCliente || "",
      numeroCpfCnpj: usuario?.cpfCnpj || "",
      email: data.email,
      matriculaSolicitante: MATRICULA_SOLICITANTE,
    };

    try {
      await present({ message: "Enviando dados..." });

      const response = await axiosClient.put(
        "/atendimentos/alterar-email-fatura-digital",
        dadosAlteracaoEmail
      );
      dismiss();

      notification.showSuccess({
        message:
          "O email de confirmação foi enviado para o endereço informado, verifique sua caixa de entrada.",
      });

      await reconsultarDadosContaSelecionada();

      cancelarAlteracaoEmail();
    } catch (e: any) {
      dismiss();
      await notification.showError({ exception: e });
    }
  };

  const { register, reset } = useForm({
    resolver: zodResolver(emailSchema),
  });

  return (
    <>
      <div className="flex flex-col space-y-4">
        <div className="card">
          <div className="card-title">Alterar Email Fatura Digital</div>
          <div>
            <ReactForm
              schema={emailSchema}
              onSubmit={(data) => handleFormSubmit(data)}
              onError={(error, e) => {}}
            >
              <div className="flex flex-col space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <div className="text-sm text-secondary font-medium">
                      E-mail atual
                    </div>
                    <div className="">
                      {contaSelecionada?.emailAdesaoFaturaDigital}
                    </div>
                  </div>
                </div>
                <InputText
                  name="email"
                  label="Novo e-mail"
                  inputProps={{ maxLength: 60, ...register("email") }}
                />
                <InputText
                  name="confirmarEmail"
                  label="Confirmar novo e-mail"
                  inputProps={{ maxLength: 60, ...register("confirmarEmail") }}
                  onPaste={(e) => e.preventDefault()} // Desabilita a opção de colar no campo
                />

                <div className="grid grid-cols-2 gap-2 xs:grid-cols-1">
                  <SubmitButton label="Alterar" />
                  <ButtonBack
                    label="Cancelar"
                    onClickEvent={cancelarAlteracaoEmail}
                  />
                </div>
              </div>
            </ReactForm>
          </div>
        </div>
      </div>
    </>
  );
};

export default AlterarEmailFaturaDigital;
