import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useIonLoading } from "@ionic/react";
import { ApiRoutes, Mensagens } from "../../models/types";
import { getBaseURL } from "../../utils/functions";
import useNotification from "../../hooks/useNotification";
import axios from "axios";
import Page from "../../components/Page";

const FaturaDigitalConfirmarAdesao: React.FC = () => {
  const notification = useNotification();
  const navigate = useHistory<any>();
  const [present, dismiss] = useIonLoading();

  // dados vindos da navegação
  useEffect(() => {
    enviarDados(navigate.location.search);
  }, []);

  async function enviarDados(dados: any) {
    //
    let _dados = String(dados);
    _dados = dados?.substring(dados?.indexOf("=") + 1, dados?.length);

    if (!_dados) {
      await notification.showError({
        message:
          "Os parâmetros não foram encontrados para confirmar a adesão da fatura digital.",
        redirect: "/home",
      });

      return;
    }

    await present({ message: Mensagens.SALVANDO });

    try {
      const url =
        getBaseURL(ApiRoutes.ECO_ATENDIMENTO_EXTERNO) +
        `/atendimentos/confirmar-solicitacao-fatura-digital?token=${_dados}`;

      let response = await axios.put(url, {
        headers: { "Content-Type": "application/json" },
      });

      notification.showAlert({
        message: response?.data,
        header: Mensagens.SUCESSO_TITULO,
        redirect: "/",
      });

      dismiss();
    } catch (e: any) {
      dismiss();

      notification.showAlert({
        message: e?.response?.data?.detalhe || Mensagens.ERRO_INESPERADO,
        header: Mensagens.ATENCAO,
        redirect: "/",
      });
    }
  }

  return (
    <Page title="Confirmar adesão fatura digital" showCardConta={false}>
      <div className="h-full"></div>
    </Page>
  );
};

export default FaturaDigitalConfirmarAdesao;
