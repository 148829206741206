import {
  downloadPDF,
  formatarDataNoPadrao,
  getBaseURL,
  isApenasNumeros,
} from "../../utils/functions";
import {
  ApiRoutes,
  Item,
  Mensagens,
  FaturaOrgaoPublico,
} from "../../models/types";
import { useIonLoading } from "@ionic/react";
import { z } from "zod";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useCallback, useEffect, useState } from "react";
import InputText from "../../components/form/InputText";
import ReactForm from "../../components/form/ReactForm";
import SubmitButton from "../../components/form/SubmitButton";
import Page from "../../components/Page";
import axios from "axios";
import useNotification from "../../hooks/useNotification";
import ButtonBack from "../../components/ButtonBack";
import CardFaturaOrgaoPublico from "../../components/CardFaturaOrgaoPublico";
import InputDate from "../../components/form/InputDate";
import InputComplete from "../../components/form/InputComplete";

export interface Cliente {
  tipoCliente?: string;
  cpfCnpj?: string;
  numeroContaComDigito: string;
}

type orgaoAgrupador = {
  codigoOrganizacao: number;
  nomeOrganizacao: string;
};

export default function FaturaOrgaoPublicoPage() {
  const notification = useNotification();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [present, dismiss] = useIonLoading();
  const [isFluxoConsulta, setIsFluxoConsulta] = useState<boolean>(true);

  // novos
  const [codigoOrgaoPagador, setCodigoOrgaoPagador] = useState<string>("");
  const [referencia, setReferencia] = useState<any>();
  const [listaOrgaoAgrupador, setListaOrgaoAgrupador] = useState<Item[]>([]);
  const [orgaoAgrupador, setOrgaoAgrupador] = useState<string>("");
  const [listaContasOrgao, setListaContasOrgao] = useState<
    FaturaOrgaoPublico[]
  >([]);

  function converterOrgaosParaItens(orgaos: orgaoAgrupador[]): Item[] {
    return orgaos.map((orgao) => ({
      label: `${orgao.codigoOrganizacao} - ${orgao.nomeOrganizacao}`,
      value: orgao.codigoOrganizacao,
    }));
  }

  const formSchema = z.object({
    codigoOrgaoPagador: z.string().min(1, "Informe o código do órgão pagador."),
    referencia: z
      .any()
      .refine((input) => input, "Informe uma referência.")
      .transform((input) => formatarDataNoPadrao(input, "yyyyMM")),
  });

  const formSchemaAgrupador = z.object({
    orgaoAgrupador: z.string().min(1, "Selecione um órgão agrupador."),
  });

  type formType = z.infer<typeof formSchema>;

  async function consultarListaAgrupadores(dados: formType, resetFields: any) {
    await present({ message: Mensagens.CONSULTANDO });

    try {
      const token = await getReCaptchaToken();
      const url =
        getBaseURL(ApiRoutes.ECO_FATURAMENTO_EXTERNO) +
        "/fatura-analitica/lista-orgao-agrupador";

      const res = await axios.get(url, {
        headers: { "Content-Type": "application/json" },
        params: {
          codigoOrgaoPagador: codigoOrgaoPagador,
          "g-recaptcha-response": token as string,
        },
      });

      if (res.status === 200 && res.data.listaResultados) {
        setListaOrgaoAgrupador(
          converterOrgaosParaItens(res.data.listaResultados)
        );

        setIsFluxoConsulta(false);
      } else {
        notification.showWarning({
          message: Mensagens.NENHUM_RESULTADO_ENCONTRADO,
        });
      }

      dismiss();
    } catch (e: any) {
      dismiss();

      await notification.showError({ exception: e });
    }
  }

  async function consultarListaContasOrgao() {
    await present({ message: Mensagens.CONSULTANDO });

    try {
      const token = await getReCaptchaToken();
      const url =
        getBaseURL(ApiRoutes.ECO_FATURAMENTO_EXTERNO) +
        "/fatura-analitica/lista-contas-orgao";

      const headers = {
        "Content-Type": "application/json",
      };

      const res = await axios.post(
        url,
        JSON.stringify({
          dataAnoMesReferencia: formatarDataNoPadrao(referencia, "yyyyMM"),
          orgaoAgrupador: orgaoAgrupador,
          orgaoPagador: codigoOrgaoPagador,
        }),
        {
          headers: headers,
          params: {
            "g-recaptcha-response": token as string,
          },
        }
      );

      if (res.status === 200 && res.data.listaResultados) {
        setListaContasOrgao(res.data.listaResultados);
        setIsFluxoConsulta(false);
      } else {
        notification.showWarning({
          message: Mensagens.NENHUM_RESULTADO_ENCONTRADO,
        });
      }
      dismiss();
    } catch (e: any) {
      dismiss();

      await notification.showError({ exception: e });
    }
  }

  async function gerarFatura() {
    await present({ message: Mensagens.CONSULTANDO });

    try {
      const url =
        getBaseURL(ApiRoutes.ECO_FATURAMENTO_EXTERNO) +
        "/fatura-analitica/emitir-fatura";

      const token = await getReCaptchaToken();

      const params = new URLSearchParams({
        "g-recaptcha-response": token as string,
      });

      const body = {
        dataAnoMesReferencia: formatarDataNoPadrao(referencia, "yyyyMM"),
        orgaoAgrupador: String(orgaoAgrupador),
        orgaoPagador: String(codigoOrgaoPagador),
      };

      const res = await axios.post(url, JSON.stringify(body), {
        timeout: 1000000000,
        params: params,
        headers: { "content-type": "application/json" },
        responseType: "blob",
      });

      notification.showSuccess({
        message: Mensagens.FATURA_GERADA,
      });

      downloadPDF("FATURA ANALÍTICA DE ÓRGÃO PÚBLICO", res.data);

      dismiss();
    } catch (e: any) {
      dismiss();

      if (e?.message && e?.message === "Network Error") {
        await notification.showError({
          message: "504 Gateway Time-out. " + Mensagens.ERRO_INESPERADO,
          exception: e,
        });
        return;
      }

      await notification.showError({ exception: e });
    }
  }

  const getReCaptchaToken = useCallback(async () => {
    if (!executeRecaptcha) {
      notification.showWarning({ message: Mensagens.RECAPTCHA_INDISPONIVEL });
      return null;
    }

    const token = await executeRecaptcha();
    return token;
  }, [executeRecaptcha]);

  const handleChangeOrgaoPagador = (e: any) => {
    if (e.target.value === "" || isApenasNumeros(e.target.value)) {
      setCodigoOrgaoPagador(e.target.value);
    }
  };

  useEffect(() => {
    setListaContasOrgao([]);

    if (orgaoAgrupador) {
      consultarListaContasOrgao();
    }
  }, [orgaoAgrupador]);

  useEffect(() => {
    setListaContasOrgao([]);
    setListaOrgaoAgrupador([]);
  }, [codigoOrgaoPagador]);

  return (
    <Page
      title="Fatura analítica de órgão público"
      reCaptcha
      showCardConta={false}
    >
      <div className="flex flex-col space-y-4">
        <div className="card bg-opacity-70">
          <div className="card-title">Dados para consulta</div>
          <div className="items-center" hidden={!isFluxoConsulta}>
            <ReactForm
              schema={formSchema}
              onError={(error, e) => {}}
              onSubmit={(data, resetFields) =>
                consultarListaAgrupadores(data as formType, resetFields)
              }
            >
              <div className="flex flex-col space-y-4">
                <div className="grid grid-cols-2 gap-4 xs:grid-cols-1">
                  <InputText
                    name="codigoOrgaoPagador"
                    label="Código do órgão pagador"
                    onInput={(e) => handleChangeOrgaoPagador(e)}
                    inputProps={{ maxLength: 6 }}
                    value={codigoOrgaoPagador}
                  />

                  <InputDate
                    name="referencia"
                    label="Referência"
                    view="month"
                    dateFormat="mm/yy"
                    value={referencia}
                    onChange={(e) => setReferencia(e?.value)}
                  />
                </div>

                <SubmitButton label="Consultar" />
              </div>
            </ReactForm>
          </div>

          <div className="flex flex-col space-y-4" hidden={isFluxoConsulta}>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <div className="text-sm text-secondary font-medium">
                  Órgão pagador
                </div>
                <div className="text-slate-700">{codigoOrgaoPagador}</div>
              </div>
              <div>
                <div className="text-sm text-secondary font-medium">
                  Mês de referência
                </div>
                <div className="text-slate-700">
                  {formatarDataNoPadrao(referencia, "MM/yyyy")}
                </div>
              </div>
            </div>

            <ReactForm
              schema={formSchemaAgrupador}
              onError={(error, e) => {}}
              onSubmit={(data, resetFields) => consultarListaContasOrgao()}
            >
              <div className="flex flex-col space-y-4">
                <InputComplete
                  name="orgaoAgrupador"
                  placeHolder="Órgão agrupador"
                  items={listaOrgaoAgrupador}
                  onSelect={(item) => {
                    setOrgaoAgrupador(item?.value as string);
                  }}
                />

                <div className="grid grid-cols-2 gap-2 xs:grid-cols-1">
                  <SubmitButton label="Consultar" />
                  <ButtonBack onClickEvent={() => setIsFluxoConsulta(true)} />
                </div>
              </div>
            </ReactForm>
          </div>
        </div>

        <CardFaturaOrgaoPublico
          data={listaContasOrgao}
          hidden={isFluxoConsulta}
          onGerarFatura={() => {
            gerarFatura();
          }}
        />
      </div>
    </Page>
  );
}
