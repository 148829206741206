import { useEffect, useState } from "react";
import { useContaUsuario } from "../../contexts/contaUsuarioContext";
import { useAuth } from "../../contexts/authContext";
import {
  ApiRoutes,
  MATRICULA_SOLICITANTE,
  Mensagens,
} from "../../models/types";
import { useIonLoading } from "@ionic/react";
import { useAxiosClient } from "../../hooks/useAxiosClient";
import useNotification from "../../hooks/useNotification";
import Button from "../../components/Button";
import ButtonBack from "../../components/ButtonBack";
import AlterarEmailFaturaDigital from "./AlterarEmailFaturaDigital";
import CardCliente from "../../components/CardCliente";

const AderidoFaturaDigital = () => {
  const { usuario } = useAuth();
  const [present, dismiss] = useIonLoading();
  const notification = useNotification();
  const { contaSelecionada, reconsultarDadosContaSelecionada } =
    useContaUsuario();

  const [alterarEmail, setAlterarEmail] = useState<boolean>(false);
  const axiosClient = useAxiosClient(ApiRoutes.ECO_ATENDIMENTO);

  const handleCancelarAdesao = async () => {
    const dadosCancelarAdesao = {
      numeroConta: contaSelecionada?.numeroConta || 0,
      tipoCliente: usuario?.tipoCliente || "",
      numeroCpfCnpj: usuario?.cpfCnpj || "",
      matriculaSolicitante: MATRICULA_SOLICITANTE,
    };

    try {
      await present({ message: "Enviando dados..." });
      const response = await axiosClient.post(
        "/atendimentos/cancelar-recebimento-fatura-digital",
        dadosCancelarAdesao
      );
      dismiss();
      notification.showSuccess({
        message: "Fatura Descadastrada com Sucesso",
      });
    } catch (e: any) {
      dismiss();
      await notification.showError({ exception: e });
    }

    await reconsultarDadosContaSelecionada();
  };

  const handleAlterarEmail = () => {
    setAlterarEmail(true);
  };

  const handleCancelarAlterarEmail = () => {
    setAlterarEmail(false);
  };

  useEffect(() => {
    reconsultarDadosContaSelecionada();
  }, []);

  return (
    <>
      {!alterarEmail ? (
        <div className="flex flex-col space-y-4">
          <CardCliente />

          <div className="card">
            <div className="flex flex-col space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <div className="text-sm text-secondary font-medium">
                    E-mail Fatura Digital
                  </div>
                  <div className="">
                    {contaSelecionada?.emailAdesaoFaturaDigital}
                  </div>
                </div>
                <div>
                  <div className="text-sm text-secondary font-medium">
                    Status
                  </div>
                  <div className="font-medium">Fatura Digital Ativa</div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col space-y-4">
            <div className="grid grid-cols-2 gap-2 xs:grid-cols-1">
              <Button label="Alterar Email" onClick={handleAlterarEmail} />
              <ButtonBack
                label="Cancelar Fatura Digital"
                onClickEvent={handleCancelarAdesao}
              />
            </div>
          </div>
        </div>
      ) : (
        <AlterarEmailFaturaDigital
          cancelarAlteracaoEmail={handleCancelarAlterarEmail}
        />
      )}
    </>
  );
};

export default AderidoFaturaDigital;
