import {
  ApiRoutes,
  Mensagens,
  UnidadeOrganizacional,
} from "../../models/types";

import { useIonLoading } from "@ionic/react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { getBaseURL } from "../../utils/functions";

import axios from "axios";
import React, { useEffect, useState } from "react";
import Page from "../../components/Page";
import useNotification from "../../hooks/useNotification";

const UnidadesInterior: React.FC = () => {
  const notification = useNotification();

  const [present, dismiss] = useIonLoading();
  const [data, setData] = useState<UnidadeOrganizacional[] | null>(null);
  const [filters, setFilters] = useState<any>(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  useEffect(() => {
    consultar();
    initFilters();
  }, []);

  async function consultar() {
    await present({ message: Mensagens.CONSULTANDO });

    let parametros = {
      agencia: true,
      cidades_excluidas: 1,
    };

    try {
      const url = getBaseURL(ApiRoutes.SAN_CADASTRO) + "/uos";

      const response = await axios.get(url, {
        params: parametros,
        headers: { "Content-Type": "application/json" },
      });

      if (response.data) {
        setData(response.data?.listaResultados);
      }

      dismiss();
    } catch (e: any) {
      dismiss();

      await notification.showError({ exception: e });
    }
  }

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    setGlobalFilterValue("");
  };

  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const header = () => {
    return (
      <div className="flex flex-row justify-end h-8">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Pesquisa"
            className="h-8 w-full"
          />
        </span>
      </div>
    );
  };

  return (
    <Page title="Encontre uma agência" showCardConta={false} buttonType="back">
      <div className="flex flex-col space-y-4">
        <div className="card">
          <div className="card-title">Unidades de atendimento do interior</div>

          <div className="text-slate-700">
            Horário de atendimento: 7h30 às 11h30 e 13h30 às 17h30
          </div>
        </div>

        <div className="card-borda">
          <DataTable
            value={data || []}
            emptyMessage={Mensagens.NENHUM_RESULTADO_ENCONTRADO}
            responsiveLayout="scroll"
            stripedRows
            header={header}
            filters={filters}
            filterDisplay="menu"
            globalFilterFields={["cidade", "logradouro", "bairro"]}
            rows={10}
            paginator
            paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport"
            currentPageReportTemplate="{first} - {last} de {totalRecords}"
            pageLinkSize={3}
          >
            <Column
              headerStyle={{ padding: 0 }}
              body={styleBodyTemplate}
            ></Column>
          </DataTable>
        </div>
      </div>
    </Page>
  );
};

const styleBodyTemplate = (rowData: UnidadeOrganizacional) => {
  return (
    <div className="flex flex-col text-slate-700">
      <div className="font-medium">{rowData.cidade}</div>
      <div className="text-sm" hidden={!rowData.telefone}>
        Telefone: {rowData.telefone}
      </div>
      <div className="text-sm">
        {rowData.logradouro}, n°{" "}
        {rowData.numeroLogradouro ? rowData.numeroLogradouro : 0},{" "}
        {rowData.bairro}
      </div>
    </div>
  );
};

export default UnidadesInterior;
