import React, { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { ApiRoutes, Item, Mensagens } from "../../models/types";
import { getBaseURL, ordernarLista } from "../../utils/functions";
import InputComplete from "./InputComplete";
import useNotification from "../../hooks/useNotification";
import DivInputLoading from "../DivInputLoading";

export interface Pesquisa {
  mensagem: string;
  listaResultados: Cidade[];
}

export interface Cidade {
  codigoCidade: number;
  nomeCidade: string;
}

export interface PesquisaCidadeProps {
  name: string;
  placeHolder?: string;
  defaultValuePosition?: number;
  disableClearable?: boolean;
  onSelect?(item: Item | null): void;
}

const PesquisaCidade: React.FC<PesquisaCidadeProps> = ({
  name,
  placeHolder,
  defaultValuePosition,
  disableClearable,
  onSelect,
}: PesquisaCidadeProps) => {
  const notification = useNotification();

  const [data, setData] = useState<Cidade[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const url = String(getBaseURL(ApiRoutes.SAN_CADASTRO)).concat(
    "/enderecos/cidades"
  );

  useEffect(() => {
    consultar();
  }, []);

  async function consultar() {
    setIsLoading(true);
    setData(null);

    try {
      let response: AxiosResponse<Pesquisa, any> = await axios.get<Pesquisa>(
        url,
        {
          params: { uf: "GO" },
          headers: {
            "content-type": "application/json",
            "Application-Origin": "AgenciaVirtual",
          },
        }
      );

      let lista = ordernarLista(response?.data?.listaResultados, "nomeCidade");

      setIsLoading(false);
      setData(lista);
      //
    } catch (e: any) {
      setIsLoading(false);

      await notification.showError({ exception: e });
    }
  }

  return isLoading ? (
    <DivInputLoading label={"Carregando cidades"} />
  ) : (
    <InputComplete
      name={name}
      placeHolder={placeHolder}
      defaultValuePosition={defaultValuePosition}
      disableClearable={disableClearable}
      onSelect={onSelect}
      items={data?.map((item) => {
        return { value: item.codigoCidade, label: item.nomeCidade };
      })}
    />
  );
};

export default PesquisaCidade;
