import React, { useEffect, useState } from "react";
import { BlockUI } from "primereact/blockui";
import { IonIcon } from "@ionic/react";
import { getBaseURL, isMobile, openURL } from "../utils/functions";
import { useLocation } from "react-router";
import { ApiRoutes, Mensagens } from "../models/types";
import axios from "axios";
import useNotification from "../hooks/useNotification";

const Popup: React.FC = () => {
  const notification = useNotification();
  const location = useLocation();

  const [show, setShow] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (location.pathname === "/") {
      consultar();
    }
  }, [location.pathname]);

  async function consultar() {
    let parametros = { tipo: 8 }; // Popup (Agência Virtual)

    try {
      const url = getBaseURL(ApiRoutes.NOTICIAS_APP) + "/popupVigente";

      const response = await axios.get(url, {
        params: parametros,
        headers: { "Content-Type": "application/json" },
      });

      let res = response.data;

      if (res?.imagemBase64) {
        //
        let extensao =
          res.urlImagemNoticia.substring(
            res.urlImagemNoticia.indexOf(".") + 1,
            res.urlImagemNoticia.length
          ) || "";

        res = {
          ...res,
          ...{
            imagemBase64: `data:image/${extensao};base64,` + res?.imagemBase64,
          },
        };

        setData(res);
        console.log(res);

        if (isMobile()) {
          //
          if (res?.showMobile) {
            setTimeout(() => {
              // espera a animacao da versao mobile finalizar
              setShow(true);
            }, 3700);
          }
        } else {
          setShow(true);
        }
      }

      //
    } catch (e: any) {
      console.log(e);
    }
  }

  async function redirect() {
    setShow(false);

    if (data?.urlRedirecionamento) {
      openURL(data?.urlRedirecionamento);
    }
  }

  function template() {
    return (
      <div className="p-4">
        <div className="flex flex-row justify-end">
          <IonIcon
            slot="start"
            src={"assets/icon/close-circle-outline.svg"}
            className="text-5xl text-red-600 cursor-pointer"
          />
        </div>

        <div className="card bg-opacity-100 ">
          <div className="w-full flex justify-center items-start">
            <img
              alt=""
              // className="w-28 lg:w-32"
              // src="./assets/nova_agencia_virtual.png"
              src={data?.imagemBase64}
              onClick={() => redirect()}
            ></img>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <BlockUI
        blocked={show}
        fullScreen
        template={template}
        onClick={() => setShow(false)}
      ></BlockUI>
    </div>
  );
};

export default Popup;
