import {
  ApiRoutes,
  CODIGO_UNIDADE_ATENDIMENTO,
  Conta,
  MATRICULA_SOLICITANTE,
  Mensagens,
  ProtocoloItem,
  SituacaoLigacaoAgua,
} from "../models/types";
import {
  formatarData,
  getNumeroDDD,
  getNumeroSemDDD,
  getOnlyNumbers,
} from "../utils/functions";
import { useContaUsuario } from "../contexts/contaUsuarioContext";
import { useEffect, useState } from "react";
import { z } from "zod";
import { useAxiosClient } from "../hooks/useAxiosClient";
import { useAuth } from "../contexts/authContext";
import { useIonLoading } from "@ionic/react";
import { useProtocolo } from "../contexts/protocoloContext";
import Page from "../components/Page";
import Button from "../components/Button";
import Accordion from "../components/Accordion";
import useNotification from "../hooks/useNotification";
import ReactForm from "../components/form/ReactForm";
import InputText from "../components/form/InputText";
import SubmitButton from "../components/form/SubmitButton";
import ButtonBack from "../components/ButtonBack";
import SelectField from "../components/form/SelectField";
import DivFaltaAgua from "../components/DivFaltaAgua";

export const TIPOS_TEMPO_FALTA_AGUA = (() => {
  const lista = ["06 horas", "12 horas", "18 horas", "24 horas", "+24 horas"];

  return lista.map((item) => {
    return { value: item, label: item };
  });
})();

export default function ComunicarFaltaAgua() {
  const { usuario } = useAuth();
  const { getProtocolo } = useProtocolo();
  const contaUsuarioContext = useContaUsuario();
  const notification = useNotification();
  const axiosClient = useAxiosClient(ApiRoutes.ECO_ATENDIMENTO);

  const [present, dismiss] = useIonLoading();
  const [isFluxoCadastro, setIsFluxoCadastro] = useState<boolean>(false);

  const [dadosConta, setDadosConta] = useState<Conta | null>({
    numeroConta: 0,
    numeroContaComDigito: "",
    nomeCidade: "",
    nomeBairro: "",
    nomeLogradouro: "",
    quadraImovel: "",
    loteImovel: "",
    numeroImovel: "",
    situacaoLigacaoAgua: "",
  });

  useEffect(() => {
    setIsFluxoCadastro(false);
  }, []);

  useEffect(() => {
    if (contaUsuarioContext.contaSelecionada) {
      setDadosConta(contaUsuarioContext.contaSelecionada);
    }
  }, [contaUsuarioContext.contaSelecionada]);

  function next() {
    if (!contaUsuarioContext.contaSelecionada) {
      notification.showNecessarioSelecionarConta();
      return;
    }

    setIsFluxoCadastro(true);
  }

  const formSchema = z.object({
    pontoReferencia: z.string().min(1, "Informe um ponto de referência."),
    tempoFaltaDeAgua: z
      .any()
      .refine((input) => input, "Selecione o tempo da falta de água.")
      .transform((input) => input.value),
    celular: z
      .string()
      .min(11, "Informe um número de contato.")
      .transform((val) => Number(getOnlyNumbers(val))),
  });

  type formType = z.infer<typeof formSchema>;

  async function enviarDados(dados: formType, resetFields: any) {
    //
    if (dadosConta?.codigoSituacaoLigacaoAgua !== SituacaoLigacaoAgua.LIGADA) {
      notification.showAlert({
        message: Mensagens.MSGE0099_FALTA_AGUA_NAO_POSSIVEL,
      });
      return;
    }

    await present({ message: Mensagens.SALVANDO });

    const protocolo = await getProtocolo({
      numeroConta: dadosConta?.numeroConta || null,
      dddTelefoneContato: getNumeroDDD(dados.celular),
      numeroTelefoneContato: getNumeroSemDDD(dados.celular),
    });

    if (!protocolo || !protocolo.numeroProtocolo) {
      dismiss();
      return;
    }

    try {
      // Implementado conforme documentação do swagger
      // https://atendimento-homologacao.saneago.com.br/eco-atendimento/servicos/swagger-ui.html#/operations/Atendimento/consultarFaltaAguaUsingGET

      const response = await axiosClient.get(
        `/atendimentos/${protocolo.numeroProtocolo}/consultar/falta-de-agua`,
        {
          params: { numeroConta: dadosConta?.numeroConta },
        }
      );

      dismiss();

      // Consta registro de corte no sistema.
      if (response && response.status === 206) {
        notification
          .showAlert({
            message: `Consta registro de corte em nosso sitema. Consulte os débitos em aberto.`,
            redirect: "/segundaVia",
            buttons: [{ text: "Consultar", role: "confirm" }],
          })
          .then(() => {
            resetFields();
          });

        return;
      }

      // Abastecimento pode estar comprometido por manobra.
      if (response && response.status === 200) {
        notification.showAlert({
          message: `Informamos que devido a obras de melhorias na rede de distribuição de água, 
          o seu abastecimento pode estar comprometido a partir do dia ${formatarData(
            response?.data?.dataHoraProgramada
          )}. A previsão de normalização acontecerá ao longo do dia  ${formatarData(
            response?.data?.dataHoraNormalizacao
          )}, de forma gradual. A Saneago  recomenda o uso de caixa de água e consumo consciente.`,
        });

        return;
      }

      notification.showAlert({
        message: `Problemas ao consultar se existe corte ou manobra. Status de sucesso "${response.status}" não mapeado.`,
      });

      //
    } catch (e: any) {
      //

      // Nao consta registro de comprometimento no abastecimento
      if (e?.request?.status === 417) {
        registrarFaltaAgua(dados, resetFields, protocolo);
        return;
      }

      dismiss();

      await notification.showError({
        message: "Problemas ao consultar se existe corte ou manobra.",
        exception: e,
      });
    }
  }

  async function registrarFaltaAgua(
    dados: formType,
    resetFields: any,
    protocolo: ProtocoloItem
  ) {
    try {
      let body = {
        matriculaSolicitante: MATRICULA_SOLICITANTE,
        codigoUnidadeAtendimento: CODIGO_UNIDADE_ATENDIMENTO,
        cpfCnpj: usuario?.cpfCnpj,
        nomeContato: usuario?.nome,
        tipoCliente: usuario?.tipoCliente,
        numeroConta: dadosConta?.numeroContaComDigito,
        pontoReferencia: dados.pontoReferencia,
        dddTelefoneContato: getNumeroDDD(dados.celular),
        numeroTelefoneContato: getNumeroSemDDD(dados.celular),
        tempoFaltaDeAgua: dados.tempoFaltaDeAgua,
      };

      await axiosClient.post(
        `/atendimentos/${protocolo.numeroProtocolo}/registrar/falta-de-agua`,
        JSON.stringify(body),
        {
          headers: {
            "content-type": "application/json",
          },
        }
      );

      notification.showProtocolo(protocolo.numeroProtocolo).then(() => {
        resetFields();
      });

      dismiss();
    } catch (e: any) {
      dismiss();

      let msg = Mensagens.ERRO_INESPERADO;

      if (e.response.status === 403) {
        msg = Mensagens.SOLICITACAO_ABERTA;
      }

      await notification.showError({ message: msg, exception: e });
    }
  }

  return (
    <Page title="Comunicar falta de água">
      <div className="flex flex-col space-y-4">
        <Accordion title="Observações" open={!isFluxoCadastro}>
          <DivFaltaAgua />
        </Accordion>

        <div className="text-center" hidden={isFluxoCadastro}>
          <Button label="Avançar" onClick={() => next()} />
        </div>

        <div className="card bg-opacity-70" hidden={!isFluxoCadastro}>
          <div className="card-title">Dados da ocorrência</div>

          <div className="items-center">
            <ReactForm
              schema={formSchema}
              onError={(error, e) => {}}
              onSubmit={(data, resetFields) =>
                enviarDados(data as formType, resetFields)
              }
            >
              <div className="flex flex-col space-y-4">
                <InputText
                  value={dadosConta?.nomeCidade}
                  name="nomeCidade"
                  label="Cidade"
                  disabled
                />
                <InputText
                  value={dadosConta?.nomeBairro}
                  name="nomeBairro"
                  label="Bairro"
                  disabled
                />
                <InputText
                  value={dadosConta?.nomeLogradouro}
                  name="nomeLogradouro"
                  label="Logradouro"
                  disabled
                />

                <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3 gap-4">
                  <InputText
                    value={dadosConta?.quadraImovel}
                    name="quadraImovel"
                    label="Quadra"
                    disabled
                  />
                  <InputText
                    value={dadosConta?.loteImovel}
                    name="loteImovel"
                    label="Lote"
                    disabled
                  />
                  <InputText
                    value={dadosConta?.numeroImovel}
                    name="numeroImovel"
                    label="Número"
                    disabled
                  />
                </div>

                <InputText
                  name="pontoReferencia"
                  label="Ponto de referência"
                  inputProps={{ maxLength: 50 }}
                />

                <InputText
                  name="celular"
                  label="Celular"
                  mask="99 99999-9999"
                />

                <SelectField
                  name="tempoFaltaDeAgua"
                  placeHolder="Tempo de falta de água"
                  items={TIPOS_TEMPO_FALTA_AGUA}
                />

                <div className="grid grid-cols-2 gap-2 xs:grid-cols-1">
                  <SubmitButton label="Enviar" />
                  <ButtonBack
                    label="Voltar"
                    onClickEvent={() => setIsFluxoCadastro(false)}
                  />
                </div>
              </div>
            </ReactForm>
          </div>
        </div>
      </div>
    </Page>
  );
}
