import { IonIcon, IonItem, IonLabel } from "@ionic/react";
import { openURL } from "../utils/functions";
import React from "react";

interface Atendimento {
  title: string;
  url: string;
  icon: string;
  classIcon: string;
  classTitle:string;
}

const CanaisAtendimento: React.FC = () => {
  const items: Array<Atendimento> = [
    {
      title: "Site: www.saneago.com.br",
      url: "https://www.saneago.com.br",
      icon: "assets/icon/site.svg",
      classIcon: "text-2xl",
      classTitle: "text-primary px-2 underline",
    },
    {
      title: "Chat: www.saneago.com.br",
      url: "https://www.saneago.com.br",
      icon: "assets/icon/chat.svg",
      classIcon: "text-2xl",
      classTitle: "text-primary px-2 underline",
    },
    {
      title: "Whatsapp: (62) 3269-9115",
      url: "https://wa.me/556232699115",
      icon: "assets/icon/whatsapp.svg",
      classIcon: "text-2xl",
      classTitle: "text-primary px-2 underline",
    },
    {
      title: "saneago",
      url: "https://www.instagram.com/saneago/",
      icon: "assets/icon/instagram.svg",
      classIcon: "text-2xl",
      classTitle: "text-primary px-2 underline",
    },
    {
      title: "saneagonarede",
      url: "",
      icon: "assets/icon/redes_sociais.svg",
      classIcon: "text-7xl",
      classTitle: "text-primary px-2",
    },
  ];

  return (
    <div className="flex flex-col items-center justify-center card-borda">
      <div className="text-center text-lg font-medium text-primary py-2">
        Fique atento aos nossos canais oficiais de atendimento
      </div>

      <div className="flex flex-col md:items-center">
        <div className="flex flex-row space-x-2 p-4 items-center">
          <IonIcon
            color="primary"
            className="text-5xl"
            src={"assets/icon/atendimento.svg"}
          />
          <div className="flex flex-col">
            <div className="text-primary text-sm">Atendimento ao cliente</div>
            <div className="text-tertiary text-xl font-extrabold">
              0800 645 0115
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2">
          {items.map((item, index) => {
            return (
              <IonItem
                routerDirection="none"
                lines="none"
                detail={false}
                key={index}
                color="transparent"
                onClick={() => openURL(item.url)}
              >
                <div className="flex flex-row items-center h-6 cursor-pointer">
                  <IonIcon src={item.icon} className={item.classIcon} />
                  <IonLabel className="ion-text-wrap">
                    <div className={item.classTitle}>{item.title}</div>
                  </IonLabel>
                </div>
              </IonItem>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CanaisAtendimento;
