import React from "react";

const DivDesobstrucaoEsgoto: React.FC = () => {
  return (
    <div className="">
      <div className="mb-2 text-sm font-medium text-slate-700">
        A desobstrução consiste na retirada de substâncias sólidas, as quais
        ocasionam o entupimento da rede de esgotos. Caso a obstrução seja nas
        instalações internas, o cliente deverá procurar firmas especializadas
        para efetuar a desobstrução.
      </div>
    </div>
  );
};

export default DivDesobstrucaoEsgoto;
