import {
  getTipoPessoa,
  getBaseURL,
  completarCpfCnpj,
} from "../utils/functions";
import { ApiRoutes, Auth, Mensagens, Usuario } from "../models/types";
import { createContext, useContext, useState } from "react";
import { SHA1 } from "crypto-js";
import { fromUnixTime } from "date-fns";
import { useHistory } from "react-router";
import { Preferences } from "@capacitor/preferences";
import { useAxiosClient } from "../hooks/useAxiosClient";

import axios from "axios";
import jwtDecode from "jwt-decode";
import useNotification from "../hooks/useNotification";

export interface AuthContextData {
  auth: Auth | null;
  isLogged: boolean;
  usuario?: Usuario | null;
  headers?: {} | null;
  login(cpfCnpj: string, senha: string): Promise<any>;
  refreshToken(): Promise<any>;
  logout(): void;
  recuperarSessao(): void;
  getHeadersRequisicao(access_token: string): any;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [auth, setAuth] = useState<Auth | null>(null);
  const [usuario, setUsuario] = useState<Usuario | null>(null);
  const [headers, setHeaders] = useState<{} | null>(null);
  const [isLogged, setIsLogged] = useState<boolean>(false);

  const navigate = useHistory();
  const notification = useNotification();

  const useAxiosESI = useAxiosClient(ApiRoutes.ESI_USUARIO_AGENCIA_VIRTUAL);
  const urlKeycloak = String(getBaseURL(ApiRoutes.KEYCLOAK));

  async function login(cpfCnpj: string, senha: string): Promise<any> {
    //
    const params = new URLSearchParams({
      username: completarCpfCnpj(cpfCnpj),
      password: SHA1(senha).toString(),
      client_id: "api-agencia",
      grant_type: "password",
    });

    return axios
      .post(urlKeycloak, params, {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
      .then(async (e) => {
        configurarSessao(e.data);
        consultarCadastroUsuario(e.data);

        return e.data;
      })
      .catch(async (e: any) => {
        setIsLogged(false);

        let msg = Mensagens.ERRO_INESPERADO;

        if (e?.response?.status === 401) {
          msg = Mensagens.USUARIO_INVALIDO;
        }

        await notification.showError({ message: msg, exception: e });
        return null;
      });
  }

  async function refreshToken(authParam?: Auth): Promise<any> {
    const _auth = authParam || auth;

    if (!isRefreshTokenEhValido(_auth!)) {
      if (usuario) {
        // se o app está em uso, desloga e lanca mensagem
        notification.showWarning({ message: "Sua sessão expirou." });

        logout();
      } else {
        // quando carrega o app pela primeira vez
        limparToken();
      }

      return null;
    }

    const params = new URLSearchParams({
      client_id: "api-agencia",
      grant_type: "refresh_token",
      refresh_token: _auth?.refresh_token!,
    });

    return axios
      .post(urlKeycloak, params, {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
      .then((e) => {
        configurarSessao(e.data);

        if (!usuario) {
          consultarCadastroUsuario(e.data);
        }

        return e.data;
      })
      .catch(async (e) => {
        limparToken();

        await notification.showError({ exception: e });
        return null;
      });
  }

  async function configurarSessao(_auth: Auth) {
    setIsLogged(true);

    const decoded: any = jwtDecode(_auth.access_token);

    _auth = {
      ..._auth,
      timestampInicioSessao: fromUnixTime(decoded.iat).getTime(),
      timestampFimSessao: fromUnixTime(decoded.exp).getTime(),
    };

    setAuth(_auth);
    setHeaders(getHeadersRequisicao(_auth.access_token));

    // salvando dados para recuperar sessao
    await Preferences.set({
      key: "auth",
      value: JSON.stringify(_auth),
    });
  }

  async function recuperarSessao() {
    const { value } = await Preferences.get({ key: "auth" });

    if (value) {
      const _auth = JSON.parse(value || "{}");
      await refreshToken(_auth);

      navigate.push("/home");
    }
  }

  function isRefreshTokenEhValido(_auth: Auth): boolean {
    if (!_auth || !_auth.refresh_token) {
      return false;
    }

    const decoded: any = jwtDecode(_auth.refresh_token);

    // verifica se expirou o refresh token
    if (Date.now() >= fromUnixTime(decoded.exp).getTime()) {
      return false;
    }

    return true;
  }

  function getHeadersRequisicao(access_token: string): object {
    return {
      "content-type": "application/json",
      "Application-Origin": "AgenciaVirtual",
      Authorization: `Bearer ${access_token}`,
    };
  }

  async function consultarCadastroUsuario(_auth: Auth) {
    const decoded: any = jwtDecode(_auth.access_token);

    // carregando os dados do usuario logado
    let _usuario: Usuario = {
      tipoCliente: getTipoPessoa(decoded.preferred_username),
      nome: decoded.given_name,
      email: decoded.email,
      cpfCnpj: completarCpfCnpj(decoded.preferred_username),
    };

    const _nome = _usuario.nome
      ? _usuario.nome
      : _usuario.email
      ? _usuario.email
      : "";

    _usuario = { ..._usuario, nome: _nome };
    setUsuario(_usuario);

    try {
      const res = await useAxiosESI.get(
        `/atendimentos/usuarios/${_usuario?.tipoCliente}/${_usuario?.cpfCnpj}`
      );

      setUsuario({
        ..._usuario,
        nome: res.data?.nome,
        ativo: res.data?.ativo,
        clienteSaneago: res.data?.clienteSaneago,
        emailOfuscado: res.data?.email,
      });

      //
    } catch (e: any) {
      await notification.showError({
        message: "Problemas ao consultar os dados do cadastro do usuário.",
        exception: e,
      });
    }
  }

  async function logout() {
    setIsLogged(false);
    setAuth(null);
    setUsuario(null);
    setHeaders(null);

    await limparToken();

    navigate.push("/login");
    window.location.reload();
  }

  async function limparToken() {
    await Preferences.remove({ key: "auth" });
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        isLogged,
        usuario,
        headers,
        login,
        logout,
        refreshToken,
        recuperarSessao,
        getHeadersRequisicao,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}
