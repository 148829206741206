import React, { useEffect, useState } from "react";
import { IonButton } from "@ionic/react";
import { useHistory } from "react-router";
import { useAuth } from "../contexts/authContext";
import { useContaUsuario } from "../contexts/contaUsuarioContext";
import { useAxiosClient } from "../hooks/useAxiosClient";
import { ApiRoutes, Debito, SegundaVia } from "../models/types";
import { formatarData, formatarDinheiro } from "../utils/functions";
import DivLoading from "./DivLoading";

const CardFaturaAtual: React.FC = () => {
  const { usuario } = useAuth();
  const { contaSelecionada } = useContaUsuario();

  const axiosClient = useAxiosClient(ApiRoutes.ECO_FATURAMENTO);
  const navigate = useHistory();

  const [data, setData] = useState<Debito | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (contaSelecionada) {
      consultarFaturaAtual();
    }
  }, [contaSelecionada]);

  async function consultarFaturaAtual() {
    setData(null);

    let parametros = {
      numeroContaComDigito: contaSelecionada?.numeroContaComDigito,
      cpfCnpj: usuario?.cpfCnpj,
      tipoCliente: usuario?.tipoCliente,
      quantidadeMeses: 1,
    };

    setIsLoading(true);

    try {
      const response = await axiosClient.get<SegundaVia>(
        "/faturamentos/faturas",
        {
          params: parametros,
        }
      );

      setData(response?.data?.listaResultados[0]);
      setIsLoading(false);
    } catch (e: any) {
      setIsLoading(false);
    }
  }

  const isFaturaAtualPagaOuZerada =
    data?.situacaoDebito === "ZERADO" || data?.situacaoDebito === "PAGO";

  if (isLoading) {
    return (
      <div className="card">
        <DivLoading label={"Consultando fatura atual..."} />
      </div>
    );
  }

  return (
    <div className="card" hidden={!data}>
      <div className="card-title">Fatura atual</div>

      <div className="grid grid-cols-1 gap-6">
        <div className="flex flex-col items-center">
          <div className="text-2xl font-bold text-tertiary">
            {formatarDinheiro(data?.valorDebito || 0)}
          </div>
        </div>

        <div className="grid grid-cols-2 gap-6">
          <div className="flex flex-col items-center">
            <div className="text-secondary">Referência</div>
            <div className="">{data?.referenciaFormatada}</div>
          </div>

          <div
            className="flex flex-col items-center"
            hidden={isFaturaAtualPagaOuZerada}
          >
            <div className="text-secondary">Vencimento</div>
            {formatarData(data?.dataVencimento)}
          </div>

          <div className="flex flex-col items-center">
            <div className="text-secondary">Consumo</div>
            <div className="">{data?.consumoMedido || 0}m³</div>
          </div>

          <div
            className="text-center text-secondary font-bold text-xl col-span-2"
            hidden={data?.situacaoDebito !== "PAGO"}
          >
            Fatura paga
          </div>

          <div
            className="flex flex-col items-center"
            hidden={isFaturaAtualPagaOuZerada}
          >
            <div className="text-secondary">Pagamento</div>
            <div className="">
              <IonButton
                shape="round"
                size="small"
                fill="outline"
                onClick={() => navigate.push("/segundaVia")}
              >
                <div className="normal-case">2ª via</div>
              </IonButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardFaturaAtual;
