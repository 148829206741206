import React from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

export interface ReactFormProps {
  schema: any;
  children: any;
  defaultValues?: Record<string, any>;
  onSubmit: (
    data: Record<string, any>,
    resetFields?: Function,
    event?: React.BaseSyntheticEvent
  ) => void;
  onError: (error: any, event?: React.BaseSyntheticEvent) => void;
}

const ReactForm: React.FC<ReactFormProps> = ({
  schema,
  onSubmit,
  onError,
  children,
  defaultValues,
}: ReactFormProps) => {
  const methods = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const { handleSubmit, resetField } = methods;

  // passe um array com os campos que deseja limpar, ou não passe nada e limpe todo o schema do zod
  function resetFields(fields?: [{ name: string; value: any }]) {
    if (fields) {
      fields.forEach((item) => {
        resetField(item.name, { defaultValue: item.value });
      });
    } else {
      let lista =
        schema?.["_getCached"]?.()?.keys ||
        schema?.["_def"]?.["schema"]?.["_def"]?.["schema"]?.["_cached"]?.keys ||
        [];

      lista?.forEach((a: string) => {
        resetField(a, { defaultValue: "" });
      });
    }
  }

  function submit(data: any, event: any) {
    onSubmit(data, resetFields, event);
  }

  function error(error: any, event: any) {
    console.warn(error);
    onError(error, event);
  }

  return (
    <FormProvider {...methods}>
      <form method="post" onSubmit={handleSubmit(submit, error)}>
        {children}
      </form>
    </FormProvider>
  );
};

export default ReactForm;
