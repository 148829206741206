import { IonIcon, IonItem, IonLabel } from "@ionic/react";
import { MarketingPages } from "../../models/types";
import React from "react";
import Marketing from "../../components/Marketing";
import CanaisAtendimento from "../../components/CanaisAtendimento";

interface ItemHome {
  title: string;
  url: string;
  icon: string;
}

const HomeNaoLogado: React.FC = () => {
  const pages: Array<ItemHome> = [
    {
      title: "Faça login",
      url: "/login",
      icon: "assets/icon/login.svg",
    },
    {
      title: "Emissão de 2ª via da fatura",
      url: "/segundaViaSimplificada",
      icon: "assets/icon/segunda_via.svg",
    },
    {
      title: "Encontre uma agência",
      url: "/agencia",
      icon: "assets/icon/encontre_agencia.svg",
    },
    {
      title: "Notícias e comunicados",
      url: "/noticias",
      icon: "assets/icon/noticias.svg",
    },
  ];

  return (
    <div className="flex flex-col space-y-4">
      <div className="card">
        <div className="grid grid-cols-2 md:grid-cols-4 justify-items-center">
          {pages.map((item, index) => {
            return (
              <IonItem
                routerLink={item.url}
                routerDirection="none"
                lines="none"
                detail={false}
                key={index}
                color="transparent"
                className="group hover:bg-primary hover:bg-opacity-10 rounded-lg"
              >
                <div className="text-center p-4">
                  <IonIcon
                    src={item.icon}
                    className="p-3 rounded-full bg-primary bg-opacity-20 opacity-90 group-hover:opacity-100 text-3xl transition duration-300 group-hover:scale-110"
                  />
                  <IonLabel className="ion-text-wrap">
                    <div className="text-sm">{item.title}</div>
                  </IonLabel>
                </div>
              </IonItem>
            );
          })}
        </div>
      </div>

      <Marketing tipo={MarketingPages.HOME_NAO_LOGADO}></Marketing>

      <CanaisAtendimento />
    </div>
  );
};

export default HomeNaoLogado;
