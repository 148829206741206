import { TIPOS_ORIGEM_OCORRENCIA } from "../../models/types";
import { useContaUsuario } from "../../contexts/contaUsuarioContext";
import { z } from "zod";
import { useHistory } from "react-router";

import Page from "../../components/Page";
import useNotification from "../../hooks/useNotification";
import ReactForm from "../../components/form/ReactForm";
import SubmitButton from "../../components/form/SubmitButton";
import SelectField from "../../components/form/SelectField";

export default function Vazamento() {
  const contaUsuarioContext = useContaUsuario();
  const navigate = useHistory();
  const notification = useNotification();

  function nextPage({ origemOcorrencia }: any) {
    if (origemOcorrencia === "conta") {
      //
      if (!contaUsuarioContext.contaSelecionada) {
        notification.showNecessarioSelecionarConta();
        return;
      }

      navigate.push("/vazamentoNaConta");
    } else {
      navigate.push("/vazamentoNoEndereco");
    }
  }

  const formSchema = z.object({
    origemOcorrencia: z
      .any()
      .refine((input) => input, "Selecione onde ocorre o vazamento.")
      .transform((input) => input.value),
  });

  return (
    <Page title="Comunicar vazamento">
      <div className="flex flex-col space-y-4">
        <ReactForm
          schema={formSchema}
          onSubmit={(e) => nextPage(e)}
          onError={(error, e) => {}}
        >
          <div className="card bg-opacity-70">
            <div className="card-title">Informe onde ocorre o vazamento</div>

            <div className="pb-4">
              <SelectField
                name="origemOcorrencia"
                placeHolder="Selecione"
                items={TIPOS_ORIGEM_OCORRENCIA}
              />
            </div>
          </div>

          <div className="pt-4">
            <SubmitButton label="Avançar" />
          </div>
        </ReactForm>
      </div>
    </Page>
  );
}
