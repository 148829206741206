import React, { useEffect, useState } from "react";
import { useContaUsuario } from "../contexts/contaUsuarioContext";
import { formatarData } from "../utils/functions";

const CardDadosConta: React.FC = () => {
  const { contaSelecionada } = useContaUsuario();
  const [enderecoConta, setEnderecoConta] = useState<string>("");

  useEffect(() => {
    let endereco = "";

    endereco = endereco.concat(
      contaSelecionada?.nomeLogradouro?.trim()
        ? `${contaSelecionada?.nomeLogradouro.trim()}`
        : ""
    );

    endereco = endereco.concat(
      contaSelecionada?.numeroImovel?.trim()
        ? `, nº ${contaSelecionada?.numeroImovel.trim()}`
        : ""
    );

    endereco = endereco.concat(
      contaSelecionada?.quadraImovel?.trim()
        ? `, QD. ${contaSelecionada?.quadraImovel.trim()}`
        : ""
    );

    endereco = endereco.concat(
      contaSelecionada?.loteImovel?.trim()
        ? `, LT. ${contaSelecionada?.loteImovel.trim()}`
        : ""
    );

    endereco = endereco.concat(
      contaSelecionada?.complementoEndereco?.trim()
        ? `, ${contaSelecionada?.complementoEndereco.trim()}`
        : ""
    );

    endereco = endereco.concat(
      contaSelecionada?.nomeBairro?.trim()
        ? `, ${contaSelecionada?.nomeBairro.trim()}`
        : ""
    );

    endereco = endereco.concat(
      contaSelecionada?.nomeCidade?.trim()
        ? `, ${contaSelecionada?.nomeCidade.trim()} - GO`
        : ""
    );

    endereco = endereco.concat(
      contaSelecionada?.codigoCep ? `, ${contaSelecionada?.codigoCep}` : ""
    );

    setEnderecoConta(endereco);
  }, [contaSelecionada]);

  return (
    <div className="card" hidden={!contaSelecionada}>
      <div className="card-title">Dados da conta</div>

      <div className="grid grid-cols-1 gap-4 text-sm">
        <div className="border-t-2 border-tertiary border-opacity-70"></div>
        <div className="text-secondary text-center">Endereço</div>
        <div className="text-slate-700 text-justify">{enderecoConta}</div>
        <div className="border-t-2 border-tertiary border-opacity-70"></div>

        <div className="grid grid-cols-2">
          <div className="flex flex-col items-center">
            <div className="text-secondary">Número</div>
            <div className="">{contaSelecionada?.numeroContaComDigito}</div>
          </div>
          <div className="flex flex-col items-center">
            <div className="text-secondary">Categoria</div>
            <div className="">{contaSelecionada?.categoria}</div>
          </div>
        </div>

        <div className="border-t-2 border-tertiary border-opacity-70"></div>
        <div className="text-center text-primary font-bold">Água</div>
        <div className="grid grid-cols-2">
          <div className="flex flex-col items-center">
            <div className="text-secondary">Situação</div>
            <div className="">{contaSelecionada?.situacaoLigacaoAgua}</div>
          </div>
          <div className="flex flex-col items-center">
            <div className="text-secondary">Data da ligação</div>
            <div className="">
              {formatarData(contaSelecionada?.dataPrimariaAgua)}
            </div>
          </div>
        </div>

        <div className="border-t-2 border-tertiary border-opacity-70"></div>
        <div className="text-center text-primary font-bold">Esgoto</div>
        <div className="grid grid-cols-2">
          <div className="flex flex-col items-center">
            <div className="text-secondary">Situação</div>
            <div className="">{contaSelecionada?.situacaoLigacaoEsgoto}</div>
          </div>
          <div className="flex flex-col items-center">
            <div className="text-secondary">Data da ligação</div>
            <div className="">
              {formatarData(contaSelecionada?.dataPrimariaEsgoto)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardDadosConta;
