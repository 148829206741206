import { IonIcon } from "@ionic/react";
import { personAddOutline } from "ionicons/icons";
import { useHistory } from "react-router";
import { useAuth } from "../../contexts/authContext";
import { useContaUsuario } from "../../contexts/contaUsuarioContext";
import { MarketingPages, TipoPessoa } from "../../models/types";
import CardFaturaAtual from "../../components/CardFaturaAtual";
import CardDadosConta from "../../components/CardDadosConta";
import DivLoading from "../../components/DivLoading";
import Marketing from "../../components/Marketing";

const HomeLogado: React.FC = () => {
  const { usuario } = useAuth();
  const { contaSelecionada } = useContaUsuario();

  const navigate = useHistory();

  const isHabilitarCadastro =
    usuario?.ativo === false && usuario?.clienteSaneago === true;

  const isNaoCliente =
    usuario?.ativo === false && usuario?.clienteSaneago === false;

  const isUsuarioAtivo =
    usuario?.ativo === true && usuario?.clienteSaneago === true;

  if (usuario?.ativo === undefined || usuario?.clienteSaneago === undefined) {
    return <DivLoading label="Consultando dados do usuário..." />;
  }

  return (
    <div className="flex flex-col space-y-4">
      <div className="card">
        <div>Bem-vindo(a),</div>
        <div className="text-primary font-medium text-lg">{usuario?.nome}</div>

        <div
          className="mt-4 text-slate-700"
          hidden={!(!contaSelecionada && isUsuarioAtivo)}
        >
          Selecione uma conta para acesso aos serviços da Agência Virtual da
          Saneago.
        </div>

        <div hidden={!isHabilitarCadastro}>
          <div className="mt-4 text-slate-700">
            Para acesso a todos os serviços, é necessário concluir o seu
            cadastro. Precisamos só de mais algumas informações.
          </div>
        </div>

        <div hidden={!isNaoCliente}>
          <div className="mt-4 text-red-600">
            Alguns serviços só estão disponíveis para quem possue conta/vínculo
            com a Saneago.
          </div>
        </div>
      </div>

      <div
        className="card-apresentacao"
        onClick={() =>
          usuario.tipoCliente === TipoPessoa.PESSOA_FISICA
            ? navigate.push("/fotosDocumento")
            : navigate.push("/dadosEmpresa")
        }
        hidden={!isHabilitarCadastro}
      >
        <div className="p-4">
          <IonIcon
            className="text-3xl"
            size="lg"
            icon={personAddOutline}
          ></IonIcon>

          <div className="font-medium text-lg">Finalizar Cadastro</div>
        </div>
      </div>

      <div hidden={!contaSelecionada}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <CardFaturaAtual></CardFaturaAtual>
          <CardDadosConta></CardDadosConta>
        </div>
      </div>

      <Marketing tipo={MarketingPages.HOME_LOGADO}></Marketing>
    </div>
  );
};

export default HomeLogado;
