import {
  IonAccordion,
  IonAccordionGroup,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { classNames } from "primereact/utils";

export interface AccordionProps {
  title?: string;
  hidden?: boolean;
  borda?: boolean;
  open?: boolean;
  children?: JSX.Element;
}

export default function Accordion({
  title,
  hidden,
  borda,
  open,
  children,
}: AccordionProps) {
  //
  const cardClass = classNames({
    "bg-white bg-opacity-40 w-full rounded-lg shadow-md": !borda,
    "bg-white bg-opacity-40 w-full rounded-lg shadow-sm border-slate-200 border-2":
      borda,
  });

  return (
    <div hidden={hidden}>
      <IonAccordionGroup expand="compact" value={open ? "first" : ""}>
        <IonAccordion value="first" className={cardClass}>
          <IonItem
            slot="header"
            color="transparent"
            className="bg-white bg-opacity-0 text-primary font-bold"
          >
            <IonLabel>{title}</IonLabel>
          </IonItem>
          <div slot="content" className="p-4 pt-0">
            {children}
          </div>
        </IonAccordion>
      </IonAccordionGroup>
    </div>
  );
}
