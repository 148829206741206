import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FaturaOrgaoPublico } from "../models/types";
import Button from "./Button";

export interface SimpleTableProps {
  data?: FaturaOrgaoPublico[];
  hidden?: boolean;
  onGerarFatura?(): void;
}

const CardFaturaOrgaoPublico: React.FC<SimpleTableProps> = ({
  data,
  onGerarFatura,
  hidden,
}) => {
  const [listaResultados, setListaResultados] = useState<FaturaOrgaoPublico[]>(
    []
  );

  useEffect(() => {
    data && setListaResultados(data);
  }, [data]);

  return (
    <div hidden={hidden || !(data && data?.length > 0)}>
      <div className="card-borda flex flex-col space-y-4 text-center">
        <Button
          label="Gerar fatura"
          onClick={() => {
            if (onGerarFatura) {
              onGerarFatura();
            }
          }}
        />

        <DataTable
          value={listaResultados}
          responsiveLayout="scroll"
          size="small"
          stripedRows
        >
          <Column
            field="numeroConta"
            header="Número da conta"
            style={{ fontSize: "0.9rem" }}
          ></Column>
          <Column
            field="nomeTitularUsuario"
            header="Titular"
            style={{ fontSize: "0.9rem" }}
          ></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default CardFaturaOrgaoPublico;
