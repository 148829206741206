import { IonLabel, IonSpinner } from "@ionic/react";

export interface Props {
  label: string;
}

export default function DivLoading(props: Props) {
  return (
    <div className="flex flex-col justify-center items-center p-4">
      <div className="pb-4">
        <IonSpinner name="bubbles"></IonSpinner>
      </div>

      <IonLabel className="text-center">{props.label}</IonLabel>
    </div>
  );
}
