export enum MarketingPages {
  HOME_LOGADO = "1",
  HOME_NAO_LOGADO = "2",
}

export enum TipoPessoa {
  PESSOA_FISICA = "F",
  PESSOA_JURIDICA = "J",
}

export enum ApiRoutes {
  ECO_FATURAMENTO = "REACT_APP_ECO_FATURAMENTO",
  ECO_FATURAMENTO_EXTERNO = "REACT_APP_ECO_FATURAMENTO_EXTERNO",
  ECO_ATENDIMENTO = "REACT_APP_ECO_ATENDIMENTO",
  ECO_ATENDIMENTO_EXTERNO = "REACT_APP_ECO_ATENDIMENTO_EXTERNO",
  ECO_ARRECADACAO = "REACT_APP_ECO_ARRECADACAO",
  ECO_ARRECADACAO_EXTERNO = "REACT_APP_ECO_ARRECADACAO_EXTERNO",
  ESI_USUARIO_AGENCIA_VIRTUAL = "REACT_APP_ESI_USUARIO_AGENCIA_VIRTUAL",
  KEYCLOAK = "REACT_APP_KEYCLOAK",
  NOTICIAS_APP = "REACT_APP_NOTICIAS_APP",
  NOTICIAS = "REACT_APP_NOTICIAS",
  SAN_CADASTRO = "REACT_APP_SAN_CADASTRO",
  SITE = "REACT_APP_SITE",
}

export enum Mensagens {
  USUARIO_INVALIDO = "Usuário ou senha inválidos.",
  CLIENTE_JA_CADASTRADO = "Cliente já consta em nossa base de dados.",
  CLIENTE_NAO_CADASTRADO_NAO_CONFIRMADO = "Cliente não encontrado e/ou cadastro não confirmado.",
  ERRO_REQUISICAO = "Ocorreu um erro inesperado na requisição. Tente novamente mais tarde.",
  ERRO_COM_DETALHES = "Ocorreu um erro. Atenção aos detalhes: ",
  ERRO_INESPERADO = "Ocorreu um erro inesperado. Por favor tente novamente.",
  ERRO_INESPERADO_CEP = "Ocorreu um erro inesperado ao consultar o CEP. Por favor verifique o CEP e tente novamente.",
  ERRO_INESPERADO_CADASTRO = "Ocorreu um erro inesperado ao salvar o seu cadastro. Por favor, tente novamente mais tarde.",
  ERRO_INESPERADO_CONCLUIR_CADASTRO = "Ocorreu um erro inesperado ao concluir o seu cadastro. Por favor, tente novamente mais tarde.",
  CODIGO_INCORRETO = "Código incorreto",
  SUCESSO_REQUISICAO = "Dados confirmados.",
  VERIFICANDO_DADOS = "Verificando dados...",
  INFORME_CODIGO = "Informe o código com 4 digitos.",
  ERRO_TITULO = "Ops!",
  SUCESSO_TITULO = "Sucesso!",
  CONSULTANDO = "Consultando...",
  SALVANDO = "Salvando...",
  ENVIANDO_EMAIL = "Enviando e-mail...",
  VALIDANDO_CREDENCIAIS = "Validando credenciais...",
  IR_LOGIN = "Ir para login",
  TENTAR_NOVAMENTE = "Tentar novamente",
  ATENCAO = "Atenção!",
  ESQUECI_SENHA = "Esqueci minha senha",
  NECESSARIO_SELECIONAR_CONTA = "É necessário selecionar uma conta.",
  SOLICITACAO_ABERTA = "Já existe uma solicitação de atendimento aberta para este serviço.",
  NO_ENDERECO_CONTA = "No endereço da conta selecionada",
  OUTRO_ENDERECO = "Em outro endereço",
  PROBLEMAS_GERAR_PROTOCOLO = "Não foi possível gerar o protocolo do atendimento.",
  NENHUM_REGISTRO = "Nenhum registro encontrado.",
  NENHUM_REGISTRO_CONTA = "Nenhum registro encontrado para a conta selecionada.",
  NENHUMA_CONTA_ENCONTRADA = "Nenhuma conta foi encontrada para esse usuário.",
  VEJA_POLITICA_PRIVACIDADE = "Veja aqui nossa política de privacidade",
  RECAPTCHA_INDISPONIVEL = "ReCaptcha ainda não disponível!",
  FATURA_GERADA = "Fatura gerada, fazendo download do pdf.",
  CADASTRO_FINALIZADO_SUCESSO = "Cadastro finalizado com sucesso. Por favor entre novamente.",
  NENHUM_RESULTADO_ENCONTRADO = "Nenhum resultado encontrado para os dados informados.",
  RECAPTCHA_INVALIDO = "reCAPTCHA não passou na verificação. Por favor, tente novamente. ",
  NECESSARIO_VINCULO_SANEAGO = "Para realização do cadastro completo é necessário possuir vínculo com a Saneago. O CPF/CNPJ informado não consta em nosso sistema. Verifique se o número do CPF/CNPJ foi informado corretamente ou entre em contato com a Central de Atendimento ao Cliente pelo telefone 0800 645 0115 para mais informações.",
  MSGE0099_FALTA_AGUA_NAO_POSSIVEL = "Não é possível registrar a falta de água pois consta em nossos registros que a ligação não está ativa.",
  MSGE0100_RECLAMACAO_QUALIDADE_AGUA_NAO_POSSIVEL = "Não é possível registrar a reclamação sobre a qualidade da água pois consta em nossos registros que a ligação não está ativa.",
  MSGE0101_VAZAMENTO_NAO_POSSIVEL = "Não é possível registrar o vazamento pois consta em nossos registros que a ligação não está ativa.",
  MSGE0103_SEM_REGISTRO_CORTE = "Não é possível solicitar a religação pois não consta registro de corte em nosso sistema.",
}

export enum SituacaoLigacaoAgua {
  LIGADA = 1,
  CORTADA = 2,
}

export enum StatusFaturaDigital {
  NAO_ADERIU = "NAO_ADERIU",
  AGUARDANDO_CONFIRMACAO_EMAIL = "AGUARDANDO_CONFIRMACAO_EMAIL",
  ADERIU = "ADERIU",
}

export const TIPOS_TELEFONE = [
  {
    value: "RESIDENCIAL",
    label: "Residencial",
  },
  {
    value: "COMERCIAL",
    label: "Comercial",
  },
  {
    value: "CELULAR",
    label: "Celular",
  },
  {
    value: "CONTATO",
    label: "Contato",
  },
];

export const CODIGO_CONTATO_AGENCIA_VIRTUAL_MOBILE = 54; // 54 = Agência virtual/Mobile/Chat
export const MATRICULA_RESPONSAVEL_PROTOCOLO = "MSI0004";
export const MATRICULA_SOLICITANTE = "MSI0004";
export const CODIGO_UNIDADE_ATENDIMENTO = "G0106";
export const POLITICA_PRIVACIDADE =
  "https://www.saneago.com.br/app/App_Politica_Privacidade.html";

export const TIPOS_ORIGEM_OCORRENCIA = [
  {
    value: "conta",
    label: Mensagens.NO_ENDERECO_CONTA,
  },
  { value: "endereco", label: Mensagens.OUTRO_ENDERECO },
];

export const TIPOS_RECLAMACAO_QUALIDADE_AGUA = (() => {
  const lista = [
    "Água suja",
    "Água com gosto",
    "Água com cheiro",
    "Água com gases (cor esbranquiçada/presença de oxigênio na tubulação)",
  ];

  return lista.map((item) => {
    return { value: item, label: item };
  });
})();

export const TIPOS_LOCAL_VAZAMENTO = [
  { value: "HIDROMETRO", label: "Hidrômetro" },
  { value: "LEITO_RUA", label: "Leito de rua" },
  { value: "CALCADA", label: "Passeio(calçada)" },
  { value: "NAO_IDENTIFICADO", label: "Não identificado" },
];

export const TIPOS_PAVIMENTACAO = (() => {
  const lista = [
    "Pavimentado",
    "Não pavimentado",
    "Bloquete",
    "Não identificado",
  ];

  return lista.map((item) => {
    return { value: item, label: item };
  });
})();

export const TIPOS_PASSEIO = (() => {
  const lista = [
    "Cimento",
    "Não pavimentado",
    "Piso especial",
    "Não identificado",
  ];

  return lista.map((item) => {
    return { value: item, label: item };
  });
})();

export const TIPOS_DENUNCIA = [
  { value: "agua", label: "Água" },
  { value: "esgoto", label: "Esgoto" },
];

export const TIPOS_IRREGULARIDADE_ESGOTO = (() => {
  const lista = [
    "Lançamento de água fluvial na rede coletora de esgotos sanitários/domésticos",
    "Lançamento de esgotos sanitários/domésticos na galeria de águas pluviais",
    "Ligação clandestina - executada pelo cliente",
  ];

  return lista.map((item) => {
    return { value: item, label: item };
  });
})();

export const TIPOS_IRREGULARIDADE_AGUA = (() => {
  const lista = [
    {
      label: "By-Pass",
      descricao:
        "Derivação existente no ramal de ligação ou padrão antes do hidrômetro, com objetivo de utilização de água sem o registro da mesma.",
    },
    {
      label: "Depreciação do hidrômetro",
      descricao:
        "É o ato de destruir o hidrômetro, inviabilizando o seu funcionamento normal de registro de consumo.",
    },
    {
      label: "Fornecimento de água a terceiro",
      descricao:
        "Extensão das instalações prediais para abastecer economias localizadas em lotes distintos, ou mesmo dentro do lote quando houver ligações independentes para economias distintas.",
    },
    {
      label: "Fusão de ligação",
      descricao:
        "Dois ou mais lotes (não sendo geminados) com abastecimento de uma só ligação.",
    },
    {
      label: "Hidrômetro invertido",
      descricao:
        "É o ato de retirar o hidrômetro e reinstalá-lo de forma invertida com relação a instalação normal.",
    },
    {
      label: "Ligação direta da rede",
      descricao:
        "Ligação feita direto da rede distribuidora de água, executada pelo cliente.",
    },
  ];

  return lista.map((item) => {
    return { value: item.label, label: item.label, detalhes: item.descricao };
  });
})();

export const TIPOS_LOCAL_OBSTRUCAO_ESGOTO = (() => {
  const lista = [
    {
      label: "Caixa de ligação",
      descricao: "Primeira caixa no passeio ou dentro do lote.",
    },
    {
      label: "Coletor predial",
      descricao: "Ramal que interliga a rede até a primeira caixa do imóvel.",
    },
    {
      label: "Poço de visita",
      descricao:
        "Unidade do sistema coletor de esgoto, localiza-se no leito da rua, normalmente nos cruzamentos.",
    },
    {
      label: "Rede coletora",
      descricao: "Localiza-se no leito da rua ou no passeio.",
    },
  ];

  return lista.map((item) => {
    return { value: item.label, label: item.label, detalhes: item.descricao };
  });
})();

export const TIPOS_UF = (() => {
  const lista = [
    "AC",
    "AL",
    "AP",
    "AM",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MT",
    "MS",
    "MG",
    "PA",
    "PB",
    "PR",
    "PE",
    "PI",
    "RJ",
    "RN",
    "RS",
    "RO",
    "RR",
    "SC",
    "SP",
    "SE",
    "TO",
  ];

  return lista.map((item) => {
    return { value: item, label: item };
  });
})();

export const TIPOS_PESSOA = [
  {
    value: TipoPessoa.PESSOA_FISICA,
    label: "Pessoa física",
  },
  {
    value: TipoPessoa.PESSOA_JURIDICA,
    label: "Pessoa jurídica",
  },
];

export interface Auth {
  access_token: string;
  refresh_token: string;
  timestampFimSessao?: number;
  timestampInicioSessao?: number;
}

export interface Usuario {
  cpfCnpj: string;
  email: string;
  tipoCliente: string;
  senha?: string;
  ativo?: boolean;
  clienteSaneago?: boolean;
  nome?: string;
  emailOfuscado?: string;
}

export interface DadosConclusaoUsuario {
  cep: number;
  codigoEstadoCivil: number;
  dataEmissaoDocumento: string;
  dataNascimento: string;
  dataValidadeDocumento: string;
  dddTelefoneCelular: string;
  descricaoComplementoLogradouro: string;
  nomeBairro: string;
  nomeCompletoMae: string;
  nomeLogradouro: string;
  numeroDocumento: string;
  numeroImovel: string;
  numeroLote: string;
  numeroQuadra: string;
  numeroTelefoneCelular: string;
  sexo: string;
  siglaUnidadeFederativaDocumento: string;
}

export interface SegundaVia {
  mensagem: string;
  listaResultados: Debito[];
}

export interface FaturaOrgaoPublico {
  codigoOrgaoAgrupador: number;
  nomeTitularUsuario: string;
  numeroConta: number;
}

export interface Debito {
  codigoTipoDocumentoFiscal: number;
  numeroDocumentoFiscal: number;
  numeroConta: number;
  dataVencimento: string;
  anoMesReferencia: number;
  codigoOrigemDebito: number;
  valorDebito: number;
  situacaoDebito: string;
  tipoBaixaDebito: any;
  dataBaixaDebito: any;
  dataPagamentoDebito: any;
  statusFatura: string;
  codigoOrgaoAgrupador: any;
  quantidadeDiasVencido: number;
  consumoMedido: number;
  dataEmissaoDocumentoFiscal: string;
  numeroDocumentoFormatado: string;
  referenciaFormatada: string;
  podeGerarSegundaVia: boolean;
}

export interface Conta {
  numeroConta?: number;
  codigoGrupoFaturamento?: number;
  codigoRotaCodificacao?: number;
  codigoSituacaoLigacaoAgua?: number;
  numeroContaComDigito?: string;
  nomeTitular?: string;
  nomeProprietario?: string;
  situacaoLigacaoAgua?: string;
  dataLigacaoAgua?: string;
  dataPrimariaAgua?: string;
  situacaoLigacaoEsgoto?: string;
  dataLigacaoEsgoto?: string;
  dataPrimariaEsgoto?: string;
  codigoCidade?: number;
  codigoBairro?: number;
  codigoLogradouro?: number;
  nomeCidade?: string;
  nomeBairro?: string;
  nomeLogradouro?: string;
  numeroImovel?: string;
  quadraImovel?: string;
  loteImovel?: string;
  complementoEndereco?: string;
  codigoCep?: number;
  percentualFaturamentoEsgoto?: number;
  categoria?: string;
  isContaMacro?: boolean;
  possuiEnderecoAlternativo?: boolean;
  flagAtivacaoTaxaColetaLixo?: string;
  statusTaxaLixo?: string;
  valorMensalTaxaColetaLixo?: unknown;
  valorFaixaConsumoTaxaColetaLixo?: string;
  categoriaMaiorPeso?: string;
  motivoTaxaLixo?: unknown;
  dataInativacaoTaxaLixo?: unknown;
  justificativaTaxaLixo?: unknown;
  emailAdesaoFaturaDigital?: string;
  situacaoAdesaoFaturaDigital?: string;
  emailResumoFaturamentoIndividualizado?: string;
}

export interface HistoricoConsumo {
  consumoEstimado: number;
  consumoMedido: number;
  consumoMedio: number;
  historicoConsumo: HistoricoConsumoFaturado[];
}

export interface HistoricoConsumoFaturado {
  consumo: number;
  referencia: string;
  valor: number;
}

export interface Item {
  label: string;
  value: string | number;
  detalhes?: any;
  disabled?: boolean;
}

export interface NoticiaComunicado {
  codigo: number;
  titulo: string;
  dataEdicao: string;
  resumo: string;
  conteudo: string;
  tipo: number;
  tipoDestaque: number;
  tag: string;
  urlImagemCapa: string;
}

export interface UnidadeOrganizacional {
  codigo: string;
  nome: string;
  sigla: string;
  codigoCidade: number;
  cidade: string;
  bairro?: string;
  logradouro?: string;
  numeroLogradouro: string;
  telefone: string;
}

export interface ProtocoloListItem {
  numeroProtocolo: number;
  dataAbertura: string;
  dataHoraSolicitacao?: unknown;
  identificadorProtocoloAtendimento?: unknown;
  anoProtocoloAtendimento?: unknown;
  numeroProtocoloAtendimento?: unknown;
}

export interface ProtocoloItem {
  numeroProtocolo: number;
  dataHoraAbertura: string;
  anoProtocolo: number;
  motivoContato: string;
  dataHoraInicioAtendimento: unknown;
  dataHoraFimAtendimento: unknown;
  acoesRealizadas: ProtocoloAcao[];
  numeroProtocoloAtendimentoFormatado: string;
  numeroConta?: number | null;
}

export interface ProtocoloAcao {
  dataHoraExecucao: string;
  numeroRegistroAtendimento: number;
  situacaoReprogramacao: string;
  servicoPrestado: string;
  dataEmissaoInicialRA: string;
  horaEmissaoInicialRA: number;
}

export interface LaudosAfericao {
  mensagem: string;
  listaResultados: LaudoAfericao[];
}

export interface LaudoAfericao {
  numeroLaudoAfericao: number;
  dataSolicitacao: string;
  anoLaudoAfericao: number;
}

export interface CadastroFaturaDigitalData {
  numeroConta: number;
  tipoCliente: string;
  numeroCpfCnpj: string;
  email: string;
  telefones: {
    ddd: number;
    numero: number;
    tipo: string;
    possuiWhats: boolean;
  }[];
  matriculaSolicitante: string;
}

export interface ProtocoloParam {
  numeroConta: number | null;
  dddTelefoneContato: number | null;
  numeroTelefoneContato: number | null;
}

export interface ProtocoloAnonimo {
  reCaptchaToken: string;
}

export interface MenuItemPage {
  title: string;
  url: string;
  icon: string;
  desabilitar?: boolean;
  remover?: boolean;
  removeSelectedCSS?: boolean;
  onClick?: Function; // caso queira realizar alguma acao no clik do item do menu, passe uma função como parametro
}
