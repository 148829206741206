import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../../contexts/authContext";

const RestrictRoute = ({ component: Component, ...rest }: any) => {
  const { isLogged, usuario } = useAuth();

  // rotas restritas precisam que o usuario esteja logado e com o cadastro completo finalizado,
  // caso não esteja redireciona para a home onde ele tem o botão de finalizar cadastro
  // caso não esteja nem logado redireciona para o login
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogged && usuario?.ativo && usuario?.clienteSaneago ? (
          <Component {...props} />
        ) : isLogged ? (
          <Redirect to="/home" />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default RestrictRoute;
