import { createContext, useContext, useEffect, useState } from "react";
import { Preferences } from "@capacitor/preferences";
import { delay, isMobile } from "../utils/functions";
import { useAuth } from "./authContext";
import { useLocation } from "react-router";

export interface PoliticaPrivacidadeContextData {
  aceitePolitica: boolean;
  aceitarPolitica(): void;
  recusarPolitica(): void;
}

const PoliticaPrivacidadeContext =
  createContext<PoliticaPrivacidadeContextData>(
    {} as PoliticaPrivacidadeContextData
  );

export const PoliticaPrivacidadeProvider = ({
  children,
}: {
  children: JSX.Element[];
}) => {
  const key = "termo-aceite-politica-saneago";
  const [aceitePolitica, setAceitePolitica] = useState<boolean>(true);
  const { logout } = useAuth();

  const location = useLocation();

  useEffect(() => {
    if (isMobile() && location.pathname === "/") {
      setTimeout(() => {
        // espera a animacao da versao mobile finalizar
        consultarPolitica();
      }, 3500);

      return;
    }

    consultarPolitica();
  }, [location.pathname]);

  async function consultarPolitica() {
    await delay(200);

    const { value } = await Preferences.get({ key: key });
    setAceitePolitica(value === "true");
  }

  async function aceitarPolitica() {
    await Preferences.set({ key: key, value: "true" });
    setAceitePolitica(true);
  }

  async function recusarPolitica() {
    await Preferences.remove({ key: key });
    logout();
  }

  return (
    <PoliticaPrivacidadeContext.Provider
      value={{
        aceitePolitica,
        aceitarPolitica,
        recusarPolitica,
      }}
    >
      {children}
    </PoliticaPrivacidadeContext.Provider>
  );
};

export function usePoliticaPrivacidade() {
  return useContext(PoliticaPrivacidadeContext);
}
