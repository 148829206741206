import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Route } from "react-router-dom";
import { AuthProvider } from "./contexts/authContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ContaUsuarioProvider } from "./contexts/contaUsuarioContext";
import { PoliticaPrivacidadeProvider } from "./contexts/politicaPrivacidadeContext";
import { StatusBar } from "@capacitor/status-bar";
import { locale, addLocale } from "primereact/api";
import { ProtocoloProvider } from "./contexts/protocoloContext";
import pt from "./lib/primelocale/pt.json";
import Menu from "./components/Menu";
import PrivateRoute from "./components/auth/PrivateRoute";
import RestrictRoute from "./components/auth/RestrictRoute";
import PoliticaPrivacidade from "./components/PoliticaPrivacidade";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/tailwind.css";

// cadastro inicial
import Cadastro from "./pages/cadastroInicial/Cadastro";
import ConfirmaEmail from "./pages/cadastroInicial/ConfirmaEmail";
import Finalizar from "./pages/cadastroInicial/Finalizar";

// cadastro completo
import FotosDocumento from "./pages/recadastro/FotosDocumento";
import FotoPessoal from "./pages/recadastro/FotoPessoal";
import DadosPessoais from "./pages/recadastro/DadosPessoais";
import Endereco from "./pages/recadastro/Endereco";
import DadosEmpresa from "./pages/recadastro/DadosEmpresa";

// segunda via
import SegundaViaSimplificada from "./pages/segundaVia/SegundaViaSimplificada";
import SegundaViaPage from "./pages/segundaVia/SegundaViaPage";
import SegundaViaFatura from "./pages/segundaVia/SegundaViaFatura";

// fatura analitica orgao publico
import FaturaOrgaoPublico from "./pages/faturaAnalitica/FaturaOrgaoPublico";

// denuncia irregularidade
import DenunciaIrregularidade from "./pages/denuncia/DenunciaIrregularidade";
import DenunciaIrregularidadeNaConta from "./pages/denuncia/DenunciaIrregularidadeNaConta";
import DenunciaIrregularidadeNoEndereco from "./pages/denuncia/DenunciaIrregularidadeNoEndereco";

// reclamacao qualidade agua
import ReclamacaoAgua from "./pages/reclamacaoAgua/ReclamacaoAgua";
import ReclamacaoAguaNaConta from "./pages/reclamacaoAgua/ReclamacaoAguaNaConta";
import ReclamacaoAguaNoEndereco from "./pages/reclamacaoAgua/ReclamacaoAguaNoEndereco";

// comunicar vazamento
import Vazamento from "./pages/vazamento/Vazamento";
import VazamentoNaConta from "./pages/vazamento/VazamentoNaConta";
import VazamentoNoEndereco from "./pages/vazamento/VazamentoNoEndereco";

// recuperar senha
import RecuperarSenha from "./pages/recuperarSenha/RecuperarSenha";
import RecuperarSenhaConfirmaEmail from "./pages/recuperarSenha/RecuperarSenhaConfirmaEmail";
import NovaSenha from "./pages/recuperarSenha/NovaSenha";

// consulta de protocolos
import Protocolos from "./pages/protocolos/Protocolos";
import ProtocoloDetalhe from "./pages/protocolos/ProtocoloDetalhe";

// encontre uma agencia
import Agencia from "./pages/atendimento/Agencia";
import UnidadesInterior from "./pages/atendimento/UnidadesInterior";
import UnidadesSaneago from "./pages/atendimento/UnidadesSaneago";

// noticias
import Noticias from "./pages/noticias/Noticias";
import NoticiaDetalhe from "./pages/noticias/NoticiaDetalhe";

// certidao negativa
import CertidaoNegativaDebitos from "./pages/CertidaoNegativaDebitos";
import ValidarCertidaoNegativa from "./pages/ValidarCertidaoNegativa";

// desobstrucao esgoto
import DesobstrucaoEsgoto from "./pages/desobstrucaoEsgoto/DesobstrucaoEsgoto";
import DesobstrucaoEsgotoNoEndereco from "./pages/desobstrucaoEsgoto/DesobstrucaoEsgotoNoEndereco";
import DesobstrucaoEsgotoNaConta from "./pages/desobstrucaoEsgoto/DesobstrucaoEsgotoNaConta";

// consumo e faturamento individulizados
import ConsumoFaturamentoIndividualizados from "./pages/individualizados/ConsumoFaturamentoIndividualizados";
import CadastroEmailIndividualizados from "./pages/individualizados/CadastroEmailIndividualizados";
import AlteracaoEmailIndividualizados from "./pages/individualizados/AlteracaoEmailIndividualizados";

// fatura digital
import FaturaDigital from "./pages/faturaDigital/FaturaDigital";
import FaturaDigitalConfirmarAdesao from "./pages/faturaDigital/FaturaDigitalConfirmarAdesao";

// pages
import LoginPage from "./pages/LoginPage";
import Home from "./pages/home/Home";
import HistoricoConsumoFaturado from "./pages/HistoricoConsumoFaturado";
import UltimasFaturasPage from "./pages/UltimasFaturas";
import ComunicarFaltaAgua from "./pages/ComunicarFaltaAgua";
import Documentos from "./pages/Documentos";
import CentroPreferencias from "./pages/CentroPreferencias";
import LaudoAfericao from "./pages/laudoAfericao/LaudoAfericao";
import FramePage from "./pages/FramePage";
import Popup from "./components/Popup";
import SolicitarReligacaoAgua from "./pages/SolicitarReligacaoAgua";

setupIonicReact();

addLocale("pt", pt.pt);
locale("pt");

const App: React.FC = () => {
  StatusBar.setBackgroundColor({ color: "#00649C" }).catch(() => {});

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        cacheTime: 0,
      },
    },
  });

  // configuracoes globais
  setupIonicReact({
    loadingSpinner: "lines",
  });

  return (
    <QueryClientProvider client={queryClient}>
      <IonApp>
        <IonReactRouter>
          <AuthProvider>
            <ContaUsuarioProvider>
              <ProtocoloProvider>
                <PoliticaPrivacidadeProvider>
                  <Popup />
                  <PoliticaPrivacidade />

                  <IonSplitPane contentId="main">
                    <Menu />

                    <IonRouterOutlet id="main">
                      <Route path="/" exact={true} component={Home} />
                      <Route path="/home" exact={true} component={Home} />

                      <Route path="/login" exact={true} component={LoginPage} />

                      {/* segunda via */}
                      <RestrictRoute
                        path="/segundaVia"
                        exact={true}
                        component={SegundaViaPage}
                      />

                      <RestrictRoute
                        path="/segundaViaFatura"
                        exact={true}
                        component={SegundaViaFatura}
                      />

                      <Route
                        path="/segundaViaSimplificada"
                        exact={true}
                        component={SegundaViaSimplificada}
                      />

                      {/* fatura analitica orgao publico */}
                      <Route
                        path="/faturaOrgaoPublico"
                        exact={true}
                        component={FaturaOrgaoPublico}
                      />

                      {/* reclamacao qualidade agua */}
                      <PrivateRoute
                        path="/reclamacaoAgua"
                        exact={true}
                        component={ReclamacaoAgua}
                      />

                      <PrivateRoute
                        path="/reclamacaoAguaNoEndereco"
                        exact={true}
                        component={ReclamacaoAguaNoEndereco}
                      />

                      <RestrictRoute
                        path="/reclamacaoAguaNaConta"
                        exact={true}
                        component={ReclamacaoAguaNaConta}
                      />

                      {/* comunicar vazamento */}
                      <PrivateRoute
                        path="/vazamento"
                        exact={true}
                        component={Vazamento}
                      />

                      <PrivateRoute
                        path="/vazamentoNoEndereco"
                        exact={true}
                        component={VazamentoNoEndereco}
                      />

                      <RestrictRoute
                        path="/vazamentoNaConta"
                        exact={true}
                        component={VazamentoNaConta}
                      />

                      {/* denuncia de irregularidade */}
                      <RestrictRoute
                        path="/denuncia"
                        exact={true}
                        component={DenunciaIrregularidade}
                      />

                      <Route
                        path="/denunciaNoEndereco"
                        exact={true}
                        component={DenunciaIrregularidadeNoEndereco}
                      />

                      <RestrictRoute
                        path="/denunciaNaConta"
                        exact={true}
                        component={DenunciaIrregularidadeNaConta}
                      />

                      {/* desobstrucao esgoto */}
                      <PrivateRoute
                        path="/desobstrucaoEsgoto"
                        exact={true}
                        component={DesobstrucaoEsgoto}
                      />

                      <PrivateRoute
                        path="/desobstrucaoEsgotoNoEndereco"
                        exact={true}
                        component={DesobstrucaoEsgotoNoEndereco}
                      />

                      <RestrictRoute
                        path="/desobstrucaoEsgotoNaConta"
                        exact={true}
                        component={DesobstrucaoEsgotoNaConta}
                      />

                      {/* cadastro inicial*/}
                      <Route
                        path="/cadastro"
                        exact={true}
                        component={Cadastro}
                      />

                      <Route
                        path="/confirmarEmail"
                        exact={true}
                        component={ConfirmaEmail}
                      />

                      <Route
                        path="/finalizar"
                        exact={true}
                        component={Finalizar}
                      />

                      {/* cadastro completo */}
                      <PrivateRoute
                        path="/fotosDocumento"
                        exact={true}
                        component={FotosDocumento}
                      />

                      <PrivateRoute
                        path="/fotoPessoal"
                        exact={true}
                        component={FotoPessoal}
                      />

                      <PrivateRoute
                        path="/dadosPessoais"
                        exact={true}
                        component={DadosPessoais}
                      />

                      <PrivateRoute
                        path="/endereco"
                        exact={true}
                        component={Endereco}
                      />

                      <PrivateRoute
                        path="/dadosEmpresa"
                        exact={true}
                        component={DadosEmpresa}
                      />

                      {/* recuperar senha*/}
                      <Route
                        path="/recuperar"
                        exact={true}
                        component={RecuperarSenha}
                      />

                      <Route
                        path="/recuperarConfirmaEmail"
                        exact={true}
                        component={RecuperarSenhaConfirmaEmail}
                      />

                      <Route
                        path="/novaSenha"
                        exact={true}
                        component={NovaSenha}
                      />

                      {/* consulta de protocolos */}
                      <PrivateRoute
                        path="/protocolos"
                        exact={true}
                        component={Protocolos}
                      />

                      <PrivateRoute
                        path="/protocoloDetalhe"
                        exact={true}
                        component={ProtocoloDetalhe}
                      />

                      {/* encontre uma agencia */}
                      <Route path="/agencia" exact={true} component={Agencia} />

                      <Route
                        path="/unidadesInterior"
                        exact={true}
                        component={UnidadesInterior}
                      />

                      <Route
                        path="/unidadesSaneago"
                        exact={true}
                        component={UnidadesSaneago}
                      />

                      {/* noticias */}
                      <Route
                        path="/noticias"
                        exact={true}
                        component={Noticias}
                      />

                      <Route
                        path="/noticiaDetalhe"
                        exact={true}
                        component={NoticiaDetalhe}
                      />

                      {/* certidao negativa */}
                      <RestrictRoute
                        path="/certidaoNegativaDebitos"
                        exact={true}
                        component={CertidaoNegativaDebitos}
                      />

                      <Route
                        path="/validarCertidaoNegativa"
                        exact={true}
                        component={ValidarCertidaoNegativa}
                      />

                      {/* historico consumo faturado */}
                      <RestrictRoute
                        path="/historico"
                        exact={true}
                        component={HistoricoConsumoFaturado}
                      />

                      {/* ultimas faturas */}
                      <RestrictRoute
                        path="/ultimasFaturas"
                        exact={true}
                        component={UltimasFaturasPage}
                      />

                      {/* comunicar falta de agua */}
                      <RestrictRoute
                        path="/comunicarFaltaAgua"
                        exact={true}
                        component={ComunicarFaltaAgua}
                      />

                      {/* faturamento individualizados */}
                      <RestrictRoute
                        path="/individualizados"
                        exact={true}
                        component={ConsumoFaturamentoIndividualizados}
                      />

                      <RestrictRoute
                        path="/cadastroEmailIndividualizados"
                        exact={true}
                        component={CadastroEmailIndividualizados}
                      />

                      <RestrictRoute
                        path="/alteracaoEmailIndividualizados"
                        exact={true}
                        component={AlteracaoEmailIndividualizados}
                      />

                      {/* documentos e anexos */}
                      <Route
                        path="/documentos"
                        exact={true}
                        component={Documentos}
                      />

                      {/* centro de preferencias */}
                      <Route
                        path="/preferencias"
                        exact={true}
                        component={CentroPreferencias}
                      />

                      {/* laudo de afericao */}
                      <RestrictRoute
                        path="/laudoAfericao"
                        exact={true}
                        component={LaudoAfericao}
                      />

                      {/* fatura digital */}
                      <RestrictRoute
                        path="/faturaDigital"
                        exact={true}
                        component={FaturaDigital}
                      />

                      <Route
                        path="/faturaDigitalConfirmarAdesao"
                        exact={true}
                        component={FaturaDigitalConfirmarAdesao}
                      />

                      {/* religacao Agua */}
                      {/* <RestrictRoute
                        path="/religacaoAgua"
                        exact={true}
                        component={SolicitarReligacaoAgua}
                      /> */}

                      {/* GED  */}
                      <Route path="/ged" exact={true}>
                        <FramePage
                          title="Consulta processos GED"
                          src="https://www.saneago.com.br/docflow/xhtml/docflow/consultaPublica/consultaPublicaWeb.jsf"
                        />
                      </Route>

                      {/* rota não encontrada 404 redireciona/carrega a home */}
                      <Route component={Home} />
                    </IonRouterOutlet>
                  </IonSplitPane>
                </PoliticaPrivacidadeProvider>
              </ProtocoloProvider>
            </ContaUsuarioProvider>
          </AuthProvider>
        </IonReactRouter>
      </IonApp>
    </QueryClientProvider>
  );
};

export default App;
