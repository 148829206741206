import { useIonLoading } from "@ionic/react";
import { useState } from "react";
import { z } from "zod";
import { useAxiosClient } from "../../hooks/useAxiosClient";
import { ApiRoutes, Mensagens } from "../../models/types";
import { useHistory } from "react-router";
import CardCapturarFoto from "../../components/form/CardCapturarFoto";
import SubmitButton from "../../components/form/SubmitButton";
import ReactForm from "../../components/form/ReactForm";
import Page from "../../components/Page";
import useNotification from "../../hooks/useNotification";
import SelectField from "../../components/form/SelectField";
import CardAnexarArquivo from "../../components/form/CardAnexarArquivo";
import ButtonBack from "../../components/ButtonBack";
import InputRadioGroupComponent from "../../components/form/InputRadioGroup";

export default function FotosDocumento() {
  //hooks
  const axiosClient = useAxiosClient(ApiRoutes.ESI_USUARIO_AGENCIA_VIRTUAL);
  const navigate = useHistory();

  const [present, dismiss] = useIonLoading();
  const [usaFotos, setUsaFotos] = useState<boolean>(true);

  const notification = useNotification();

  //Zod schema
  const formSchema = z.object({
    tipoDocumento: z
      .any()
      .refine((input) => input, "Informe o tipo do documento.")
      .transform((input) => input.value),
    imagemFrente: z.string(),
    imagemVerso: z.string(),
    documentoPdf: z.string(),
  });

  type formType = z.infer<typeof formSchema>;

  async function enviarDados(dados: formType) {
    try {
      if (usaFotos && (!dados.imagemFrente || !dados.imagemVerso)) {
        notification.showAlert({
          message:
            "É necessário adicionar as duas fotos (frente e verso) do documento.",
        });
        return;
      }

      if (!usaFotos && !dados.documentoPdf) {
        notification.showAlert({
          message: "É necessário adicionar um arquivo PDF do documento.",
        });
        return;
      }

      await present({ message: "Enviando..." });

      if (dados.imagemFrente && dados.imagemVerso) {
        await axiosClient.post(
          "/atendimentos/salvarDocumento",
          JSON.stringify({
            arquivoDocumentoCodificado: dados.imagemFrente
              .replace(/^data:image\/[a-z]+;base64,/, "")
              .trim(),
            ladoDocumento: "FRENTE",
            tipoDocumentoIdentificacao: dados.tipoDocumento,
            extensaoArquivoDocumentoCodificado: "jpg",
          })
        );

        await axiosClient.post(
          "/atendimentos/salvarDocumento",
          JSON.stringify({
            arquivoDocumentoCodificado: dados.imagemVerso
              .replace(/^data:image\/[a-z]+;base64,/, "")
              .trim(),
            ladoDocumento: "VERSO",
            tipoDocumentoIdentificacao: dados.tipoDocumento,
            extensaoArquivoDocumentoCodificado: "jpg",
          })
        );
        //
      } else if (dados.documentoPdf) {
        //
        await axiosClient.post(
          "/atendimentos/salvarDocumento",
          JSON.stringify({
            arquivoDocumentoCodificado: dados.documentoPdf
              .replace(/^data:application\/[a-z]+;base64,/, "")
              .trim(),
            ladoDocumento: "FRENTE",
            tipoDocumentoIdentificacao: dados.tipoDocumento,
            extensaoArquivoDocumentoCodificado: "pdf",
          })
        );
      }

      // chama a proxima tela onde solicita a self
      navigate.push({
        pathname: "/fotoPessoal",
        state: {
          tipoDocumento: dados.tipoDocumento,
        },
      });

      dismiss();
    } catch (e: any) {
      dismiss();

      await notification.showError({ exception: e });
    }
  }

  return (
    <Page title="Recadastro" showCardConta={false}>
      <div className="card bg-opacity-70">
        <div className="p-2">
          <div className="font-bold text-lg text-primary">
            Documento de identificação
          </div>

          <ReactForm
            schema={formSchema}
            onSubmit={(e) => enviarDados(e as formType)}
            onError={(error, e) => {}}
          >
            <div className="flex flex-col space-y-4">
              <div className="font-light text-sm">
                Você pode tirar fotos do documento ou anexar um arquivo PDF.
                Qual a sua escolha?
              </div>

              <InputRadioGroupComponent
                defaultValuePosition={0}
                name="preferenciaAnexo"
                size="small"
                items={[
                  {
                    value: "fotos",
                    label: "Tirar fotos do documento",
                  },
                  {
                    value: "pdf",
                    label: "Anexar PDF do documento",
                  },
                ]}
                onSelectItem={(value) => {
                  setUsaFotos(value?.value === "fotos");
                }}
              />

              <div className="font-light text-sm" hidden={!usaFotos}>
                Seguem algumas observações:
              </div>

              <div className="pl-4 font-light text-sm" hidden={!usaFotos}>
                <ol className="list-disc space-y-2">
                  <li>Centralize o documento.</li>
                  <li>Seu dedo não pode sair na foto.</li>
                  <li>
                    Fotografe uma imagem nítida de cada um dos lados do
                    documento.
                  </li>
                  <li>
                    Retire seu documento do plástico de proteção se achar
                    necessário.
                  </li>
                </ol>
              </div>

              <div className="font-light text-sm">
                Que tipo de documento deseja informar?
              </div>

              <SelectField
                name="tipoDocumento"
                placeHolder="Tipo do documento"
                items={[
                  { label: "RG", value: "RG" },
                  { label: "CNH", value: "CNH" },
                  { label: "RNE(Estrangeiro)", value: "RNE" },
                ]}
              />

              <div className="grid grid-cols-2 justify-center items-center gap-4 xs:grid-cols-1">
                <CardCapturarFoto
                  name="imagemFrente"
                  title="Foto frente"
                  hidden={!usaFotos}
                />

                <CardCapturarFoto
                  name="imagemVerso"
                  title="Foto verso"
                  hidden={!usaFotos}
                />
              </div>

              <CardAnexarArquivo
                name="documentoPdf"
                title="PDF documento"
                maxSize={5}
                hidden={usaFotos}
                types={["application/pdf"]}
              />

              <div className="grid grid-cols-2 gap-2 xs:grid-cols-1">
                <SubmitButton label="Prosseguir" />
                <ButtonBack label="Cancelar" />
              </div>
            </div>
          </ReactForm>
        </div>
      </div>
    </Page>
  );
}
