import {
  ApiRoutes,
  CODIGO_UNIDADE_ATENDIMENTO,
  Conta,
  MATRICULA_SOLICITANTE,
  Mensagens,
  SituacaoLigacaoAgua,
  TIPOS_RECLAMACAO_QUALIDADE_AGUA,
} from "../../models/types";
import { getOnlyNumbers } from "../../utils/functions";
import { useContaUsuario } from "../../contexts/contaUsuarioContext";
import { useEffect, useState } from "react";
import { z } from "zod";
import { useAxiosClient } from "../../hooks/useAxiosClient";
import { useAuth } from "../../contexts/authContext";
import { useIonLoading } from "@ionic/react";
import { useProtocolo } from "../../contexts/protocoloContext";
import Page from "../../components/Page";
import useNotification from "../../hooks/useNotification";
import ReactForm from "../../components/form/ReactForm";
import InputText from "../../components/form/InputText";
import SubmitButton from "../../components/form/SubmitButton";
import Accordion from "../../components/Accordion";
import DivReclamacaoAgua from "../../components/DivReclamacaoAgua";
import InputComplete from "../../components/form/InputComplete";
import ButtonBack from "../../components/ButtonBack";

export default function ReclamacaoAguaNaConta() {
  const { usuario } = useAuth();
  const contaUsuarioContext = useContaUsuario();

  const [present, dismiss] = useIonLoading();
  const [dadosConta, setDadosConta] = useState<Conta | null>({
    nomeCidade: "",
    nomeBairro: "",
    nomeLogradouro: "",
    quadraImovel: "",
    loteImovel: "",
    numeroImovel: "",
    situacaoLigacaoAgua: "",
  });

  useEffect(() => {
    setDadosConta(contaUsuarioContext.contaSelecionada);
  }, [contaUsuarioContext.contaSelecionada]);

  const { getProtocolo } = useProtocolo();
  const notification = useNotification();
  const axiosClient = useAxiosClient(ApiRoutes.ECO_ATENDIMENTO);

  const formSchema = z.object({
    pontoReferencia: z.string().min(1, "Informe um ponto de referência."),
    reclamacao: z.string().min(1, "Selecione a descrição da reclamação."),
    celular: z
      .string()
      .min(11, "Informe um número de contato.")
      .transform((val) => Number(getOnlyNumbers(val))),
  });

  async function enviarDados(
    { pontoReferencia, reclamacao, celular }: any,
    resetFields: any
  ) {
    //
    if (dadosConta?.codigoSituacaoLigacaoAgua !== SituacaoLigacaoAgua.LIGADA) {
      notification.showAlert({
        message: Mensagens.MSGE0100_RECLAMACAO_QUALIDADE_AGUA_NAO_POSSIVEL,
      });
      return;
    }

    await present({ message: Mensagens.SALVANDO });

    const dddTelefone = String(celular).substring(0, 2);
    const numeroTelefone = String(celular).substring(2, 11);

    const protocolo = await getProtocolo({
      numeroConta: dadosConta?.numeroConta || null,
      dddTelefoneContato: Number(dddTelefone),
      numeroTelefoneContato: Number(numeroTelefone),
    });

    if (!protocolo || !protocolo.numeroProtocolo) {
      dismiss();
      return;
    }

    try {
      let body = {
        matriculaSolicitante: MATRICULA_SOLICITANTE,
        codigoUnidadeAtendimento: CODIGO_UNIDADE_ATENDIMENTO,
        cpfCnpj: usuario?.cpfCnpj,
        nomeContato: usuario?.nome,
        tipoCliente: usuario?.tipoCliente,
        numeroConta: dadosConta?.numeroContaComDigito,
        pontoReferencia: pontoReferencia,
        dddTelefoneContato: dddTelefone,
        numeroTelefoneContato: numeroTelefone,
        descricaoLocalProblema: reclamacao,
      };

      await axiosClient.post(
        `/atendimentos/${protocolo.numeroProtocolo}/registrar/qualidade-agua`,
        JSON.stringify(body),
        {
          headers: {
            "content-type": "application/json",
          },
        }
      );

      notification.showProtocolo(protocolo.numeroProtocolo).then(() => {
        resetFields();
      });

      dismiss();
    } catch (e: any) {
      dismiss();

      let msg = Mensagens.ERRO_INESPERADO;

      if (e.response.status === 403) {
        msg = Mensagens.SOLICITACAO_ABERTA;
      }

      await notification.showError({ message: msg, exception: e });
    }
  }

  return (
    <Page title="Reclamação sobre qualidade de água" buttonType="back">
      <div className="flex flex-col space-y-4">
        <Accordion title="Observações">
          <DivReclamacaoAgua />
        </Accordion>

        <div className="card bg-opacity-70">
          <div className="card-title">Dados da ocorrência</div>

          <div className="items-center">
            <ReactForm
              schema={formSchema}
              onError={(error, e) => {}}
              onSubmit={(data, resetFields) => enviarDados(data, resetFields)}
            >
              <div className="flex flex-col space-y-4">
                <InputText
                  value={dadosConta?.nomeCidade}
                  name="nomeCidade"
                  label="Cidade"
                  disabled
                />
                <InputText
                  value={dadosConta?.nomeBairro}
                  name="nomeBairro"
                  label="Bairro"
                  disabled
                />
                <InputText
                  value={dadosConta?.nomeLogradouro}
                  name="nomeLogradouro"
                  label="Logradouro"
                  disabled
                />

                <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3 gap-4">
                  <InputText
                    value={dadosConta?.quadraImovel}
                    name="quadraImovel"
                    label="Quadra"
                    disabled
                  />
                  <InputText
                    value={dadosConta?.loteImovel}
                    name="loteImovel"
                    label="Lote"
                    disabled
                  />
                  <InputText
                    value={dadosConta?.numeroImovel}
                    name="numeroImovel"
                    label="Número"
                    disabled
                  />
                </div>

                <InputText
                  name="pontoReferencia"
                  label="Ponto de referência"
                  inputProps={{ maxLength: 50 }}
                />

                <InputText
                  name="celular"
                  label="Celular"
                  mask="99 99999-9999"
                />

                <InputComplete
                  name="reclamacao"
                  placeHolder="Reclamação"
                  items={TIPOS_RECLAMACAO_QUALIDADE_AGUA}
                />

                <div className="grid grid-cols-2 gap-2 xs:grid-cols-1">
                  <SubmitButton label="Enviar" />
                  <ButtonBack label="Voltar" />
                </div>
              </div>
            </ReactForm>
          </div>
        </div>
      </div>
    </Page>
  );
}
