import { IonContent, IonPage } from "@ionic/react";
import { classNames } from "primereact/utils";
import { useAuth } from "../contexts/authContext";
import { useContaUsuario } from "../contexts/contaUsuarioContext";
import { Mensagens } from "../models/types";
import { useEffect } from "react";
import { delay } from "../utils/functions";

import useWindowDimensions from "../hooks/useWindowDimensions";
import CardConta from "./CardConta";
import HeaderSaneago from "./HeaderSaneago";

export declare type ButtonType = "menu" | "back" | "none";

export interface PageProps {
  children: JSX.Element;
  title?: string;
  showDegradeBackground?: boolean;
  showCardConta?: boolean;
  desabilitarCardConta?: boolean;
  necessarioSelecionarConta?: boolean;
  reCaptcha?: boolean;
  buttonType?: ButtonType;
  framePage?: boolean;
}

export default function Page({
  children,
  title,
  showDegradeBackground = true,
  buttonType = "menu",
  showCardConta = true,
  desabilitarCardConta = false,
  necessarioSelecionarConta = false,
  reCaptcha = false,
  framePage = false,
}: PageProps) {
  //
  const { isLogged, usuario } = useAuth();
  const { contaSelecionada } = useContaUsuario();
  const { size } = useWindowDimensions();

  useEffect(() => {
    toggleReCaptchaBadge(reCaptcha);
  }, [reCaptcha]);

  const toggleReCaptchaBadge = async (show: boolean) => {
    await delay(250);
    const badge = document.getElementsByClassName("grecaptcha-badge")[0];

    if (badge && badge instanceof HTMLElement) {
      badge.style.visibility = show ? "visible" : "hidden";
    }
  };

  let CardContaRender: any = <></>;
  if (isLogged && usuario?.ativo && usuario?.clienteSaneago && showCardConta) {
    CardContaRender = (
      <div className="pt-4 flex justify-center items-center mx-3">
        <div
          className={classNames({
            "pointer-events-none opacity-70": desabilitarCardConta,
          })}
        >
          <CardConta />
        </div>
      </div>
    );
  }

  let CardNecessarioSelecionarContaRender: any = <></>;
  if (
    isLogged &&
    showCardConta &&
    necessarioSelecionarConta &&
    !contaSelecionada?.numeroContaComDigito
  ) {
    CardNecessarioSelecionarContaRender = (
      <div className="p-4">
        <div className="card text-slate-700">
          <div>{Mensagens.NECESSARIO_SELECIONAR_CONTA}</div>
        </div>
      </div>
    );
  }

  return (
    <IonPage>
      <HeaderSaneago title={title} buttonType={buttonType} />
      <IonContent>
        <div className="h-full">
          {showDegradeBackground ? (
            // <div className="absolute -z-10 opacity-40 bg-gradient-to-b from-tertiary via-transparent to-transparent h-full w-full"></div>
            // <div className="absolute -z-10 opacity-40 bg-tertiary h-full w-full"></div>
            <div
              className={classNames(
                "absolute -z-10 bg-fundo-degrade bg-no-repeat bg-cover bg-center h-full w-full",
                { "-top-28": size === "xs" || size === "sm" },
                { "-top-20": size === "md" },
                { "-top-16": size === "lg" }
              )}
            >
              {/* codigo para remover a linha que aparece no final da imagem, mas gerou barra de rolagem  */}
              {/* <div className="absolute z-10 self-center bg-white -bottom-0 w-full h-4"></div> */}
            </div>
          ) : (
            <></>
          )}

          <div
            className={classNames("flex justify-center items-center", {
              "h-full": framePage,
            })}
          >
            <div
              className={classNames("w-full", {
                "h-full": framePage,
                "xl:w-10/12 2xl:w-3/4": !framePage,
              })}
            >
              {/* Componente de Selecao de Contas */}
              {CardContaRender}

              {/* Componente de informação se obrigatorio selecionar conta */}
              {CardNecessarioSelecionarContaRender}

              {/* Children components para page */}
              <div
                className={classNames("z-20 p-4", {
                  "h-full": framePage,
                })}
              >
                {children}
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}
