import { InputMask, InputMaskProps } from "primereact/inputmask";
import { classNames } from "primereact/utils";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import usePrevious from "../../hooks/usePrevious";

export interface MaskfieldProps extends InputMaskProps {
  name: string;
  label?: string;
}

const MaskField: React.FC<MaskfieldProps> = (props: MaskfieldProps) => {
  const { name, label, ...propsOriginal } = props;
  const _backgroundColor = "rgb(249, 250, 251, 0.95)";

  const {
    register,
    resetField,
    formState: { isSubmitting, errors },
  } = useFormContext();

  const previousMaskValue = usePrevious(propsOriginal.mask);

  useEffect(() => {
    if (previousMaskValue !== propsOriginal.mask) {
      // resetField(name, { defaultValue: "" });
      resetField(name);
    }
  }, [propsOriginal.mask]);

  return (
    <div>
      <span className="p-float-label">
        <InputMask
          {...propsOriginal}
          style={{ backgroundColor: _backgroundColor, height: 56 }}
          className={classNames("w-full", {
            "p-invalid": !!errors[name as string],
          })}
          {...register(name)}
          autoComplete="off" // necessário para remover o bug de auto complete da mascara
          disabled={propsOriginal.disabled || isSubmitting}
          value={propsOriginal.value}
          onBlur={propsOriginal.onBlur}
          onComplete={propsOriginal.onComplete} // use para capturar ao complementar a mascara e?.value
          onFocus={(item) => {
            //colocando o cursor no comeco
            setTimeout(() => {
              item.target.setSelectionRange(0, 0);
            }, 100);
          }}
        ></InputMask>
        <label
          htmlFor="inputmask"
          style={{ backgroundColor: _backgroundColor }}
        >
          <div
            className={classNames({
              "text-red-600": !!errors[name as string],
            })}
          >
            {label ? label : name}
          </div>
        </label>
      </span>

      <div
        className="py-1 pl-3 text-xs text-red-600"
        style={{ backgroundColor: _backgroundColor }}
        hidden={!errors[name]}
      >
        {errors[name as string]?.message?.toString()}
      </div>
    </div>
  );
};

export default MaskField;
