import {
  AlertButton,
  ToastButton,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import { useHistory } from "react-router";
import { Mensagens } from "../models/types";

export type PredefinedPosition = "top" | "middle" | "bottom";

export type PredefinedColors =
  | "primary"
  | "secondary"
  | "tertiary"
  | "success"
  | "warning"
  | "danger"
  | "light"
  | "medium"
  | "dark";

export default function useNotification() {
  const [presentToast] = useIonToast();
  const [presentAlert] = useIonAlert();

  const navigate = useHistory();

  const durationDefault: number = 4000;

  function show({
    message,
    color,
    duration,
    dismiss = true,
    buttons,
    position,
  }: {
    message: string;
    color: PredefinedColors;
    duration?: number;
    dismiss?: boolean;
    buttons?: (ToastButton | string)[];
    position?: PredefinedPosition;
  }) {
    presentToast({
      message: message,
      duration: dismiss ? duration || durationDefault : undefined,
      color: color,
      buttons: buttons || ["OK"],
      position: position || "top",
    });
  }

  function showWarning({
    message,
    duration,
    dismiss = true,
  }: {
    message: string;
    duration?: number;
    dismiss?: boolean;
  }) {
    //
    presentToast({
      message: message,
      duration: dismiss ? duration || 8000 : undefined,
      color: "warning",
      buttons: ["OK"],
      position: "top",
    });
  }

  async function showError({
    message,
    duration,
    dismiss = true,
    exception,
    redirect,
  }: {
    message?: string;
    duration?: number;
    dismiss?: boolean;
    exception?: any;
    redirect?: string;
  }) {
    //
    exception ? console.error(exception) : console.log();

    let _color = "danger";
    let _status = exception?.response?.status || 0;
    let _detalhe = exception?.response?.data?.detalhe || "";
    let _stack = exception ? JSON.stringify(exception) : "";

    // para casos onde o retorno não é um json
    if (!_detalhe) {
      try {
        const text = await exception?.response?.data.text();
        const data = await JSON.parse(text);

        _detalhe = data?.detalhe || "";
      } catch (error) {
        _detalhe = "";
      }
    }

    // se houver mensagem vinda da tela, mostra a mensagem e depois o detalhe
    let _message = message ? message + " " + _detalhe : _detalhe;

    // se não houver mensagem vinda da tela, força mensagem especifica
    if (!message && _status === 404) {
      _message = Mensagens.NENHUM_REGISTRO;
    }

    // se não houver mensagem vinda da tela, força mensagem especifica
    if (!message && _status === 500) {
      _message = Mensagens.ERRO_INESPERADO;
    }

    if (_stack && _stack.includes("reCAPTCHA inv")) {
      _message = Mensagens.RECAPTCHA_INVALIDO;
      _color = "warning";
    }

    // se depois de todo o tratamento nao houver mensagem, mostra erro generico
    _message = _message ? _message : Mensagens.ERRO_INESPERADO;

    _message = _status
      ? _status + " - " + _message
      : exception?.message
      ? exception?.message + " - " + _message
      : _message;

    presentToast({
      message: _message,
      duration: dismiss ? duration || 8000 : undefined,
      color: _color,
      buttons: ["OK"],
      position: "top",
      onDidDismiss(event) {
        if (redirect) {
          navigate.push(redirect);
        }
      },
    });

    // presentAlert({
    //   header: Mensagens.ATENCAO,
    //   message: JSON.stringify(exception),
    //   buttons: ["OK"],
    // });
  }

  function showSuccess({
    message,
    duration,
    dismiss = true,
    redirect,
  }: {
    message: string;
    duration?: number;
    dismiss?: boolean;
    redirect?: string;
  }) {
    presentToast({
      message: message,
      duration: dismiss ? duration || durationDefault : undefined,
      color: "secondary",
      buttons: ["OK"],
      position: "top",
      onDidDismiss(event) {
        if (redirect) {
          navigate.push(redirect);
        }
      },
    });
  }

  function showAlert({
    message,
    header,
    subHeader,
    buttons,
    redirect,
  }: {
    message: string;
    header?: string;
    subHeader?: string;
    buttons?: (AlertButton | string)[];
    redirect?: string;
  }) {
    return new Promise((resolve, reject) => {
      presentAlert({
        header: header || Mensagens.ATENCAO,
        subHeader: subHeader,
        message: message,
        buttons: buttons || ["OK"],
        onDidDismiss(event) {
          if (redirect) {
            navigate.push(redirect);
          }

          resolve(event);
        },
      });
    });
  }

  function showProtocolo(numeroProtocolo: number) {
    return new Promise((resolve, reject) => {
      presentAlert({
        header: "",
        subHeader: `Protocolo: ${numeroProtocolo}`,
        message:
          "Anote pois com ele você poderá consultar o andamento de sua solicitação pelo aplicativo, telefone 0800 645 0115 ou nas Agências de Atendimento VAPT VUPT.",
        buttons: ["OK"],
        onDidDismiss(event) {
          navigate.push("/home");
          resolve(event);
        },
      });
    });
  }

  function showNecessarioSelecionarConta() {
    presentToast({
      message: Mensagens.NECESSARIO_SELECIONAR_CONTA,
      color: "warning",
      buttons: ["OK"],
      position: "top",
    });
  }

  return {
    show,
    showSuccess,
    showWarning,
    showError,
    showNecessarioSelecionarConta,
    showProtocolo,
    showAlert,
  };
}
