import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import InputText from "../../components/form/InputText";
import SubmitButton from "../../components/form/SubmitButton";
import ReactForm from "../../components/form/ReactForm";
import InputRadioGroupComponent from "../../components/form/InputRadioGroup";
import InputComplete from "../../components/form/InputComplete";
import ButtonBack from "../../components/ButtonBack";
import { TelefoneFormData } from "./CadastroFaturaDigital";
import { TIPOS_TELEFONE } from "../../models/types";

const opcoesPossuiWhatsapp = [
  {
    value: "true",
    label: "Sim",
  },
  {
    value: "false",
    label: "Não",
  },
];

interface CadastroTelefoneProps {
  onSubmit: (data: TelefoneFormData) => void;
  handleCancelar: () => void;
  telefones: TelefoneFormData[];
}

const CadastroTelefone: React.FC<CadastroTelefoneProps> = ({
  onSubmit,
  handleCancelar,
  telefones,
}) => {
  const telefoneSchema = z.object({
    tipo: z.string().min(1, "Selecione um tipo de telefone."),
    telefoneCompleto: z.string().min(1, "Informe um número de telefone."),
    possuiWhats: z
      .string()
      .nullable()
      .refine((data) => {
        // Se o tipo é CELULAR, então possuiWhats não pode ser nulo
        if (selecaoTipoTelefone === "CELULAR") {
          return data !== null;
        }
        // Se não for CELULAR, a validação é sempre verdadeira
        return true;
      }, "Selecione uma opção de WhatsApp."),
  });

  const { register, reset } = useForm({
    resolver: zodResolver(telefoneSchema),
  });

  const handleFormSubmit = async (data: any) => {
    // Garante que possuiWhats é false quando não há informação
    if (data.possuiWhats === null || data.possuiWhats === undefined) {
      data.possuiWhats = false;
    }

    onSubmit(data);
    await reset(); // Reseta o formulário após a submissão
  };

  const [selecaoTipoTelefone, setSelecaoTipoTelefone] = useState<
    string | number | undefined
  >("");

  const [celularObriatorio, setCelularObrigatorio] = useState<boolean>(true);

  useEffect(() => {
    const temCelular = telefones.some(
      (telefone) => telefone.tipo === "CELULAR"
    );
    setCelularObrigatorio(!temCelular);
  }, [telefones]);

  return (
    <>
      <ReactForm
        schema={telefoneSchema}
        onSubmit={(data) => handleFormSubmit(data)}
        onError={(error, e) => {}}
      >
        <div className="flex flex-col space-y-4">
          <InputComplete
            name="tipo"
            placeHolder="Tipo de Telefone"
            items={TIPOS_TELEFONE}
            onSelect={(item) => {
              setSelecaoTipoTelefone(item?.value);
            }}
            defaultValuePosition={celularObriatorio ? 2 : undefined}
            readonly={celularObriatorio}
          />
          <InputText
            name="telefoneCompleto"
            label="Número de Telefone"
            inputProps={{ ...register("numeroTelefone") }}
            mask={
              selecaoTipoTelefone === "CELULAR"
                ? "99 99999-9999"
                : "99 9999-9999"
            }
          />
          {selecaoTipoTelefone === "CELULAR" && (
            <InputRadioGroupComponent
              name="possuiWhats"
              label="Possui Whatsapp?"
              items={opcoesPossuiWhatsapp}
            />
          )}

          <div className="grid grid-cols-2 gap-2 xs:grid-cols-1">
            <SubmitButton label="Adicionar Telefone" />
            <ButtonBack label="Voltar" onClickEvent={handleCancelar} />
          </div>
        </div>
      </ReactForm>
      <div></div>
    </>
  );
};

export default CadastroTelefone;
