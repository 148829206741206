import React from "react";

const DivReclamacaoAgua: React.FC = () => {
  return (
    <div className="">
      <div className="mb-2 text-sm font-medium text-slate-700">
        A SANEAGO garante a qualidade da água que distribui através de amplo
        controle:
      </div>

      <ol className="list-decimal pl-4 space-y-2">
        <li>
          Desenvolve ações para preservar e despoluir mananciais (rios,
          ribeirões e córregos) de onde capta a água bruta;
        </li>
        <li>
          Realiza um rigoroso processo nas Estações de Tratamento de Água que
          torna a água potável, desprovida de micro-organismos causadores de
          doenças, ou seja, própria para o consumo humano;
        </li>
        <li>
          Realiza análise diária e rotineira nos reservatórios e redes de
          distribuição em pontos diversos da estação de tratamento, na cidade,
          para avaliação da qualidade de água que chega ao seu imóvel;
        </li>
        <li>
          Está estruturada com profissionais especializados e laboratórios
          espalhados por todos o estado;
        </li>
        <li>
          Garante a qualidade de água de acordo com as normas do Ministério da
          Saúde, obedecendo a portaria nº 036/90.
        </li>
      </ol>
    </div>
  );
};

export default DivReclamacaoAgua;
