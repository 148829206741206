import { IonIcon, IonItem, IonLabel, IonMenuToggle } from "@ionic/react";
import { lockClosedOutline } from "ionicons/icons";
import { useLocation } from "react-router-dom";
import { classNames } from "primereact/utils";
import { MenuItemPage } from "../models/types";

export default function MenuItem(props: { lista: Array<MenuItemPage> }) {
  const location = useLocation();

  return (
    <>
      {props.lista.map((item: MenuItemPage, index) => {
        return (
          <IonMenuToggle key={index} autoHide={false}>
            <IonItem
              style={{ "--padding-end": "2px" }}
              className={classNames({
                selected:
                  location.pathname === item.url &&
                  item.removeSelectedCSS !== true,
              })}
              routerLink={item.url}
              routerDirection="root"
              lines="none"
              detail={false}
              hidden={!!item.remover}
              onClick={() => (item.onClick ? item.onClick() : {})}
            >
              <IonIcon
                hidden={item.desabilitar}
                color="primary"
                slot="start"
                src={item.icon}
              />

              <IonIcon
                hidden={!item.desabilitar}
                color="danger"
                slot="start"
                ios={lockClosedOutline}
                md={lockClosedOutline}
              />

              <IonLabel className="ion-text-wrap">
                <div className="text-sm">{item.title}</div>
              </IonLabel>
            </IonItem>
          </IonMenuToggle>
        );
      })}
    </>
  );
}
