import {
  converterStringEmDate,
  formatarDataNoPadrao,
  getOnlyNumbers,
  isDataValida,
} from "../../utils/functions";

import { z } from "zod";
import { TIPOS_UF } from "../../models/types";
import { useAuth } from "../../contexts/authContext";
import { useHistory } from "react-router";

import React from "react";
import InputText from "../../components/form/InputText";
import ReactForm from "../../components/form/ReactForm";
import SubmitButton from "../../components/form/SubmitButton";
import Page from "../../components/Page";
import InputComplete from "../../components/form/InputComplete";

export const TIPOS_SEXO = (() => {
  const lista = ["Masculino", "Feminino"];

  return lista.map((item) => {
    return { value: item, label: item };
  });
})();

const DadosPessoais: React.FC = () => {
  //hooks
  const { usuario } = useAuth();
  const navigate = useHistory<any>();

  const tipoDocumento = navigate.location.state?.tipoDocumento;

  //Zod schema
  const formSchema = z
    .object({
      nomeCompletoMae: z.string().min(1, "Informe o nome da mãe."),
      dataNascimento: z
        .string()
        .refine(
          (input) => isDataValidaMenorIgualHoje(input),
          "Informe uma data de nascimento válida."
        ),
      sexo: z.string().min(1, "Selecione o sexo."),
      codigoEstadoCivil: z.string().min(1, "Selecione o estado civil."),
      siglaUnidadeFederativaDocumento: z
        .string()
        .min(1, "Selecione a UF do documento."),
      numeroDocumento: z.string().min(1, "Informe o número do documento."),
      dddTelefoneCelular: z
        .string()
        .min(1, "Informe um celular válido.")
        .optional(),
      numeroTelefoneCelular: z
        .string()
        .min(1, "Informe um número de telefone celular.")
        .transform((val) => Number(getOnlyNumbers(val))),
      dataValidadeDocumento: z
        .string()
        .refine(
          (input) => validarDataValidadeDocumento(input),
          "Informe uma data válida."
        ),
      dataEmissaoDocumento: z
        .string()
        .refine(
          (input) => isDataValidaMenorIgualHoje(input),
          "Informe uma data de emissão válida."
        ),
    })
    .refine(
      (data) =>
        isDataValidaMaiorDataEmissao(
          data.dataValidadeDocumento,
          data.dataEmissaoDocumento
        ),
      {
        message: "Data de validade deve ser maior que a data de emissão.",
        path: ["dataValidadeDocumento"],
      }
    );

  type formType = z.infer<typeof formSchema>;

  async function enviarDados(dados: formType) {
    const dddTelefone = String(dados.numeroTelefoneCelular).substring(0, 2);
    const numeroTelefone = String(dados.numeroTelefoneCelular).substring(2, 11);

    navigate.push({
      pathname: "/endereco",
      state: {
        data: {
          ...dados,
          numeroTelefoneCelular: numeroTelefone,
          dddTelefoneCelular: dddTelefone,
          sexo: dados.sexo.toUpperCase(),
          dataNascimento: formatarDataNoPadrao(
            converterStringEmDate(dados.dataNascimento),
            "yyyy-MM-dd"
          ),
          dataValidadeDocumento: formatarDataNoPadrao(
            converterStringEmDate(dados.dataValidadeDocumento),
            "yyyy-MM-dd"
          ),
          dataEmissaoDocumento: formatarDataNoPadrao(
            converterStringEmDate(dados.dataEmissaoDocumento),
            "yyyy-MM-dd"
          ),
        },
      },
    });
  }

  function isDataValidaMenorIgualHoje(data: string): boolean {
    if (!data) {
      return false;
    }

    if (!isDataValida(data)) {
      return false;
    }

    const date = converterStringEmDate(data);

    if (!date) {
      return false;
    }

    if (date > new Date()) {
      return false;
    }

    return true;
  }

  function validarDataValidadeDocumento(data: string): boolean {
    const obrigatorio = tipoDocumento !== "RG";

    if (!obrigatorio && !data) {
      return true;
    }

    if (obrigatorio && !data) {
      return false;
    }

    if (!isDataValida(data)) {
      return false;
    }

    const date = converterStringEmDate(data);

    if (!date) {
      return false;
    }

    return true;
  }

  function isDataValidaMaiorDataEmissao(
    dataValidade: string,
    dataEmissao: string
  ): boolean {
    // só validade se a data de validade for informada,
    // pois não é campo obrigatorio em todos os cenários
    if (!dataValidade) {
      return true;
    }

    const _dataValidade = converterStringEmDate(dataValidade);
    const _dataEmissao = converterStringEmDate(dataEmissao);

    if (!_dataValidade || !_dataEmissao) {
      return false;
    }

    // Data de validade deve ser maior que a data de emissão.
    if (_dataValidade <= _dataEmissao) {
      return false;
    }

    return true;
  }

  return (
    <Page title="Recadastro" showCardConta={false}>
      <div className="card bg-opacity-70">
        <div className="p-2">
          <div className="font-bold text-lg text-primary">Dados pessoais</div>

          <ReactForm
            schema={formSchema}
            onSubmit={(e) => enviarDados(e as formType)}
            onError={(error, e) => {}}
          >
            <div className="flex flex-col space-y-4">
              <div className="font-light text-sm">
                Preencha seus dados de acordo com o documento enviado. Confira
                atentamente as informações.
              </div>

              <InputText
                name="nomeCompleto"
                label="Nome completo"
                defaultValue={usuario?.nome}
                inputProps={{ maxLength: 70 }}
                disabled={true}
              />

              <InputText
                name="nomeCompletoMae"
                label="Nome completo da mãe"
                inputProps={{ maxLength: 70 }}
              />

              <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3 gap-4">
                <InputText
                  name="dataNascimento"
                  label="Data de nascimento"
                  mask="99/99/9999"
                />

                <InputComplete
                  name="sexo"
                  placeHolder="Sexo"
                  items={TIPOS_SEXO}
                />

                <InputComplete
                  name="codigoEstadoCivil"
                  placeHolder="Estado civil"
                  items={[
                    { label: "Solteiro", value: 1 },
                    { label: "Casado", value: 2 },
                    { label: "Desquitado", value: 3 },
                    { label: "Viuvo", value: 4 },
                    { label: "Divorciado", value: 6 },
                    { label: "União Estável", value: 7 },
                    { label: "Outro", value: 5 },
                  ]}
                />
              </div>

              <InputText
                name="numeroDocumento"
                label="Número do documento"
                inputProps={{ maxLength: 18 }}
              />

              <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3 gap-4">
                <InputComplete
                  name="siglaUnidadeFederativaDocumento"
                  placeHolder="UF do documento"
                  items={TIPOS_UF}
                />

                <InputText
                  name="dataEmissaoDocumento"
                  label="Data de emissão"
                  mask="99/99/9999"
                />

                <InputText
                  name="dataValidadeDocumento"
                  label="Data de validade (CNH e RNE)"
                  mask="99/99/9999"
                />
              </div>

              <InputText
                name="numeroTelefoneCelular"
                label="Celular"
                mask="99 99999-9999"
              />

              <SubmitButton label="Prosseguir" />
            </div>
          </ReactForm>
        </div>
      </div>
    </Page>
  );
};

export default DadosPessoais;
